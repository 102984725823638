import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, Location } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';

import { RB_COMPLETE, getFlagColor } from '../../../../utils/constants';
import { AWARD_GEM_MUTATION } from '../../../../components/GemButton/services/graphql';
import AssignBand from '../../../Banding/components/assign';
import { AuthContext } from '../../../../services/auth/AuthProvider';

import SaveConfirm from '../../../../components/SaveConfirm';
import { Flag, MoreVertical } from 'react-feather';
import Boomer from '../../../../images/loader-boomer.png'
import { useTranslation } from 'react-i18next';
import styles from './StudentRow.module.scss';
import cx from 'classnames';
import SideModal from '../../../../components/SideModal';
import AddNote from '../../../Student/Components/Notes/AddNote';
import ReadingLogDatesRow from './ReadingLogDatesRow';

const StudentRow = ({ student, classInfo, onSelectStudent, viewType, intervalStats, selectedStudents, studentView = false }) => {
    const { t } = useTranslation();

    const [gemAwarded, setGemAwarded] = useState(false);
    const [showBand, setShowBand] = useState(false);
    const [addNote, setAddNote] = useState(false);
    const [menu, setMenu] = useState(false);
    const auth = useContext(AuthContext);
    const { userPermissions } = auth;

    const activeSchoolBooks = student.readingBooks.filter(book => book.status !== RB_COMPLETE && book.bookType === 1);

    const [awardGem] = useMutation(AWARD_GEM_MUTATION, {
        variables: {
            studentId: student.id
        },
        onCompleted: ({ addRewardGemToStudent }) => {
            if (addRewardGemToStudent.errors && addRewardGemToStudent.errors.length !== 0) {
                alert(t('error_awarding_gem'));
                return;
            }
            if (addRewardGemToStudent.student && addRewardGemToStudent.student.id) {
                setGemAwarded(true);
                setMenu(false);
            }
        }
    });

    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);


    return (
        <>
            {studentView ?
                <li key={`student-${student.id}`} className={styles.row}>
                    <div className={styles.rowInner}>
                        <Location>
                            {() =>
                                <Link className={styles.rowInner} to={`/student/${student.id}`} state={{ classInfo: classInfo }}>
                                    <div className={styles.rowMain}>
                                        <div className={styles.rowBoomer}>
                                            <img src={student.boomerAvatarUrl ? `${student.boomerAvatarUrl}?v=${student.updatedAt.replace(/\s/g, '')}` : Boomer} className={styles.boomerImg} />
                                            {student.flagType && <span style={{background: getFlagColor(student.flagType)}} className={styles.rowFlag}><Flag fill='#fff' color='#fff' size='14' /></span>}
                                        </div>
                                        <div className={styles.rowInfo}>
                                            <p>{student.name}</p>
                                            <div className={styles.rowBook}>
                                                {activeSchoolBooks.length === 0 ? <p className={styles.rowBookTitle}>{t('no_current_school_book')}</p> :
                                                    <>
                                                        {activeSchoolBooks.slice(0, 3).map(el =>
                                                            <p key={`active-b-${el.id}`} className={styles.studentBookTitle}>{el.book.title}</p>
                                                        )}
                                                        {activeSchoolBooks.length > 3 && <p>+{activeSchoolBooks.length - 3} more</p>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.rowData}>
                                        <ReadingLogDatesRow intervalStats={intervalStats} viewType={viewType} student={student} />
                                    </div>
                                </Link>
                            }
                        </Location>
                    </div>
                </li>
                :
                <li className={styles.row} key={`student-${student.id}`}>
                    <div className={styles.rowInner}>
                        <Location>
                            {() =>
                                <Link to={`/student/${student.id}`} state={{ classInfo: classInfo }} className={styles.rowLink}></Link>
                            }
                        </Location>
                        <div className={styles.rowMain}>
                            <div className={cx("basic-form__group--check", styles.rowStCheck)}>
                                <input
                                    name="student"
                                    className="basic-form__check-box"
                                    type="checkbox"
                                    value={student.id}
                                    onChange={(e) => onSelectStudent(e)}
                                    checked={selectedStudents.indexOf(student.id) > -1}
                                    id={`st_${student.id}`}
                                />
                                <label className="basic-form__check-label" htmlFor={`st_${student.id}`}>&nbsp;</label>
                            </div>
                            <div className={styles.rowBoomer}>
                                <img src={student.boomerAvatarUrl ? `${student.boomerAvatarUrl}?v=${student.updatedAt.replace(/\s/g, '')}` : Boomer} className={styles.boomerImg} />
                                {student.flagType && <span style={{ background: getFlagColor(student.flagType) }} className={styles.rowFlag}><Flag fill='#fff' color='#fff' size='14' /></span>}
                            </div>
                            <div className={styles.rowInfo}>
                                {gemAwarded && <div className={styles.studentGemAwarded}><SaveConfirm message={t('gem_awared')} hideConfirm={() => setGemAwarded(false)} /></div>}
                                <p>{student.name}</p>
                                <div className={styles.rowBook}>
                                    {activeSchoolBooks.length === 0 ? <p className={styles.rowBookTitle}>{t('no_current_school_book')}</p> :
                                        <>
                                            {activeSchoolBooks.slice(0, 3).map(el =>
                                                <p key={el.id} className={styles.rowBookTitle}>{el.book.title}</p>
                                            )}
                                            {activeSchoolBooks.length > 3 && <p>+{activeSchoolBooks.length - 3} more</p>}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.rowData}>
                            <ReadingLogDatesRow intervalStats={intervalStats} viewType={viewType} student={student} />
                        </div>
                    </div>

                    <div ref={ref} className='dropdown dropdownTopxx'>
                        <button className={cx('dropdownBtn', { 'dropdownBtnIsActive': menu })} onClick={() => setMenu(!menu)}><MoreVertical /></button>
                        {menu && (
                            <ul className="dropdownMenu">
                                <li><button onClick={() => awardGem()}>{t('award_bonus_gem')}</button></li>
                                {
                                    student.school.readingBands && student.school.readingBands.length > 0 &&
                                    <li>
                                        <button onClick={() => setShowBand(true)}>
                                            {student.currentReadingRank ? t('change_reading_band') : t('add_reading_band')}
                                        </button>
                                    </li>
                                }

                                {userPermissions && userPermissions.canManageStudentNotes &&
                                    <li>
                                        <button onClick={() => setAddNote(true)}>Add a note</button>
                                    </li>
                                }


                                {activeSchoolBooks.length === 0 ?
                                    <li><Link to={`/student/${student.id}`} state={{ classInfo: { ...classInfo, showAddBook: true } }}>{t('add_book')}</Link></li> :
                                    <li>
                                        <Location>
                                            {({ location }) =>
                                                <Link
                                                    to={`/logReading/${student.id}`}
                                                    state={{
                                                        oldLocation: JSON.parse(JSON.stringify(location)),
                                                        student: student,
                                                        schoolOnly: true
                                                    }}
                                                    onClick={() => setMenu(false)}
                                                >
                                                    {t('log_reading')}
                                                </Link>
                                            }
                                        </Location>
                                    </li>
                                }
                                <li><Link to={`/student/${student.id}`} state={{ classInfo: { ...classInfo, activity: true } }}>View activity</Link></li>
                            </ul>
                        )}
                    </div>
                </li>
            }

            {showBand && <AssignBand student={student} close={() => setShowBand(false)} />}

            {addNote && (
                <SideModal closeModal={() => setAddNote(false)}>
                    <AddNote fromClassPage={true} student={student} close={() => setAddNote(false)} feedbackInModal={true} />
                </SideModal>
            )}
        </>
    )
}

export default StudentRow;