import React, { useState } from 'react';
import { CheckCircle } from 'react-feather';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './SaveConfirm.module.scss';

const SaveConfirm = ({ hideConfirm, message, classNames }) => {
    const { t } = useTranslation();

    const [fadeIn, setFadeIn] = useState(false);
    useEffectOnce(() => {
        setTimeout(() => {
            hideConfirm();
        }, 5000)
        setTimeout(() => {
            setFadeIn(false);
        }, 4500)
        setTimeout(() => {
            setFadeIn(true);
        }, 100)
    })

    return (
        <p className={cx(classNames, styles.saveConfirm, {[styles.fadeIn]: fadeIn}, {[styles.fadeOut]: !fadeIn})}><CheckCircle className={styles.saveConfirmIcon} /> {message || t('changes_saved')}</p>
    );
}

export default SaveConfirm;
