import React, { useState } from "react";
import { Modal } from "@axeedge/go-teacher-components";
import Doughnutchart from "./DoughnutChart";
import EthnicityBar from "./EthnicityBar";
import PupilListBreakDown from "./PupilListBreakdown";
import styles from '../SchoolData.module.scss';
import cx from 'classnames';

const Demographics = ({ demographicsData, source, isFromClass, school }) => {

    const [showPupils, setShowPupils] = useState(null);

    const titleCase = (str) => str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()).split('_').join(' ');

    const getBreakDownTitle = value => {
        if (value === '') {
            return 'Not specified'
        } else if (value === 'false') {
            return 'None'
        }
        else return value;
    }



    const gender = demographicsData.find(el => el.name === 'gender');
    const eal = demographicsData.find(el => el.name === 'eal');
    const premium = demographicsData.find(el => el.name === 'pupil_premium');
    const send = demographicsData.find(el => el.name === 'send');

    const dataGender = {
        labels: gender.details.map(detail => detail.name === '' ? 'Not specified' : detail.name),
        datasets: [
            {
                label: 'Dataset Gender',
                data: gender.details.map(detail => detail.value),
                ids: gender.details.map(detail => ({ name: detail.name, category: 'gender' })),
                backgroundColor: [
                    'rgba(254, 100, 132, 0.9)',
                    'rgba(255, 159, 64, 0.9)',
                    'rgba(76, 192, 192, 0.9)',
                ],
                borderColor: [
                    'rgba(254, 100, 132, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(76, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };



    const dataSend = {
        labels: send.details.map(detail => detail.name === '' ? 'Not specified' : detail.name),
        datasets: [
            {
                label: 'Dataset Send',
                data: send.details.map(detail => detail.value),
                ids: send.details.map(detail => ({ name: detail.name, category: 'send' })),
                backgroundColor: [
                    'rgba(33, 106, 157, 0.9)',
                    'rgba(53, 161, 235, 0.9)',
                    'rgba(253, 100, 132, 0.9)',
                    'rgba(225, 225, 225, 0.9)'
                ],
                borderColor: [
                    'rgba(33, 106, 157, 1)',
                    'rgba(53, 161, 235, 1)',
                    'rgba(253, 100, 132, 1)',
                    'rgba(225, 225, 225, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataEal = {
        labels: eal.details.map(detail => detail.name === '' ? 'Not specified' : detail.name === 'true' ? 'EAL' : 'none'),
        datasets: [
            {
                label: 'Dataset EAL',
                data: eal.details.map(detail => detail.value),
                ids: eal.details.map(detail => ({ name: detail.name, category: 'eal' })),
                backgroundColor: [
                    'rgba(255, 159, 64, 0.9)',
                    'rgba(255, 205, 86, 0.9)',
                    'rgba(225, 225, 225, 0.9)',

                ],
                borderColor: [
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 205, 86, 1)',
                    'rgba(225, 225, 225, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };



    const dataPremium = {
        labels: premium.details.map(detail => detail.name === '' ? 'Not specified' : detail.name === 'true' ? 'Premium' : 'none'),
        datasets: [
            {
                label: 'Dataset Premium',
                data: premium.details.map(detail => detail.value),
                ids: premium.details.map(detail => ({ name: detail.name, category: 'pupil_premium' })),
                backgroundColor: [
                    'rgba(45, 57, 159, 0.9)',
                    'rgba(53, 161, 235, 0.9)',
                    'rgba(225, 225, 225, 0.9)',
                ],
                borderColor: [
                    'rgba(45, 57, 159, 1)',
                    'rgba(53, 161, 235, 1)',
                    'rgba(225, 225, 225, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    return (
        <>
            <div className={cx(styles.dataPies, 'u-m-base-3')}>
                {gender?.details.length > 0 &&
                    <div className={styles.dataPieChart}>
                        <div className={styles.dataPieHead}>
                            <h3 className="u-bold">Gender</h3>
                            <p>&nbsp;</p>
                        </div>
                        <Doughnutchart setShowPupils={setShowPupils} dataSet={dataGender} />
                    </div>
                }
                {send?.details.length > 0 &&
                    <div className={styles.dataPieChart}>
                        <div className={styles.dataPieHead}>
                            <h3 className="u-bold">SEND</h3>
                            <p className="small">Special educational needs & disability</p>
                        </div>
                        <Doughnutchart setShowPupils={setShowPupils} dataSet={dataSend} />
                    </div>
                }

                {eal?.details.length > 0 &&
                    <div className={styles.dataPieChart}>
                        <div className={styles.dataPieHead}>
                            <h3 className="u-bold">EAL</h3>
                            <p className="small">English as an additional language</p>
                        </div>
                        <Doughnutchart setShowPupils={setShowPupils} dataSet={dataEal} />
                    </div>
                }

                {premium?.details.length > 0 &&
                    <div className={styles.dataPieChart}>
                        <div className={styles.dataPieHead}>
                            <h3 className="u-bold">Pupil Premium</h3>
                            <p>&nbsp;</p>
                        </div>
                        <Doughnutchart setShowPupils={setShowPupils} dataSet={dataPremium} />
                    </div>
                }
            </div>

            {demographicsData.find(el => el.name === 'ethnicity')?.details.length > 0 &&
                <EthnicityBar ethnicityData={demographicsData.find(el => el.name === 'ethnicity')} setShowPupils={setShowPupils} />
            }

            {
                showPupils &&
                <Modal closeModal={() => setShowPupils(null)}>
                    <h3 className="u-m-base-2">{source.name}</h3>
                    <h3 className="u-m-base-2 light">{titleCase(showPupils.category)} - {getBreakDownTitle(showPupils.name)}</h3>
                    <PupilListBreakDown
                        demographicsCategory={showPupils.category}
                        value={showPupils.name}
                        source={source}
                        isFromClass={isFromClass}
                        school={school}
                    />
                </Modal>
            }
        </>
    )
}

export default Demographics;