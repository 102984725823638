import React, { useState, useEffect } from 'react';
import styles from '../BookSearch.module.scss';
import cx from 'classnames';
import SearchQueries from './searchQueries';
import * as Icon from 'react-feather';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import beep from "../../../sounds/beep.mp3";
import { useTranslation } from 'react-i18next';

const IsbnSearch = ({studentId, close, hide, reset, studentIds, classInfo, setFilter, bookToExistingGroup, groupBookId = null}) => {

    const { t } = useTranslation();
    const [bookCode, setBookCode] = useState("");
    const [scannedCode, setScannedCode] = useState("");
    const [showSearch, setShowSearch] = useState({
        showSearch: false,
        code: null
    })
    const handleSearch = (e) => {
        e.preventDefault();
        setShowSearch({
            show: true,
            code: bookCode
        });
    }

    useEffect(() => {
        let audio = new Audio(beep)
        if (scannedCode !== "") {
            //audio.play();
            const playedPromise = audio.play();
            if (playedPromise) {
                playedPromise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [scannedCode])

    return (
        <>
            <div className={cx(styles.bookSearchPanel, styles.bookSearchPanelISBN)}>
                <Icon.Camera size="40" color="#c33764" className={styles.searchIconISBN}/>
                <h3 className={cx(styles.optionTitle, styles.optionTitleISBN)}>{t('isbn_scanner')}</h3>
                <Icon.XCircle className={styles.closeSearch} onClick={() => hide()}/>
                {!showSearch.show &&
                    <>
                        <form onSubmit={(e) => {handleSearch(e)}} className={cx(styles.searchForm, "u-m-top-10")}>
                            <input type="text" placeholder="ISBN no." onChange={(e) => {setBookCode(e.currentTarget.value)}} value={bookCode} className={cx(styles.searchInput, "u-m-right-10")} />
                            <button type="submit" className={cx(styles.searchBtn, bookCode !== "" && styles.searchBtnActive)} disabled={bookCode === "" && true}>{t('button.search')}</button>
                            {/*<input type="button" value="Clear" onClick={() => {setBookCode("");setShowSearch({showSearch: false, code: null})}} />*/}
                        </form>
                        <div className={styles.scannerHolder}>
                            <BarcodeScannerComponent
                                width={350}
                                height={"auto"}
                                onUpdate={(err, result) => {
                                    if (result) {
                                        if (result.text !== bookCode) {
                                            setScannedCode(result.text);
                                            setBookCode(result.text);
                                        }
                                    }
                                }
                                }
                            />
                        </div>
                        <ul className={cx(styles.help, "u-m-top-20")}>
                            <li>1. {t('use_camera_to_scan_isbn')}.<span className={styles.helpSmall}>{t('try_to_hold_book_flat_to_camera')}</span></li>
                            <li className="u-m-top-10">2. {t('once_scanned_hit_search')}</li>
                        </ul>
                    </>
                }
                {showSearch.show &&
                    <>
                        <div className={styles.bookFind}>
                            <SearchQueries code={showSearch.code} studentId={studentId} close={close} reset={reset} studentIds={studentIds} classInfo={classInfo} setFilter={setFilter} bookToExistingGroup={bookToExistingGroup} groupBookId={groupBookId} />
                        </div>
                        <div className={cx(styles.searchInput, "u-m-top-20")}>{showSearch.code}</div>
                        <p onClick={() => {setShowSearch({...showSearch, show: false}); setBookCode('')}} className={cx(styles.searchAgain, "u-m-top-10")}>{t('try_again')}</p>
                    </>
                }
            </div>
        </>
    )
}

export default IsbnSearch;
