import { gql } from 'apollo-boost';

export const GET_SCHOOL_STATS = gql`
query currentTeacher {
    currentTeacher {
        id
        school {
            id
            studentsWithParentsCounter
            studentsWithNoParentsAssociatedCounter
        }
    }
}
`;

export const GET_SCHOOL_YEAR_STATS = gql`
query currentTeacher {
    currentTeacher {
        id
        school {
            id
            yearGroupWithParentAccountCounters {
                id
                studentCounter
                yearGroup
            }
            yearGroupWithoutParentAccountCounters {
                id
                studentCounter
                yearGroup
            }
        }
    }
}
`;

export const GET_CLASS_PARENTS_STATS = gql`
query currentTeacher {
    currentTeacher {
        id
        school {
            id
            classes {
                id
                name
                studentsWithNoParentsAssociatedCounter
                studentsWithParentsCounter
            }
        }
    }
}
`;

export const GET_YEAR_PARENTS_STATS = gql`
query currentTeacher($yearGroup: Int) {
    currentTeacher {
        id
        school {
            id
            studentsWithNoParentsAssociated(yearGroup: $yearGroup) {
                id
                firstName
                lastName
                upn
            }
        }
    }
}
`;
export const GET_ALL_PARENTS_STATS = gql`
query currentTeacher {
    currentTeacher {
        id
        school {
            id
            studentsWithNoParentsAssociated {
                id
                firstName
                lastName
                upn
            }
        }
    }
}
`;