import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { FormErrors } from '@axeedge/go-teacher-components';
import { EDIT_REVIEW } from "../../services/graphql";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Star } from "react-feather";
import moment from "moment";
import styles from './BookReviews.module.scss';

const EditReview = ({ review, setReview, setShowEdit, stars, }) => {

    const [mutationErrors, setMutationErrors] = useState([]);


    const schema = Yup.object().shape({
        text: Yup.string().required('Please write your text'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });




    const [editReadingBookReview, { loading: editing }] = useMutation(EDIT_REVIEW, {
        update: (_, { data }) => {
            if (data.editReadingBookReview?.errors?.length > 0) {
                setMutationErrors(data.editReadingBookReview.errors);
                return;
            }
            if (data.editReadingBookReview.readingBookReview?.id) {
                setReview({ ...review, reviewText: data.editReadingBookReview.readingBookReview.reviewText });
                setShowEdit(null);
            }
        }
    });

    const onSubmit = values => {
        const { text } = values;
        editReadingBookReview({
            variables: {
                readingBookReviewId: review.id,
                text,
                rate: review.reviewRate
            }
        })
    }

    return (
        <form className={styles.reviewsPanel} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.reviewsInner}>
                <div className={styles.reviewHeader}>
                    <div><p className="bold">{review.author.name}</p></div>
                    <div>
                        {stars.map(star => {
                            return <Star color="#000000" key={`star${star}`} size="26" fill={star <= review.reviewRate ? '#FFD506' : 'none'} />
                        })}
                    </div>
                </div>
                <p className="u-m-base-1 bold lead">{review.book.title}</p>
                <p className="small u-m-base-3">Reviewed on: {moment(review.date).format("DD/MM/YYYY")}</p>

                <div>
                    <textarea
                        className="basic-form__text-box"
                        rows={8}
                        defaultValue={review.reviewText}
                        ref={register}
                        name='text'
                    ></textarea>
                </div>
                <FormErrors errors={mutationErrors || []} />
            </div>
            <div className={styles.reviewFooter}>
                <button type="submit" disabled={editing} className="btn-primary">{editing ? 'Saving...' : 'Save'}</button>
                <button type="button" onClick={() => setShowEdit(null)} className="btn-reset">Cancel</button>
            </div>
        </form>

    )
}

export default EditReview;