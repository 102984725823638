import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ClassFilterContext } from '../../../services/class/ClassFilterProvider';
import { AlertTriangle, XCircle } from 'react-feather';
import DataActionCable from '../../Student/Components/DataDownload/services/DataActionCable';
import { EXPORT_DATA_TICKET } from '../../Student/Components/DataDownload/services/graphql';
import axios from 'axios';

import styles from '../Home.module.scss';

const PupilCsvExport = () => {

    const classFilterContext = useContext(ClassFilterContext);
    const { pupilCsvExport, setPupilCsvExport } = classFilterContext;

    const [startCsvQuery, setStartCsvQuery] = useState(null);
    const [showCsvBanner, setShowCsvBanner] = useState(null);
    const [showCsvError, setShowCsvError] = useState(null);


    const [getUrl, { data, error, loading }] = useLazyQuery(EXPORT_DATA_TICKET, {
        fetchPolicy: 'netork-only',
        onCompleted: (data) => {
            setShowCsvBanner(data.exportDataTicket);
            setStartCsvQuery(null);
            setPupilCsvExport(null);
        }
    })

    useEffect(() => {
        if (startCsvQuery && startCsvQuery.success) {
            getUrl({
                variables: {
                    id: pupilCsvExport.id
                }
            });
        } else if (startCsvQuery && !startCsvQuery.success) {
            setShowCsvError(true);
            setShowCsvBanner(startCsvQuery);
            setPupilCsvExport(null);
        }
    }, [startCsvQuery]);

    const downloadFile = ticket => {
        axios({
            method: 'get',
            url: ticket.exportUrl,
            responseType: 'blob'
        }).then((response => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
            let link = document.createElement('a');
            link.href = downloadUrl;
            const fileName = `${ticket.requestedBy.name}-${ticket.student.name}`;
            link.setAttribute('download', fileName + '.csv');
            document.body.appendChild(link);
            link.click();
        }));
    }

    const renderBanner = () => {
        return (
            <div className={styles.csvToast}>
                <XCircle className={styles.csvToastClose} onClick={() => { setShowCsvBanner(null); setShowCsvError(false) }} />
                {loading && <p>Loading...</p>}
                {error && <p>{error.message}</p>}
                {data && data.exportDataTicket && (
                    <>
                        {
                            data.exportDataTicket.status === 1 ? (
                                <>
                                    <h3 className={styles.csvToastHeader}><AlertTriangle /> The CSV file you requested for {data.exportDataTicket.student.name} is ready for download</h3>
                                    <button className={styles.csvToastBtn} onClick={() => { downloadFile(data.exportDataTicket); setShowCsvBanner (null)}}>Download file</button>

                                </>
                            ) : <p><AlertTriangle size="20" style={{ color: 'red' }} />  There was an error processing the file, please contact <a href="mailto:goapps@squirrellearning.co.uk">goapps@squirrellearning.co.uk</a> for support.</p>
                        }
                    </>
                )}
                {showCsvError && <div>
                    <p>There was an error processing the file</p>
                    <button className={styles.csvToastBtn} onClick={() => { setShowCsvBanner(null); setShowCsvError(false) }}>Close</button>
                </div>}
            </div>
        )
    }

    return (
        <>
            {pupilCsvExport && pupilCsvExport.id && <DataActionCable ticketId={pupilCsvExport.id} setStartCsvQuery={setStartCsvQuery} />}
            {showCsvBanner && renderBanner()}
        </>
    )

}

export default PupilCsvExport;
