import React, { useState } from "react";
import ComparisonFilters from "./ComparisonFilters";
import { Button, Dropdown, Modal } from "@axeedge/go-teacher-components";
import moment from "moment";
import { ChevronDown } from "react-feather";
import { monthNames, radiusOpts, lineColors } from "../../../services/utils";

import Tab from '../../../../../components/Tab';
import Tabs from '../../../../../components/Tabs';
import cx from 'classnames';
import styles from '../SchoolData.module.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffectOnce } from "react-use";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const CompareDataContent = ({ currentUser, compareItem, reportDataItems, onApplyFilters, showFilters, setShowFilters, loading }) => {


    const currentAcadYear = moment().toDate().getMonth() >= 8 ? moment().year() + 1 : moment().year();

    const [viewType, setViewType] = useState('monthly');
    const [currentTab, setCurrentTab] = useState('total');
    const [compareFilters, setCompareFilters] = useState(null);

    const displayOptions = [
        { text: 'Monthly', type: 'monthly', action: () => setViewType('monthly'), styleName: cx({ 'u-text-primary': viewType === 'monthly' }) },
        { text: 'Yearly', type: 'yearly', action: () => setViewType('yearly'), styleName: cx({ 'u-text-primary': viewType === 'yearly' }) },

    ];
    const viewTitle = displayOptions.find(o => o.type === viewType)?.text || '';

    const monthNumbers = compareItem.monthlyReportData.map(mr => mr.month);
    const labels = viewType === 'yearly' ? compareItem.academicYearReports.map(el => el.academicYear) : monthNumbers.map(month => monthNames[month - 1]);
    const getViewTypeData = () => viewType === 'monthly' ? 'monthlyReportData' : 'academicYearReports';

    const chartData = {
        labels,
        datasets: [
            ...reportDataItems.map((kls, i) => {
                return {
                    label: kls.name,
                    data: kls[getViewTypeData()].map(item => currentTab === 'total' ? item.totalLogs : item.totalBooksCompleted),
                    ...lineColors[i % lineColors.length],
                    ...radiusOpts
                }
            },
            )
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                offset: viewType === 'yearly' ? true : false
            }
        }
    };

    const getCategDetails = category => {
        const uniqueElementsMap = {};
        const all = [];
        reportDataItems.forEach(item => {
            if (item.demographics?.find(d => d.name === category)) {
                all.push(...item.demographics.find(d => d.name === category)?.details)
            }
        });
        all.forEach(element => {
            if (!uniqueElementsMap[element.name]) {
                uniqueElementsMap[element.name] = element;
            }
        });
        const uniqDetailsArray = Object.values(uniqueElementsMap);
        return { name: category, details: uniqDetailsArray, id: category };
    }

    const allCategs = ['gender', 'send', 'pupil_premium', 'eal', 'ethnicity']
    const criteria = [...allCategs.map(categ => getCategDetails(categ))];

    useEffectOnce(() => {
        const f = [];
        criteria.forEach(el => {
            f.push({
                category: el.name,
                values: []
            })
        })
        const filtersObjects = f.reduce((acc, curr) => {
            acc[curr.category] = { values: curr.values };
            return acc;
        }, {});
        setCompareFilters(filtersObjects);
    });

    // const showAppliedFiltersNo = (appliedFilters) => {
    //     let count;
    //     if (appliedFilters) {
    //         count = Object.values(appliedFilters).reduce((accumulator, currentValue) => {
    //             return accumulator + currentValue.values.length;
    //         }, 0)
    //     }
    //     return count > 0 ? <span className="light">({count})</span> : null
    // }

    const currentYearReport = reports => reports.find(r => r => r.academicYear === +currentAcadYear);

    return (
        <>
            <h3 className="u-m-base-2 light">Compare {compareItem.name} with: </h3>
            <div className="u-m-base-2">
                {reportDataItems.filter(item => item.id !== compareItem.id).map(item => <span key={`label-${item.id}`} className="label label-muted u-m-right-2">{item.name}</span>)}
                {currentUser.school?.canAccessWonde &&
                    <Button className='btn-small' onClick={() => setShowFilters(true)}>Filters</Button>
                }
            </div>

            <Tabs className='u-m-base-3 u-m-top-3'>
                <Tab selected={currentTab === 'total'} onClick={() => setCurrentTab('total')}>Reading logs</Tab>
                <Tab selected={currentTab === 'books'} onClick={() => setCurrentTab('books')}>Books completed</Tab>
                <span className="small u-text-muted u-m-left-auto">Data is calculated at the end of each day</span>
            </Tabs>

            <div className="u-flex-center u-m-base-3">
                Display:
                <Dropdown icon={ChevronDown} dropdownOpts={displayOptions} classNames={styles.viewOptionBtn} leftAligned titleLeft={viewTitle} />
            </div>

            <Line options={options} data={chartData} />

            <div className={cx(styles.compStats, 'u-m-top-4')}>
                <ul className={styles.compStatsHeader}>
                    {reportDataItems.map(item => <li className={cx(styles.compStatsNo, 'u-text-center')} key={`header-${item.id}`}>{item.name}</li>)}
                </ul>
                <ul>
                    <li className={styles.compStatsRow}>
                        <div className={styles.compStatsLabel}>No. of Student Accounts</div>
                        {reportDataItems.map(el => <div key={`active-${el.id}`} className={cx(styles.compStatsNo, 'u-text-center')}>{currentYearReport(el.academicYearReports)?.activeAccounts}</div>)}
                    </li>

                    <li className={styles.compStatsRow}>
                        <div className={styles.compStatsLabel}>No. of Active Parent Accounts</div>
                        {reportDataItems.map(el => <div key={`active-${el.id}`} className={cx(styles.compStatsNo, 'u-text-center')}>{currentYearReport(el.academicYearReports)?.activeParents || 0}</div>)}
                    </li>
                    <li className={styles.compStatsRow}>
                        <div className={styles.compStatsLabel}>Books completed/year</div>
                        {reportDataItems.map(el => <div key={`active-${el.id}`} className={cx(styles.compStatsNo, 'u-text-center')}>{currentYearReport(el.academicYearReports)?.totalBooksCompleted}</div>)}
                    </li>
                </ul>
            </div>

            {
                showFilters && <Modal closeModal={() => setShowFilters(false)}>
                    <ComparisonFilters loading={loading} applyFilters={onApplyFilters} criteria={criteria} filters={compareFilters} setFilters={setCompareFilters} />
                </Modal>
            }

        </>
    )
}

export default CompareDataContent;