import React, { useContext } from "react";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import DataDashNav from "../../components/DataDashNav";
import DataDashMainContent from "./DataDashMainContent";
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import Vimeo from '@u-wave/react-vimeo';

import styles from './SchoolData.module.scss';

const DataDashboardSchool = () => {

    const { currentUser } = useContext(AuthContext);

    if (!currentUser) {
        return <>Loading</>
    }

    return (
        <>
            <DataDashNav />
            <div className={styles.dashWrapper}>
                {!currentUser.school?.canAccessDataDashboard ?
                    <div className={styles.noAccess}>
                        <div>
                        <h3 className="heavy u-m-base-3">Monitor levels of reading engagement, compare reading trends and parental engagement. Find out more...</h3>
                            {/* <Vimeo video='https://vimeo.com/589796391/ff841580aa' responsive /> */}
                        </div>
                        <div className="card">
                            <div className="card-body u-text-center">
                                <p className="u-bold">The Data Dashboard requires a subscription</p>
                                <p className="u-m-top-2 u-m-base-3">£1 per pupil <br />(annual subscription)</p>
                                {currentUser.isSchoolAdmin ? (
                                    <p><a className="btn-link" href={`${getUrl(APPS.go_portal_teacher)}/settings/boomreader`}>Subscribe</a></p>
                                ) : <p className="small">Contact your system admin to sign up</p>}
                            </div>

                        </div>
                    </div>
                    :
                    <DataDashMainContent currentUser={currentUser} />
                }
            </div>
        </>
    )

}

export default DataDashboardSchool;