import React from 'react';
import styles from './Notes.module.scss';
import { Edit3 } from 'react-feather';
import cx from 'classnames';
import moment from 'moment';

const NoteItem = ({ note, setEditNote }) => {


    return (
        <>

            <div className={styles.note}>
                <div className={styles.noteSide}>
                    <p>{note.creator.displayName}</p>
                    <p className='u-m-base-2'>{moment(note.createdAt).format('DD/MM/YYYY')}</p>
                    {note.updater && (
                        <>
                            <p>Modified by:</p>
                            <p>{note.updater.displayName}</p>
                            <p>{moment(note.updatedAt).format('DD/MM/YYYY')}</p>
                        </>
                    )}

                </div>
                <div className={styles.noteMain}>
                    <div className={styles.noteHeader}>
                        {note.title && <h4 className={styles.noteHeaderTitle}>{note.title}</h4>}
                        <button onClick={() => setEditNote(note)} className={cx('btn-reset', styles.noteHeaderEdit)}><Edit3 /></button>
                    </div>
                    <p>{note.body}</p>
                </div>
            </div>

        </>
    )
}

export default NoteItem;