import React from 'react';
import AssignContent from './assignContent';
import { useQuery } from '@apollo/react-hooks';
import styles from '../Banding.module.scss';
import cx from 'classnames';
import { GET_READING_BANDS } from '../../../components/BandingInfoPanel/services/graphql';
import { useTranslation } from 'react-i18next';

const AssignBand = ({close, student}) => {

    const { t } = useTranslation();
    const { data, error, loading } = useQuery(GET_READING_BANDS);

    return (
        <div className={styles.modalBg} onClick={() => close({show: false})}>
            <div className={cx(styles.modalInner,styles.modalInnerAssign)} onClick={(e) => e.stopPropagation()}>
                {error && <p>{t('failed_to_fetch_bands')}</p>}
                {loading && <p>{t('loading')}</p>}
                {data && <AssignContent data={data} student={student} close={close} />}
            </div>
        </div>
    )
}

export default AssignBand;