import React, { useState, useEffect, useContext } from 'react';
import { MARK_LOG_AS_LIKED, MARK_LOG_AS_SEEN, GET_SINGLE_BOOK, GET_GROUP_BOOK } from '../services/graphql';
import { useMutation } from '@apollo/react-hooks';
import { useIntersection } from 'react-use';
import * as Icon from 'react-feather';
import { Tooltip } from 'react-tippy';
import { BooksContext } from '../../../services/books/BooksProvider';

import ls from 'local-storage';
import cx from 'classnames';
import styles from './Search.module.scss';

const LogAcknowledgement = ({ book, log, teacher, classId, intersectionRef, fromGroup = false }) => {

    const bookContext = useContext(BooksContext)
    const { activityFeed, setActivityFeed, classActivityFeed, setClassActivityFeed } = bookContext;


    const likeBtnDisabled = (log.likes && log.likes.find(el => el.author.id === teacher?.id)) || log.author.role === 'Teacher';

    const [markLogAsLiked, { loading: liking }] = useMutation(MARK_LOG_AS_LIKED, {
        onCompleted: (data) => {
            if (data && data.markLogAsLiked && data.markLogAsLiked.errors && data.markLogAsLiked.errors.length > 0) {
                return;
            }
            if (data && data.markLogAsLiked && data.markLogAsLiked.logLiked) {
                const newFeed = activityFeed.map(el => (el.studentReadingBookLogId === log.id || el.groupReadingBookLogId === log.id) ? { ...el, likedBy: [...(el.likedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
                const newClassFeed = classActivityFeed.map(el => (el.studentReadingBookLogId === log.id || el.groupReadingBookLogId === log.id) ? { ...el, likedBy: [...(el.likedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setClassActivityFeed(newClassFeed);

            }
        },
        refetchQueries: () => [
            !book.bookType ?
                {
                    query: GET_GROUP_BOOK,
                    variables: {
                        id: book.id
                    }
                } :
                {
                    query: GET_SINGLE_BOOK,
                    variables: {
                        id: book.id
                    }
                }
        ]
    });

    const [markLogAsSeen] = useMutation(MARK_LOG_AS_SEEN, {
        onCompleted: (data) => {
            if (data && data.markLogAsViewed && data.markLogAsViewed.errors && data.markLogAsViewed.errors.length > 0) {
                return;
            }
            if (data && data.markLogAsViewed && data.markLogAsViewed.logViewed) {
                const newFeed = activityFeed.map(el => (el.studentReadingBookLogId === log.id || el.groupReadingBookLogId === log.id) ? { ...el, viewedBy: [...(el.viewedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
                const newClassFeed = classActivityFeed.map(el => (el.groupReadingBookLogId === log.id || el.studentReadingBookLogId === log.id) ? { ...el, viewedBy: [...(el.viewedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setClassActivityFeed(newClassFeed);
            }
        },
        refetchQueries: () => [
            !book.bookType ?
                {
                    query: GET_GROUP_BOOK,
                    variables: {
                        id: book.id
                    }
                } :
                {
                    query: GET_SINGLE_BOOK,
                    variables: {
                        id: book.id
                    }
                }
        ]
    });

    const [itemVisible, setItemVisible] = useState(false);

    const teacherOfClass = classId && teacher?.studentsClasses.find(el => el.id === classId);
    const userShouldView = !ls('go_impersonating') && teacher?.school?.allowSeens && teacherOfClass && (!log.views || !log.views.find(el => el.author.id === teacher.id));

    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    useEffect(() => {
        if (intersection && intersection.intersectionRatio === 1 && log.author.role !== 'Teacher') {
            setItemVisible(true);
        }
    }, [intersection, log]);


    useEffect(() => {
        // Not called for logs added by teachers (correct)
        if (userShouldView && itemVisible) {
            !book.bookType && log.studentIds?.length > 0 ?
                markLogAsSeen({
                    variables: {
                        groupReadingBookLogId: log.id, studentId: log.studentIds[0]
                    }
                }) :
                markLogAsSeen({
                    variables: {
                        studentReadingBookLogId: log.id
                    }
                })
        }
    }, [itemVisible, userShouldView]);

    return (
        <span className='u-m-left-2'>
            {teacher?.school?.allowLikes &&
                <span className='u-m-right-1'>
                    <Tooltip
                        disabled={!log.likes || log.likes.length === 0}
                        size='big'
                        animateFill={false}
                        html={<div>{log.likes && log.likes.map(el => <p key={`tip-${el.id}`}>{el.author.displayName}</p>)}</div>}
                    >
                        {/* send groupReadingBookLogId && studentId for group books */}
                        <button onClick={() =>
                            !book.bookType && log.studentIds?.length > 0  ? 
                                markLogAsLiked({
                                    variables: {
                                        groupReadingBookLogId: log.id, studentId: log.studentIds[0]
                                    }
                                }) :
                                markLogAsLiked({
                                    variables: {
                                        studentReadingBookLogId: log.id
                                    }
                                })
                        }
                            className={(cx(styles.likeButton, { [styles.likeButtonActive]: log.likes && log.likes.length > 0 }))}
                            disabled={likeBtnDisabled || liking}
                        ><Icon.Heart /></button>{log.likes && log.likes.length > 0 && <>({log.likes.length})</>}
                    </Tooltip>
                </span>
            }

            {/* don't show to teacher their own seens on Parent Logs OR Student logs, but show parent seens on student logs */}
            {
                teacher?.school?.allowSeens && log.author.role !== 'Parent' &&
                <span className=''>
                    <Tooltip
                        disabled={!log.views || log.views.filter(el => el.author.id !== teacher?.id).length === 0}
                        size='big'
                        animateFill={false}
                        html={<div>{log.views && log.views.filter(el => el.author.id !== teacher.id).map(el => <p key={`seen-${el.id}`}>{el.author.displayName}</p>)}</div>}
                    >
                        <span
                            className={(cx(styles.seeButton, { [styles.seeButtonActive]: log.views && log.views.filter(el => el.author.id !== teacher.id).length > 0 }))}
                        ><Icon.Eye /></span>{log.views && log.views.filter(el => el.author.id !== teacher.id).length > 0 && <>({log.views.filter(el => el.author.id !== teacher.id).length})</>}
                    </Tooltip>
                </span>
            }
        </span >
    )
}

export default LogAcknowledgement;