import { gql } from 'apollo-boost';
import { studentReadingBookQuery } from '../../../utils/constants';


export const GET_PUBLISHERS_QUERY = gql`
query getPublishers {
    publishers {
        id
        name
        description
    }
}
`;

export const BOOK_TITLE_SEARCH_QUERY = gql`
query bookTitleSearch($title: String!, $publisherId: ID, $searchApi: Boolean, $startIndex: Int) {
    booksByTitle(title: $title, searchApi: $searchApi, publisherId: $publisherId, startIndex: $startIndex) {
        items {
            id
            author
            isbn
            isbn13
            coverUrl
            title
            description
            existsInDb
        }
        total
    }
}
`;


export const BOOK_ISBN_SEARCH_QUERY = gql`
query bookIsbnSearch($isbn: String!) {
    bookByIsbn(isbn: $isbn) {
        id
        author
        isbn
        isbn13
        coverUrl
        title
        description
        existsInDb
    }
}
`;

export const ADD_READING_BOOK = gql`
mutation addReadingBookAndAssignToStudent($title: String!,
    $author: String,
    $isbn: String,
    $isbn13: String,
    $coverUrl: String,
    $description: String,
    $bookType: Int!,
    $hideComments: Boolean!,
    $publisherId: String,
    $studentId: ID!) {
        addReadingBookAndAssignToStudent(title: $title, author: $author, isbn: $isbn, isbn13: $isbn13, coverUrl: $coverUrl, description: $description, studentId: $studentId, bookType: $bookType, hideComments: $hideComments, publisherId: $publisherId) {
        studentReadingBook {
            id
        }
        errors
    }
}
`;

export const ASSIGN_READING_BOOK = gql`
mutation assignReadingBookToStudent(
    $bookId: ID!,
    $bookType: Int!,
    $hideComments: Boolean!,
    $studentId: ID!,
    $isReread: Boolean) {
        assignReadingBookToStudent(bookId: $bookId, studentId: $studentId, bookType: $bookType, hideComments: $hideComments, isReread: $isReread) {
        studentReadingBook {
            ${studentReadingBookQuery}
        }
        errors
    }
}
`;

export const ASSIGN_READING_BOOK_CLASS = gql`
mutation assignReadingBookToClass(
    $bookId: ID!,
    $bookType: Int!,
    $hideComments: Boolean!,
    $studentsClassId: ID!) {
        assignReadingBookToClass(bookId: $bookId, studentsClassId: $studentsClassId, bookType: $bookType, hideComments: $hideComments) {
        studentsClass{
            id
        }
        errors
    }
}
`;

export const ADD_READING_BOOK_CLASS = gql`
mutation addReadingBookAndAssignToClass($title: String!,
    $author: String,
    $isbn: String,
    $isbn13: String,
    $coverUrl: String,
    $description: String,
    $bookType: Int!,
    $hideComments: Boolean!,
    $publisherId: String,
    $studentsClassId: ID!) {
        addReadingBookAndAssignToClass(title: $title, author: $author, isbn: $isbn, isbn13: $isbn13, coverUrl: $coverUrl, description: $description, studentsClassId: $studentsClassId, bookType: $bookType, hideComments: $hideComments, publisherId: $publisherId) {
        studentReadingBook {
            id
        }
        errors
    }
}
`;

export const ADD_GROUP = gql`
    mutation addGroup(
        $schoolId: ID!,
        $studentsClassId: ID!,
        $name: String!
    ) {
        addGroup(schoolId: $schoolId, studentsClassId: $studentsClassId, name: $name) {
            group {
                id
            }
            errors
        }
    }
`
export const ASSIGN_STUDENTS_TO_GROUP = gql`
    mutation assignStudentsToGroup($groupId: ID!, $studentIds: [ID!]!) {
        assignStudentsToGroup(groupId: $groupId, studentIds: $studentIds) {
            group {
                id
            }
            errors
        }
    }
`

export const ASSIGN_READING_BOOK_GROUP = gql`
    mutation assignReadingBookToGroup($bookId: ID!, $groupId: ID!, $hideComments: Boolean!, $groupReadingBookId: ID) {
        assignReadingBookToGroup(bookId: $bookId, groupId: $groupId, hideComments: $hideComments, groupReadingBookId: $groupReadingBookId) {
            groupReadingBook {
                id
                createdAt
                book {
                    id
                    title
                }
                students {
                    id
                    firstName
                    lastName
                }
            }
            errors
        }
    }
`

export const ADD_READING_BOOK_AND_ASSIGN_TO_GROUP = gql`
    mutation addReadingBookAndAssignToGroup($title: String!,$author: String,$isbn: String,$isbn13: String,$coverUrl: String,$description: String,$groupId: ID!,$hideComments: Boolean!,$publisherId: String, $groupReadingBookId: ID) {
        addReadingBookAndAssignToGroup(title: $title, author: $author, isbn: $isbn, isbn13: $isbn13, coverUrl: $coverUrl, description: $description, hideComments: $hideComments, publisherId: $publisherId, groupId: $groupId, groupReadingBookId: $groupReadingBookId) {
            groupReadingBook {
                id
                createdAt
                book {
                    id
                    title
                }
                students {
                    id
                    firstName
                    lastName
                }
            }
            errors
        }
    }
`