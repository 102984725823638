import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClassChart from './ClassChart';
import styles from './ClassStats.module.scss';
import cx from 'classnames';

const AnalyticsData = ({ classInfo, analyticsData, acadYear, dateRange, sortType, setSortType }) => {

    const { t } = useTranslation();

    const [baseStudents, setBaseStudents] = useState(analyticsData.students);
    const [sortedStudents, setSortedStudents] = useState(baseStudents);


    useEffect(() => {
        if (analyticsData.students !== baseStudents) {
            setBaseStudents(analyticsData.students);
        }
    }, [analyticsData]);

    useEffect(() => {
        if (sortType === 'highest') {
            setSortedStudents([...baseStudents].sort((a, b) => b.counter - a.counter));
        } else if (sortType === 'flagged') {
            setSortedStudents([...baseStudents].sort((a, b) => b.flagType - a.flagType));
        } else {
            setSortedStudents([...baseStudents].sort((a, b) => a.lastName.localeCompare(b.lastName)));
        }
    }, [sortType, baseStudents, setSortedStudents]);


    return (
        <>
            <div className={styles.mainContent}>
                <div className={styles.mainContentData}>
                    <div className={styles.orderDataWrapper}>
                        <select value={sortType} className={cx('basic-form__text-select', styles.sortTypeSelect)} onChange={(e) => setSortType(e.target.value)}>
                            <option value='highest'>{t('order_highest')}</option>
                            <option value='az'>{t('student_name_last')}</option>
                            <option value='flagged'>{t('order_flagged')}</option>
                        </select>
                        <ul className={styles.chartLegend}>
                            <li><span className={styles.chartLegendCurrent}></span> {acadYear} - {acadYear + 1}</li>
                            <li><span></span> {acadYear - 1} - {acadYear}</li>
                        </ul>
                    </div>
                    <ClassChart dateRange={dateRange} classInfo={classInfo} studentsData={sortedStudents} />
                </div>
                <div className={styles.mainContentAverages}>
                    <p>{t('average_reading_logs')}</p>
                    <ul className={styles.averageList}>
                        <li>
                            <span className={styles.averageListNo}>{analyticsData.averageParentLogs}</span> {t('parent')}
                        </li>
                        <li>
                            <span className={styles.averageListNo}>{analyticsData.averageTeacherLogs}</span> {t('teacher')}
                        </li>
                        <li>
                            <span className={styles.averageListNo}>{analyticsData.averageStudentLogs}</span> {t('pupil')}
                        </li>
                        <li className={styles.averageListBooks}>
                            <span className={styles.averageListNo}>{analyticsData.averageBooksRead}</span>
                            <div>
                                <span className={styles.averageListExtra}>{t('average')}</span> <p>{t('books_read')}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AnalyticsData;