import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import { GET_STUDENT_QUERY } from './services/graphql';
import Content from './content';
import _ from 'lodash';
import { RB_COMPLETE } from '../../utils/constants';

const Student = ({ id, schoolOnly, oldLocation, standAlone=false }) => {
    const { data, error, loading } = useQuery(GET_STUDENT_QUERY, {
        variables: {
            id
        }
    });

    const createData = (data) => {
        const readingBook = _.filter(data.student.readingBooks, book => {
            if (schoolOnly) {
                return book.status !== RB_COMPLETE && book.bookType === 1
            }
            return book.status !== RB_COMPLETE;
        })[0];
        const recentLog = readingBook && readingBook.mostRecentLog;
        return {
            student: {
                name: data.student.name,
                id: data.student.id
            },
            readingBook: readingBook,
            pageStart: recentLog && recentLog.pageEnd,
            oldLocation
        }
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data) {
        return (
            <Content data={createData(data)} standalone={standAlone} />
        );
    }

}

export default Student;