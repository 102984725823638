import React, {useState, useEffect} from 'react'
import styles from './parentDataContent.module.scss'
import cx from 'classnames'
import { useQuery } from '@apollo/react-hooks'
import { ArrowRight } from 'react-feather';
import {GET_CLASS_PARENTS_STATS} from "../services/graphql";
import { Link } from '@reach/router';
const ParentsByClass = () => {
    const { data, error, loading } = useQuery(GET_CLASS_PARENTS_STATS);
    const [classes, setClasses] = useState(null)
    const [sort, setSort] = useState(0) 

    useEffect(() => {
        if (sort === 1) {setClasses([...classes].sort(compareWithParents))};
        if (sort === 2) {setClasses([...classes].sort(compareWithParents).reverse())}
        if (sort === 3) {setClasses([...classes].sort(compareWithoutParents))}
        if (sort === 4) {setClasses([...classes].sort(compareWithoutParents).reverse())}
        if (sort === 5) {setClasses([...classes].sort(comparePercentWithout))}
        if (sort === 6) {setClasses([...classes].sort(comparePercentWithout).reverse())}
    }, [sort])

    function compareWithParents( a, b ) {
        if ( a.studentsWithParentsCounter < b.studentsWithParentsCounter ){
          return -1;
        }
        if ( a.studentsWithParentsCounter > b.studentsWithParentsCounter ){
          return 1;
        }
        return 0;
    }

    function compareWithoutParents( a, b ) {
        if ( a.studentsWithNoParentsAssociatedCounter < b.studentsWithNoParentsAssociatedCounter ){
          return -1;
        }
        if ( a.studentsWithNoParentsAssociatedCounter > b.studentsWithNoParentsAssociatedCounter ){
          return 1;
        }
        return 0;
    }

    function comparePercentWithout( a, b ) {
        if ( a.percentWithout < b.percentWithout ){
          return -1;
        }
        if ( a.percentWithout > b.percentWithout ){
          return 1;
        }
        return 0;
    }

    useEffect(() => {
        if(data) {
            let tempClasses = []
            data.currentTeacher.school.classes.map((c) => {
                if (c.studentsWithParentsCounter + c.studentsWithNoParentsAssociatedCounter === 0) {return null}
                let newClass = {
                    ...c,
                    percentWith: (c.studentsWithParentsCounter/(c.studentsWithNoParentsAssociatedCounter + c.studentsWithParentsCounter))*100
                }
                tempClasses.push(newClass)
            })
            setClasses(tempClasses)
        }
    }, [data])

    if (loading) {
        return <p>Fetching Data&hellip;</p>
    }
    if (data) {
        return (
            <table className={cx("u-m-top-4", styles.dataTable)}>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th className={styles.colHeader} onClick={() => setSort(sort === 1 ? 2 : 1)}>Parent Linked</th>
                    <th className={styles.colHeader} onClick={() => setSort(sort === 3 ? 4 : 3)}>Parent Not Linked</th>
                    <th className={styles.colHeader} onClick={() => setSort(sort === 5 ? 6 : 5)}>% With Linked Parent</th>
                </tr>
            </thead>
            <tbody>
                {classes && classes.map(y => {
                    return (
                        <tr className={styles.dataRow} key={`y${y.id}`}>
                            <td className={styles.dataName}><Link to={`/class/${y.id}?viewParents=true`} className={styles.dataNameInner}>{y.name} <ArrowRight size="16"/></Link></td>
                            <td className={styles.dataCell}><span className={cx(styles.heroStat, styles.dataGreen)}>{y.studentsWithParentsCounter}</span></td>
                            <td className={styles.dataCell}><span className={cx(styles.heroStat, styles.dataRed)}>{y.studentsWithNoParentsAssociatedCounter}</span></td>
                            <td className={styles.dataCell}><span className={cx(styles.heroStat)}>{(Math.round(y.percentWith * 10) / 10)}%</span></td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
        )
    }
    return null
}

export default ParentsByClass