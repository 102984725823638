import moment from 'moment';

export const getWeekDays = (weekStart) => {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

export const getWeekRange = (date) => {
    return {
        from: moment(date).startOf('week').toDate(),
        to: moment(date).endOf('week').toDate(),
    };
}

export const logFilterLabel = filter => {
    switch (filter) {
        case 1:
            return "Teacher"
        case 2:
            return "Pupil"
        case 3:
            return "Parent"
        case 4:
            return "Group"
        default:
            return "-"
    }
}