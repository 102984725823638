import React from "react";
import { Link } from '@reach/router';
import { useQuery } from "@apollo/react-hooks";
import { CLASS_DEMOGRAPHIC_FILTERED_STUDENTS, YEAR_GROUP_DEMOGRAPHIC_FILTERED_STUDENTS } from "../../../services/graphql";
import { Loader } from "@axeedge/go-teacher-components";

const PupilListBreakDown = ({ source, value, demographicsCategory, isFromClass, school }) => {

    const { data, loading, error } = useQuery(CLASS_DEMOGRAPHIC_FILTERED_STUDENTS, {
        variables: {
            id: source.id,
            demographicsCategory,
            value,
        },
        skip: !isFromClass
    });


    const { data: dataYg, loading: loadingYg, error: errorYg } = useQuery(YEAR_GROUP_DEMOGRAPHIC_FILTERED_STUDENTS, {
        variables: {
            code: +source.code,
            schoolId: school?.id,
            demographicsCategory,
            value,
        },
        skip: isFromClass
    });

    const StudentItem = ({student}) => {
        return (
            <li className="u-m-base-1">
                <Link className="u-flex-center u-justify-between" to={`/student/${student.id}`}>
                    <p>{student.name}</p>
                    <p>{student.upn}</p>
                </Link>
            </li>
        )
    }

    if (loading || loadingYg) {
        return <Loader />
    }

    if (error || errorYg) {
        return <p>{error.message}</p>
    }

    if (data?.studentsClass?.demographicsFilteredStudents || dataYg?.yearGroup?.demographicsFilteredStudents) {

        return (
            <>
                <div className="u-flex-center u-justify-between u-m-top-2 u-m-base-2">
                    <p className="u-bold">Name</p>
                    <p className="u-bold">UPN</p>
                </div>
                <ul>
                    {isFromClass ?
                        data.studentsClass.demographicsFilteredStudents.map(student => <StudentItem key={student.id} student={student} />) :
                        dataYg?.yearGroup.demographicsFilteredStudents.map(student => <StudentItem key={student.id} student={student} />)}
                </ul>
            </>
        )
    }
    return null;
}

export default PupilListBreakDown;