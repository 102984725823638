import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { AuthContext } from '../../services/auth/AuthProvider';
import { Loader } from '@axeedge/go-teacher-components';
import StudentTileList from './components/StudentTileList'
import { GET_CLASS_QUERY, GET_CLASS_STATS_QUERY, GET_CLASS_DATA } from './services/graphql';
import PrintableStudentsList from './components/PrintableStudentsList';
import PrintableParentLetters from './components/PrintableParentLetters';
import * as Icon from 'react-feather';

import { parse } from "query-string";
import { useLocation } from 'react-use';
import cx from 'classnames';
import styles from './Class.module.scss';

const ClassNew = (props) => {

    const { id, studentView = false } = props
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const client = useApolloClient();
    const [initiateStats, setInitiateStats] = useState(false);

    const [backToSchoolStats, setBackToSchoolStats] = useState(false);

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getClassData, { data: classData, loading: classLoading }] = useLazyQuery(GET_CLASS_DATA, {
        variables: {
            id
        },
        onCompleted: ({ studentsClass }) => {
            if (studentsClass && studentsClass.id) {
                console.log("fetched data")
            }
        }
    })


    const [getClassStats, { data: classStats }] = useLazyQuery(GET_CLASS_STATS_QUERY, {
        variables: {
            id
        },
        /*fetchPolicy: 'cache-and-network',*/
        onCompleted: ({ studentsClass }) => {
            if (studentsClass && studentsClass.id) {
                const newStudents = [];
                data.studentsClass.students.forEach(student => {
                    const matchStudent = studentsClass.students.find(s => s.id === student.id);
                    newStudents.push({
                        ...student,
                        stats: matchStudent.stats,
                    })
                })
                client.writeQuery({
                    query: GET_CLASS_DATA,
                    variables: { id },
                    data: {
                        studentsClass: {
                            ...data.studentsClass,
                            students: newStudents
                        }
                    }
                });
            }
        }
    })

    useEffect(() => {
        if (classData && classData.studentsClass && classData.studentsClass.id && !classStats && initiateStats) {
            getClassStats();
        }
    }, [classData, classStats, getClassStats, initiateStats]);

    useEffect(() => {
        if (initiateStats) {
            getClassData()
        }
    }, [initiateStats])


    const location = useLocation();

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading && !data) {
        return <Loader />
    }

    if (data.studentsClass && data.studentsClass.id && teacher) {
        if (teacher && teacher.school && parse(location.search).printStudents) {
            return <PrintableStudentsList classData={data.studentsClass} students={data.studentsClass.students} school={teacher.school} />
        }

        if (teacher && teacher.school && parse(location.search).printParentLetters) {
            return <PrintableParentLetters classData={data.studentsClass} students={data.studentsClass.students} school={teacher.school} />
        }
        return (
            <Fragment>
                <div className="page-header u-m-base-0">
                    <div className='page-header__content u-display-flex u-align-center'>
                        {!studentView && backToSchoolStats && <Icon.ArrowLeft size="26" className='u-m-right-2 page-header__back' onClick={() => window.history.back()} />}
                        <h1 className={cx(`page-header__content__title`, studentView && styles.studentViewHeading)}>{data.studentsClass.name}</h1>
                    </div>
                </div>
                <div className='card-mobile'>
                    <StudentTileList
                        studentView={studentView}
                        classLoading={classLoading}
                        studentsData={classData ? classData.studentsClass.students : []}
                        students={data.studentsClass.students}
                        classInfo={data.studentsClass}
                        school={classData && classData.studentsClass.school}
                        allLoaded={data && classStats}
                        teacher={teacher}
                        initiateStats={setInitiateStats}
                        backToClass={props.location && props.location.state && props.location.state.backToClass}
                        setBackToSchoolStats={setBackToSchoolStats}
                    />
                </div>
            </Fragment>
        )
    }

    return null;
}

export default ClassNew;