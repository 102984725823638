import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { useTranslation } from 'react-i18next';
import { ADD_STUDENT_NOTE } from '../../../services/graphql';
import { BooksContext } from '../../../../../services/books/BooksProvider';

const AddNote = ({ close, student, feedbackInModal = false, fromClassPage = false, notes }) => {

    const { t } = useTranslation();

    const bookContext = useContext(BooksContext)
    const { activityFeed, setActivityFeed } = bookContext;

    const [formErrors, setFormErrors] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);

    const [addNote, { loading }] = useMutation(ADD_STUDENT_NOTE, {
        update: (_, { data }) => {
            if (data.addStudentNote.errors && data.addStudentNote.errors.length > 0) {
                setFormErrors(data.addStudentNote.errors);
                return
            }
            if (data.addStudentNote.note && data.addStudentNote.note.id) {
                notes && notes.unshift(data.addStudentNote.note);

                const newFeed = activityFeed;
                newFeed.push({
                    id: data.addStudentNote.note.id,
                    activityType: 10,
                    activityDate: data.addStudentNote.note.createdAt,
                    author: data.addStudentNote.note.creator.displayName,
                })
                setActivityFeed(newFeed);
                feedbackInModal ? setShowFeedback(true) : close();
            }
        }
    });

    const schema = Yup.object().shape({
        body: Yup.string().required('Please write your note'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = values => {
        const { title, body } = values;
        addNote({
            variables: {
                studentId: student.id,
                title,
                body
            }
        })
    }

    return (
        <>
            <h2 className='u-m-base-3'>Add a note</h2>
            {showFeedback ? (
                <>
                    <p className='u-m-base-3'>Thanks, your note has been added!</p>
                    <Button type="button" outline onClick={() => close()}>Done</Button>

                </>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className='u-bold u-m-base-2'>Title (optional)</p>
                    <div className="basic-form__group ">
                        <input
                            name="title"
                            className="basic-form__text-box"
                            ref={register}
                            placeholder='Title'
                        />
                    </div>
                    <p className='u-bold u-m-base-1'>Note</p>
                    <div className="basic-form__group ">
                        <textarea
                            name="body"
                            rows="6"
                            className="basic-form__text-box"
                            ref={register}
                            placeholder='Add your note'
                        ></textarea>
                    </div>
                    {errors.body && <p className='basic-form__hint'>{errors.body.message}</p>}
                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                    <Button type='submit' disabled={loading} className='u-m-right-1'>{loading ? 'Saving' : 'Add note'}</Button>
                    <Button type="button" outline onClick={() => close()}>{t('button:cancel')}</Button>
                </form>
            )}
        </>
    )
}

export default AddNote;