import React from 'react';

import StudentQuery from './StudentQuery';

const LogReading = ({ id, location }) => {
    if (location.state) {
        return <StudentQuery id={location.state.student.id} {...location.state} standalone={true} />
    }
    return <StudentQuery id={id} />
    // if (props.location.state === null) {
    //     return <StudentQuery id={props.id}/>
    // }

    // return <Content data={props.location.state} />
}

export default LogReading;