import { gql } from 'apollo-boost';

export const GET_CLASS_QUERY = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        school {
            id
        }
        yearGroupConfirmed
        studentsWithNoParentsAssociatedCounter
        studentsWithParentsCounter
        students {
            id
            loginCode
            name
            firstName
            lastName
            flagType
            yearGroup
            parentsCounter
            upn
        }
    }
}
`;


export const GET_CLASS_DATA = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        description
        school {
            id
            readingBands {
                id
            }
        }
        students {
            id
            avatarJson
            boomerAvatarUrl
            updatedAt
            firstName
            lastName
            loginCode
            name
            flagType
            parentsCounter
            currentReadingRank {
                id
                readingBandRank {
                    id
                    name
                    colourCode
                }
            }
            studentReadingBandRanks {
                id
                assignedAt
                readingBandRank {
                    id
                    assignedAt
                    name
                    colourCode
                }
            }
            email
            readingBooks {
                id
                status
                bookType
                book {
                    id
                    title
                }
            }
            stats(addEmpty: true) {
                id
                parentLogs
                studentLogs
                teacherLogs
                lastParentLog
                lastStudentLog
                lastTeacherLog
                totalBooksCounter
                currentYearParentLogsCounter
                currentYearStudentLogsCounter
                currentYearTeacherLogsCounter
                currentWeekBooksCounter
                currentYearBooksCounter
            }
            stats(addEmpty: true) {
                id
                parentLogs
                studentLogs
                teacherLogs
            }
        }
    }
}
`;

export const GET_CLASS_STATS_QUERY = gql`
query studentsClassStats($id: ID!) {
    studentsClass(id: $id) {
        id
        students {
            id
            stats {
                id
                parentLogs
                studentLogs
                teacherLogs
                lastParentLog
                lastStudentLog
                lastTeacherLog
                currentYearParentLogsCounter
                currentYearStudentLogsCounter
                currentYearTeacherLogsCounter
                currentWeekBooksCounter
                currentYearBooksCounter
                totalBooksCounter
            }
        }
    }
}
`;


export const GET_CLASS_ACTIVITY = gql`
    query studentClassActivity($id: ID!, $startDate: String!, $duration: Int!, $activityType: [Int!]) {
        studentsClass(id: $id)
        {
            id
            activities(startDate: $startDate, duration: $duration, activityType: $activityType) {
                items {
                    id
                    activityDate
                    activityType
                    author
                    bookName
                    bookType
                    fromPage
                    rankName
                    reviewRate
                    reviewText
                    rankColourCode
                    isBookCompletion
                    logComment
                    studentReadingBookLogId
                    readingBookReviewId
                    groupReadingBookLogId
                    studentNoteId
                    viewedBy {
                        id
                        name
                    }
                    likedBy {
                        id
                        name
                    }
                    student {
                        id
                        name
                    }
                    students
                    toPage
                }
            }
        }
    }
`


export const CLASS_STATS_PER_INTERVAL = gql`
mutation studentsClassStatsPerInterval(
    $studentsClassId: ID!,
    $startDate: String!,
    $endDate: String!
) {
    studentsClassStatsPerInterval(studentsClassId: $studentsClassId, startDate: $startDate, endDate: $endDate ) {
        errors
        studentsClass {
            id
        }
    } 
} 
`;


export const SET_STUDENTS_FLAG = gql`
mutation setStudentsFlag($studentIds: [ID!]!, $flagType: Int, $removeFlags: Boolean) {
    setStudentsFlag(studentIds: $studentIds, flagType: $flagType, removeFlags: $removeFlags) {
        errors
        students {     
            id
            flagType
        }
    }
}
`;



export const GET_CLASS_ANALYTICS_QUERY = gql`
query studentsClass($id: ID!, $logType: [Int!]!, $startDate: String!, $endDate: String!) {
    studentsClass(id: $id) {
        id
        studentsActivityReport(startDate: $startDate, endDate: $endDate, logType: $logType) {
           id
           averageBooksRead
           averageParentLogs
           averageStudentLogs
           averageTeacherLogs
           details {
                id
                counter
                previousYearCounter
            }
        }
    }
}
`;

export const GET_STUDENT_REPORT = gql`
query student($id: String!, $startDate: String!, $endDate: String!) {
    student(id: $id) {
        id
        name
        detailedStats(startDate: $startDate, endDate: $endDate){
            id
            counters {
                parentLogs
                studentLogs
                teacherLogs
            }
            completedBooksCounter
            ranks {
                id
                assignedAt
                readingBandRank {
                    id
                    name
                    colourCode
                }
            }
            readBooks {
                id
                studentBook {
                    id
                    readDates
                    bookType
                    book {
                        id
                        title
                    }
                }
                groupBook {
                    id
                    readDates
                    book {
                        id
                        title
                    }
                }
            }
        }
    }
}
`;

export const UPDATE_YEAR_GROUP = gql`
mutation updateStudentsYearGroup($studentsClassId: ID!, $data: [StudentYeargroupAttributes!]!) {
    updateStudentsYearGroup(studentsClassId: $studentsClassId, data: $data) {
        errors
        studentsClass {
            id
            yearGroupConfirmed
        }
    }
}
`;

export const SAVE_CLASS_FEED_SETTINGS = gql`
mutation editTeacherActivityDisplay($classFeedActivityFilters: [Int!]!) {
    editTeacherActivityDisplay(classFeedActivityFilters: $classFeedActivityFilters) {
        errors
        teacher {
            id
            settings {
                classFeedActivityFilters
            }
        }
    }
}
`;

export const GET_CLASS_REVIEWS = gql`
query studentsClass($id: ID!, $limit: Int, $offset: Int, $status: Int!) {
    studentsClass(id: $id)  {
        id
        bookReviews(limit: $limit, offset: $offset, status: $status) {
            id
            reviewRate
            reviewText
            date
            status
            book {
                id
                title
                author 
            }
            author {
                id
                name
            }
        }
      
    }
}
`;

export const GET_SINGLE_REVIEW = gql`
query readingBookReview($readingBookReviewId: ID!) {
    readingBookReview(readingBookReviewId: $readingBookReviewId)  {
        id
        reviewRate
        reviewText
        date
        status
        book {
            id
            title
            author 
        }
        author {
            id
            name
        } 
    }
}
`;
export const APPROVE_REVIEW = gql`
mutation approveReadingBookReview($readingBookReviewId: ID!, $approve: Boolean!) {
    approveReadingBookReview(readingBookReviewId: $readingBookReviewId, approve: $approve) {
        errors
        readingBookReview {
            id
            status
        }
    }
}
`;

export const ARCHIVE_REVIEW = gql`
mutation archiveReadingBookReview($readingBookReviewId: ID!, $archive: Boolean!) {
    archiveReadingBookReview(readingBookReviewId: $readingBookReviewId, archive: $archive) {
        errors
        readingBookReview {
            id
            status
        }
    }
}
`;

export const EDIT_REVIEW = gql`
mutation editReadingBookReview($readingBookReviewId: ID!, $text: String!, $rate: Int!) {
    editReadingBookReview(readingBookReviewId: $readingBookReviewId, text: $text, rate: $rate) {
        errors
       readingBookReview {
            id
            reviewText
        }
    }
}
`;
