import React from 'react';
import cx from 'classnames';
import { Button } from '@axeedge/go-teacher-components';
import logo from '../../../../images/logos/boomreader-logo-full.svg'
import goreadQrImg from '../../../../images/go-read-qr-code.svg';
import boomreaderQrImg from '../../../../images/boomreader-qr-code.svg';
import appStoreBadge from '../../../../images/app-store-badge.svg';
import googlePlayBadge from '../../../../images/google-play-badge.svg';

import styles from '../PrintableStudentsList/PrintableStudentsList.module.scss';
import { useTranslation, Trans } from 'react-i18next';

const PrintableParentLetters = ({ school, students }) => {
    const { t } = useTranslation();

    const hostname = window && window.location && window.location.hostname;


    return (
        <div className={styles.studentsPrint}>
            <div className={cx(styles.noPrint, 'hidden-print')}>
                <div className={styles.noPrintContent}>
                    <p className='u-m-base-2'>{t('print_view_to_send_introduction_letters_to_parents')}</p>
                    <Button onClick={() => window.print()}>{t('print')}</Button>
                </div>
            </div>

            {
                students.length > 0 ?
                    students.map(student => {
                        return (
                            <div key={`print-${student.id}`} className={cx(styles.student, styles.letter, styles.printSheet)}>
                                <img className={styles.studentAppLogo} src={logo} alt='' />
                                <h2 className={styles.letterTitle}>{t('dear_parent_carer', { studentName: student.name })}</h2>
                                <p className='u-m-base-4'>{t('welcome_parent_intro_line', { schoolName: school.name })}</p>

                                <h3>1. {t('download_the_app')}</h3>
                                <div className={styles.letterUrl}>
                                    <div className='u-m-top-2 u-m-base-2'>
                                        <div className={styles.letterAppLinks}>
                                            <a href="https://apps.apple.com/us/app/goapps-parents/id1552398474?itsct=apps_box&amp;itscg=30200" target='_blank' rel="noopener noreferrer" >
                                                <img src={appStoreBadge} alt='Download on the App Store' />
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=app.goapps.parents&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' rel="noopener noreferrer" >
                                                <img src={googlePlayBadge} alt='Get it on Google Play' />
                                            </a>
                                        </div>
                                        <Trans i18nKey="search_go_apps_parents">
                                            Search <b>BoomReader Parents</b> or go to:
                                        </Trans>
                                        <h3 className='u-m-top-2'>boomreader.co.uk/parents</h3>
                                    </div>
                                    <img className={styles.letterUrlQrImg} src={boomreaderQrImg} alt='' /> :
                                    
                                </div>


                                <h3 className='u-m-top-4'>2. {t('connect_to_student', { studentName: student.name })}</h3>
                                <p>{t('use_details_below_to_connect_to_your_child')}</p>
                                <p>{t('if_you_have_other_chilren_add_by_tap')}</p>
                                <p className={styles.letterCode}>{t('school_code')}: {school.schoolCode}</p>
                                <p className={styles.letterCode}>{t('pin_code')}: {student.loginCode}</p>


                                <h3 className='u-m-top-4'>3. {t('done')}!</h3>
                                <p>{t('set_reading_schedule', {studentName: student.firstName})}</p>
                                <h3 className='u-m-top-4'>{t('thank_you_for_using_go_read')}</h3>
                            </div>
                        )
                    })
                    : <p className='u-m-top-3 u-text-center'>{t('no_pupils_in_this_class')}</p>
            }
        </div>
    )
}

export default PrintableParentLetters;
