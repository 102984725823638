import React, { useState, useEffect} from 'react';
import NoteItem from './NoteItem';
import { useLazyQuery } from '@apollo/react-hooks';
import SideModal from '../../../../components/SideModal';
import EditNote from './EditNote';
import AddNote from './AddNote';
import { Button, Loader } from '@axeedge/go-teacher-components';
import { AlertCircle, PlusCircle } from 'react-feather';
import { GET_STUDENT_NOTES } from '../../services/graphql';

import styles from './Notes.module.scss';
import cx from 'classnames';

const Notes = ({ student }) => {

    const [editNote, setEditNote] = useState(null);
    const [addNote, setAddNote] = useState(false);

    const [notes, setNotes] = useState([]);
    const [notesCount, setNotesCount] = useState(0);
    const [offset, setOffset] = useState(0);

    const [getInitialNotes, { loading: loadingInitial, }] = useLazyQuery(GET_STUDENT_NOTES, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data && data.student && data.student.studentNotes) {
                setNotes(data.student.studentNotes.items);
                setNotesCount(data.student.studentNotes.total);
            }
        }
    });

    const [getMoreNotes, { loading: loadingMore }] = useLazyQuery(GET_STUDENT_NOTES, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data && data.student && data.student.studentNotes) {
                setNotes(notes.concat(data.student.studentNotes.items));
                setOffset(offset + 10);
            }
        }
    });


    useEffect(() => {
        getInitialNotes({
            variables: {
                id: student.id,
                offset: offset
            }
        })
    }, []);

    const loadMoreNotes = () => {
        getMoreNotes({
            variables: {
                id: student.id,
                offset: offset + 10
            }
        })
    }

    if (loadingInitial) {
        return <Loader />
    }


    return (
        <>
            <div className={cx(styles.notes, 'card card-mobile')}>
                <div className="u-display-flex u-m-base-3 u-justify-end">
                    <p className='u-text-muted-2 small u-display-flex u-align-center u-m-right-2'><AlertCircle className='u-m-right-1' />Notes are only seen by school staff and not parents or children</p>
                    <Button className='u-display-flex u-align-center' onClick={() => setAddNote(true)}><PlusCircle className='u-m-right-1' /> New note</Button>
                </div>
                {notes.length > 0 ? (
                    <>
                        {
                            notes.map(note => (
                                <NoteItem
                                    key={`note-${note.id}`}
                                    setEditNote={setEditNote}
                                    note={note}
                                />
                            ))
                        }
                        {notesCount > notes.length && (
                            <Button disabled={loadingMore} outline onClick={() => loadMoreNotes()}>Load more</Button>
                        )}
                    </>
                )
                    : <p>No notes</p>}
            </div>

            {
                editNote && (
                    <SideModal closeModal={() => setEditNote(null)}>
                        <EditNote notesCount={notesCount} setNotesCount={setNotesCount} notes={notes} setNotes={setNotes} close={() => setEditNote(null)} student={student} note={editNote} />
                    </SideModal>
                )
            }

            {
                addNote && (
                    <SideModal closeModal={() => setAddNote(null)}>
                        <AddNote notes={notes} student={student} close={() => setAddNote(null)} />
                    </SideModal>
                )
            }
        </>
    )

}

export default Notes;