import React from 'react'
import styles from './parentDataContent.module.scss'
import { useQuery } from '@apollo/react-hooks'
import {GET_YEAR_PARENTS_STATS} from "../services/graphql";
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import { XCircle } from 'react-feather';
const ViewStudents = ({yearGroup, setViewYear}) => {
    const { data, error, loading } = useQuery(GET_YEAR_PARENTS_STATS, {variables: {
        yearGroup
    }});
    if (loading) {
        return (<p>Loading...</p>)
    }
    if (data) {
        return (
            <div className="u-m-top-2">
                <div className={styles.studentsHeader}>
                    <p>{YEAR_GROUPS.find(yg => yg.val === yearGroup).name} students <span className="u-bold">without</span> associated parent accounts.</p>
                    <button className={styles.closeStudents} onClick={() => setViewYear(null)}><XCircle/></button>
                </div>
                <ul className="u-m-top-4">
                    <li className={styles.studentsHeaderItem}>Student Name<span>UPN Number</span></li>
                    {data.currentTeacher.school.studentsWithNoParentsAssociated.map(s => {
                        return(
                            <li key={s.id} className={styles.studentItem}><span>{s.firstName} {s.lastName}</span>{s.upn}</li>
                        )
                    })}
                </ul>
            </div>
        )
    }
    return null
}

export default ViewStudents