import React from 'react';
import { useQuery} from '@apollo/react-hooks';

import { BOOK_TITLE_SEARCH_QUERY } from '../services/graphql';
import TitleSearchResults from './titleSearchResults';
import { useTranslation } from 'react-i18next';

const TitleSearchQueries = ({code, studentId, close, publisher, reset, studentIds, classInfo, setFilter, bookToExistingGroup, groupBookId = null}) => {

    const { t } = useTranslation();
    const { data, error, loading } = useQuery(BOOK_TITLE_SEARCH_QUERY, {
        variables: {
            title: code,
            publisherId: publisher && publisher.id,
            searchApi: publisher ? false : true,
        },
        fetchPolicy: 'network-only',
    });


    if (error) {
    return <p>{error && error.message && error.message}</p>
    }

    if (loading) {
        return <p className="altDesc">{t('loading')}</p>
    }

   if (data) {
        return <TitleSearchResults multipleBooks={data} code={code} studentId={studentId} reset={reset} close={close} publisher={publisher} studentIds={studentIds} classInfo={classInfo} setFilter={setFilter} bookToExistingGroup={bookToExistingGroup} groupBookId={groupBookId} />
    }
    return null
}

export default TitleSearchQueries;