import React, { useState } from "react";
import { Button, Modal } from "@axeedge/go-teacher-components";
import { useEffectOnce } from "react-use";
import styles from '../SchoolData.module.scss';
import { ArrowLeft, ArrowRight, X } from "react-feather";
import CompareByDemographyContent from "./CompareByDemographyContent";
import { useLazyQuery } from "@apollo/react-hooks";
import { COMPARE_DEMOGRAPHIC_SETS } from "../../../services/graphql";

const CompareByDemography = ({ studentsClass, yearGroup, school, demoCriteria, schoolCode, close }) => {

    const [showModal, setShowModal] = useState(false);

    const [selectedCritSets, setSelectedCritSets] = useState([]);
    const [filters, setFilters] = useState(null);
    const [reportData, setReportData] = useState(null);

    const initFilters = () => {
        const f = [];
        demoCriteria.forEach(el => {
            f.push({
                category: el.name,
                values: []
            })
        })
        const filtersObjects = f.reduce((acc, curr) => {
            acc[curr.category] = { values: curr.values };
            return acc;
        }, {});
        setFilters(filtersObjects);
    }

    useEffectOnce(() => {
        initFilters();
    });

    const titleCase = str => str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()).split('_').join(' ');

    const onChangeFilter = (name, category) => {
        const updatedValues = filters[category]?.values.includes(name) ? filters[category]?.values.filter(v => v !== name) : [...filters[category]?.values, name]
        setFilters((prevFilters) => ({
            ...prevFilters,
            [category]: {
                ...prevFilters[category],
                values: updatedValues,
            },
        }));
    }

    const getCritDetailName = (text, categName) => {
        let name = text;
        if (text === 'true') {
            name = titleCase(categName);
        }
        if (text === 'false') {
            name = 'None'
        }
        if (text === '') {
            name = 'Not specified'
        }
        return name;
    }

    const filterEmptyValues = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key].values.length > 0) {
                //if (obj[key].values.some(value => value !== "")) {

                acc[key] = obj[key];
            }
            return acc;
        }, {});
    };

    const handleAdd = () => {
        const filtersWithData = filterEmptyValues(filters);
        setSelectedCritSets(prevSelectedCrit => {
            return [...prevSelectedCrit, filtersWithData]
        });

        setShowModal(false);
        initFilters();
    }

    const handleRemoveSet = (indexToRemove) => {
        setSelectedCritSets(prevSelectedCrit => prevSelectedCrit.filter((_, index) => index !== indexToRemove));
    }

    const getFiltersForSubmit = data => {
        return data.map(item => {
            const filters = Object.keys(item).map(key => ({
                demographicsCategory: key,
                values: item[key].values
            }));
            return { filters };
        });
    }

    const [compareDemographicSets, { loading }] = useLazyQuery(COMPARE_DEMOGRAPHIC_SETS, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setReportData(data.schoolByCode.monthlyReadingDataByDemographics)
        }
    })

    const onApplyFilterSets = () => {
        if (studentsClass) {
            compareDemographicSets({
                variables: {
                    allSchoolStudents: false,
                    studentsClassId: studentsClass.id,
                    filterSets: getFiltersForSubmit(selectedCritSets),
                    schoolCode: schoolCode
                }
            });
        } else if (yearGroup) {
            compareDemographicSets({
                variables: {
                    allSchoolStudents: false,
                    yearGroupId: yearGroup.id,
                    filterSets: getFiltersForSubmit(selectedCritSets),
                    schoolCode: schoolCode
                }
            });
        } else if (school) {
            compareDemographicSets({
                variables: {
                    allSchoolStudents: true,
                    filterSets: getFiltersForSubmit(selectedCritSets),
                    schoolCode: schoolCode
                }
            });
        }
    }

    return (
        <>
            <h4 className="u-m-base-2 u-display-flex u-align-center">
                <button onClick={close} className="btn-reset u-display-flex u-align-center u-m-right-1"><ArrowLeft /></button>
                {studentsClass ? studentsClass.name : yearGroup ? yearGroup.name : school && 'School'} demographics
            </h4>
            {reportData ? <CompareByDemographyContent reportData={reportData} /> :
                <>

                    <p className="u-m-base-2">Select the demographic criteria you want to compare:</p>
                    {selectedCritSets.length < 3 &&
                        <Button outline className='u-display-flex' onClick={() => setShowModal(true)}>{selectedCritSets.length > 0 ? 'Add another set' : 'Select criteria'} <ArrowRight size={18} className="u-m-left-2" /></Button>
                    }
                    {selectedCritSets.length > 0 &&
                        <>
                            <hr className="separator" />
                            <p className="bold u-m-base-2 u-m-top-2">Comparison sets:</p>
                            <ul className="u-m-base-2">
                                {selectedCritSets.map((setObject, i) => (
                                    <li className={styles.demoSets} key={`set-${i}`}>
                                        <div>
                                            {Object.keys(setObject).map(key => (
                                                <span className='u-m-right-1' key={key}>
                                                    <span>{titleCase(key)}</span>: <strong>{setObject[key].values.map(v => v || 'Not specified').join(', ')}</strong>
                                                </span>
                                            ))}
                                        </div>
                                        <span className="u-m-left-auto"><button onClick={() => handleRemoveSet(i)} className="btn-reset"><X /></button></span>
                                    </li>
                                ))}
                            </ul>
                            <Button disabled={loading} onClick={() => onApplyFilterSets()}>{loading ? 'Loading...' : 'View'}</Button>
                        </>
                    }
                </>
            }
            {showModal &&
                <Modal closeModal={() => setShowModal(false)}>
                    <h1>Demographics criteria</h1>
                    {demoCriteria.sort((a, b) => a.name.localeCompare(b.name)).map(crit => (
                        <div key={crit.id}>
                            <p className='lead u-bold u-m-base-2'>{titleCase(crit.name)}</p>
                            {
                                crit.details.map(critDetail => (
                                    <div className='u-m-base-1' key={`${critDetail.id}`}>
                                        <input checked={filters[crit.name]?.values.includes(critDetail.name)} value={critDetail.name} onChange={(e) => onChangeFilter(e.target.value, crit.name)} id={`${critDetail.id}-${critDetail.name}`} type='checkbox' className='checkBoxRegular' />
                                        <label htmlFor={`${critDetail.id}-${critDetail.name}`}> {getCritDetailName(critDetail.name, crit.name)}</label>
                                    </div>
                                ))
                            }
                            <hr className='separator u-m-top-2 u-m-base-2' />
                        </div>
                    ))}
                    <Button type='button' onClick={handleAdd}>Add</Button>
                </Modal>
            }

        </>
    )
}

export default CompareByDemography;