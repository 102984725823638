import React from 'react';

const ChartYLines = ({ height, roundedMax, chartWidth }) => {

    //const bands = roundedMax > 9 ? 10 : roundedMax;
    const bands = 10;
    const bandSpacing = (chartWidth - 1) / bands;
    const numberIntervals = roundedMax / bands;

    return (
        <>
            {Array.from({ length: bands + 1 }, (_, i) => {
                const xPos = bandSpacing * i;
                return (
                    <g key={`band-${i}`}>
                        <line stroke='#f2f2f2' strokeWidth="1" x1={xPos} x2={xPos} y1="0" y2={height}></line>
                        <text textAnchor={i === 0 ? 'start' : i === bands ? 'end' : 'middle'} fontSize={10} y={height + 20} x={xPos}>
                            {numberIntervals * i}
                        </text>
                    </g>
                );
            })}
        </>
    )
}

export default ChartYLines;