import { gql } from 'apollo-boost';

export const GET_STUDENT_QUERY = gql`
query student($id: String!) {
    student(id: $id) {
        id
        name
        firstName
        loginCode
        avatarJson
        flagType
        updatedAt
        boomerAvatarUrl
        totalEarnedGems
        availableGems
        stats {
            id
            parentLogs
            studentLogs
            teacherLogs
            lastParentLog
            lastStudentLog
            lastTeacherLog
            currentYearParentLogsCounter
            currentYearStudentLogsCounter
            currentYearTeacherLogsCounter
            currentWeekBooksCounter
            currentYearBooksCounter
            totalBooksCounter
        }
        readingBooks {
            id
            status
            bookType
            book {
                id
                title
            }
        }
        classes {
            id
            name
            teachers {
                id
                displayName
            }
        }
        currentReadingRank {
            id
            readingBandRank {
                id
                name
                colourCode
            }
        }
        studentReadingBandRanks {
            id
            assignedAt
            readingBandRank {
                id
                assignedAt
                name
                colourCode
            }
        }
        school {
            id
            schoolCode
            name
            readingBands {
                id
            }
        }
        problemWordsCounter
        mostRecentProblemWords {
            id
            word
            timestamp
        }
        yearGroup
        parentsCounter
        studentSurveys {
            id
            status
            survey {
                id
                title
            }
        }
    }
}
`;

export const GET_STUDENT_BOOKS = gql`
query student($id: String!, $bookType: Int, $status: [Int!], $title: String, $page: Int) {
    student(id: $id) {
        id
        readingBooksByQuery(bookType: $bookType, status: $status, title: $title, page: $page) {
            total
            items {
                id
                book {
                    id
                    coverUrl
                    title
                    author
                    publisher {
                        id
                        name
                    }
                    isbn
                    isbn13
                    reviews {
                        id
                        author {
                            id
                        }
                        reviewText
                        reviewRate
                        date
                    }
                }
                status
                completedAt
                createdAt
                bookType
                hideComments
                isReread
                mostRecentLog {
                    id
                    readingDate
                    pageEnd
                    pageStart
                }
            }
        }
        groupReadingBooksByQuery(status: $status, title: $title, page: $page) {
            total
            items {
                id
                group {
                    id
                    name
                }
                book {
                    id
                    coverUrl
                    title
                    author
                    publisher {
                        id
                        name
                    }
                    isbn
                    isbn13
                    reviews {
                        id
                        author {
                            id
                        }
                        reviewText
                        reviewRate
                        date
                    }
                }
                status
                students {
                    id
                    firstName
                    lastName
                }
                completedAt
                createdAt
                hideComments
                mostRecentLog {
                    id
                    readingDate
                    pageEnd
                    pageStart
                }
            }
        }
    }
}`

export const GET_STUDENT_LOGS = gql`
query student($id: String!, $startDate: String!, $duration: Int!, $activityType: [Int!]) {
    student(id: $id) {
        id
        activities(startDate: $startDate, duration: $duration, activityType: $activityType) {
            items {
                id
                activityDate
                activityType
                author
                bookName
                bookType
                fromPage
                rankName
                reviewRate
                reviewText
                rankColourCode
                isBookCompletion
                logComment
                studentReadingBookLogId
                readingBookReviewId
                groupReadingBookLogId
                studentNoteId
                viewedBy {
                    id
                    name
                }
                likedBy {
                    id
                    name
                }
                student {
                    id
                    name
                }
                toPage
            }
        }
    }
}`

export const GET_STUDENT_PROBLEM_WORDS = gql`
query student($id: String!, $showAll: Boolean) {
    student(id: $id) {
        mostRecentProblemWords(showAll: $showAll) {
            id
            word
            timestamp
            yearGroup
            status
        }
        completedProblemWords {
            id
            word
            completedAt
            timestamp
            yearGroup
            status
        }
    }
}
`;


export const MARK_WORDS_AS_COMPLETED = gql`
mutation markProblemWordsAsCompleted($words: [ID!]!) {
    markProblemWordsAsCompleted(words: $words) {
        errors
        completedWords {
            id
            word
            yearGroup
            completedAt
            status
            timestamp
        }
    }
}
`;

export const GET_SINGLE_BOOK = gql`
query studentBook($id: String!) {
    studentBook(id: $id) {
        id
        logs {
            id
            date
            isCompletion
            readingDate
            entry
            author {
                id
                name
                role
                displayName
            }
            problemWords {
                id
                word
            }
            pageStart
            pageEnd
            views {
                id
                author {
                    id
                    displayName
                }
            }
            likes {
                id
                author {
                    id
                    displayName
                }
            }
        }
    }
}
`;

export const GET_GROUP_BOOK = gql`
query groupBook($id: String!) {
    groupBook(id: $id) {
        id
        logs {
            id
            date
            isCompletion
            readingDate
            entry
            studentIds
            author {
                id
                name
                role
                displayName
            }
            problemWords {
                id
                word
            }
             views {
                id
                author {
                    id
                    displayName
                }
            }
            likes {
                id
                author {
                    id
                    displayName
                }
            }
            pageStart
            pageEnd
        }
    }
}
`;

export const REGENERATE_LOGIN_CODE = gql`
mutation regenerateStudentLoginCode($studentId: ID!) {
    regenerateStudentLoginCode(studentId: $studentId) {
        student {
            id
            name
            loginCode
        }
        errors
    }
}
`;

export const ADD_STUDENT_NOTE = gql`
mutation addStudentNote($studentId: ID!, $title: String, $body: String!) {
    addStudentNote(studentId: $studentId, title: $title, body: $body) {
        note {
            id
            body
            title
            createdAt
            updatedAt
            creator {
                id
                displayName
            }
            updater {
                id
                displayName
            }
            student {
                id
                name
            }
        }
        errors
    }
}
`;

export const UPDATE_STUDENT_NOTE = gql`
mutation updateStudentNote($studentId: ID!, $studentNoteId: ID!, $title: String, $body: String!) {
    updateStudentNote(studentId: $studentId, studentNoteId: $studentNoteId, title: $title, body: $body) {
        note {
            id
            body
            title
            createdAt
            updatedAt
            creator {
                id
                displayName
            }
            updater {
                id
                displayName
            }
        }
        errors
    }
}
`;

export const DELETE_STUDENT_NOTE = gql`
mutation deleteStudentNote($studentNoteId: ID!) {
    deleteStudentNote(studentNoteId: $studentNoteId) {
        deleted
        errors
    }
}
`;

export const GET_STUDENT_NOTES = gql`
query student($id: String!, $offset: Int!) {
    student(id: $id) {
        id
        studentNotes(offset: $offset) {
            total
            items {
                id
                body
                title
                createdAt
                updatedAt
                creator {
                    id
                    displayName
                }
                updater {
                    id
                    displayName
                }
            }
        }
    }
}
`;


export const EDIT_LOG = gql`
mutation editReadingLog($studentReadingBookLogId: ID!, $entry: String, $problemWords: [String!]) {
    editReadingBookLog(studentReadingBookLogId: $studentReadingBookLogId, entry: $entry, problemWords: $problemWords) {
        studentReadingBookLog {
            id
            date
            isCompletion
            readingDate
            entry
            book {
                id
                bookType
                book {
                    id
                    title
                }
            }
            author {
                id
                name
                role
                displayName
            }
            problemWords {
                id
                word
            }
            pageStart
            pageEnd
        }
        errors
    }
}
`;


export const DELETE_LOG = gql`
mutation deleteReadingBookLog($studentReadingBookLogId: ID!, $studentId: ID!) {
    deleteReadingBookLog(studentReadingBookLogId: $studentReadingBookLogId, studentId: $studentId) {
        studentReadingBook {
            id
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            status
            completedAt
            createdAt
            bookType
            hideComments
            isReread
            mostRecentLog {
                id
                readingDate
                pageEnd
                pageStart
            }
        }
        deleted
        errors
    }
}
`;


export const MARK_LOG_AS_LIKED = gql`
mutation markLogAsLiked($studentReadingBookLogId: ID, $groupReadingBookLogId: ID, $studentId: ID ) {
    markLogAsLiked(studentReadingBookLogId: $studentReadingBookLogId, groupReadingBookLogId: $groupReadingBookLogId, studentId: $studentId) {
        errors
        logLiked
    }
}
`;



export const MARK_LOG_AS_SEEN = gql`
mutation markLogAsViewed($studentReadingBookLogId: ID, $groupReadingBookLogId: ID, $studentId: ID) {
    markLogAsViewed(studentReadingBookLogId: $studentReadingBookLogId, groupReadingBookLogId: $groupReadingBookLogId, studentId: $studentId) {
        errors
        logViewed
    }
}
`;

export const MARK_REVIEW_AS_LIKED = gql`
mutation markBookReviewAsLiked($readingBookReviewId: ID!) {
    markBookReviewAsLiked(readingBookReviewId: $readingBookReviewId) {
        errors
        reviewLiked
    }
}
`;

export const MARK_REVIEW_AS_SEEN = gql`
mutation markBookReviewAsViewed($readingBookReviewId: ID!) {
    markBookReviewAsViewed(readingBookReviewId: $readingBookReviewId) {
        errors
        reviewViewed
    }
}
`;

export const GET_BOOK_REVIEW = gql`
query studentBook($id: String!) {
    studentBook(id: $id) {
        id 
        book {
            id
            reviews {
                id
                author {
                    id
                }
                reviewRate
                reviewText
                views {
                    id
                    author {
                        id
                        displayName
                    }
                }
                likes {
                    id
                    author {
                        id
                        displayName
                    }
                }
            }
        }
    }
}
`;
