import React, { useEffect, useState } from 'react';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import styles from '../Student.module.scss';

const CompletedProblemWords = ({ words }) => {

    const [sortOption, setSortOption] = useState('az');
    const [sortedCompletedWords, setSortedCompletedWords] = useState(words);

    useEffect(() => {
        if (sortOption === 'az') {
            setSortedCompletedWords([...words].sort((a, b) => a.word.localeCompare(b.word)));
        } else if (sortOption === 'year') {
            setSortedCompletedWords([...words].sort((a, b) => b.yearGroup - a.yearGroup));
        } else if (sortOption === 'completed') {
            setSortedCompletedWords([...words].sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()));
        } else {
            setSortedCompletedWords([...words].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
        }
    }, [sortOption, words, setSortedCompletedWords]);

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }

    return (
        <>
            {sortedCompletedWords.length > 0 ?
                <table className={styles.wordsCompleted}>
                    <thead>
                        <tr>
                            <th><button onClick={() => setSortOption('az')} className='btn-reset u-text-primary'>Word</button></th>
                            <th><button onClick={() => setSortOption('year')} className='btn-reset u-text-primary'>Year Group</button></th>
                            <th><button onClick={() => setSortOption('added')} className='btn-reset u-text-primary'>Date added</button></th>
                            <th><button onClick={() => setSortOption('completed')} className='btn-reset u-text-primary'>Date Completed</button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedCompletedWords.map(pword => (
                            <tr key={pword.id}>
                                <td>{pword.word}</td>
                                <td>{getYearGroupName(pword.yearGroup)}</td>
                                <td>{pword.timestamp}</td>
                                <td>{pword.completedAt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> : <p>No completed problem words</p>
            }
        </>
    )
}

export default CompletedProblemWords;