import { gql } from 'apollo-boost';

export const GET_STUDENT_QUERY = gql`
query student($id: String!) {
    student(id: $id) {
        id
        name
        readingBooks {
            id
            status
            bookType
            book {
                id
                title
            }
            mostRecentLog {
                id
                pageEnd
            }
        }
    }
}
`;

export const ADD_READING_LOG = gql`
mutation addReadingBookLog(
        $studentReadingBookId: ID!
        $pageStart: Int!
        $pageEnd: Int!
        $entry: String!
        $problemWords: [String!]
        $finished: Boolean!,
        $readingDate: String!,
    ) {
        addReadingBookLog(studentReadingBookId: $studentReadingBookId, pageStart: $pageStart, pageEnd: $pageEnd, entry: $entry, problemWords: $problemWords, finished: $finished, readingDate: $readingDate) {
        studentReadingBook {
            id
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            mostRecentLog {
                id
                date
                isCompletion
                readingDate
                entry
                book {
                    id
                    bookType
                    book {
                        id
                        title
                    }
                }
                author {
                    id
                    name
                    firstName
                    lastName
                    role
                    displayName
                }
                problemWords {
                    id
                    word
                }
                pageStart
                pageEnd
            }
            status
            completedAt
            createdAt
            hideComments
            isReread
            bookType
        }
        errors
    }
}
`;

export const ADD_GROUP_LOG = gql`
mutation addGroupReadingBookLog(
    $groupReadingBookId: ID!,
    $pageStart: Int!,
    $pageEnd: Int!,
    $entry: String,
    $finished: Boolean!,
    $readingDate: String!,
    $studentIds: [ID!],
    $problemWords: [String!]
) {
    addGroupReadingBookLog(
        groupReadingBookId: $groupReadingBookId,
        pageStart: $pageStart,
        pageEnd: $pageEnd,
        entry: $entry,
        finished: $finished,
        readingDate: $readingDate,
        studentIds: $studentIds,
        problemWords: $problemWords
    ) {
        groupReadingBook{
            id
            students {
                id
                firstName
                lastName
            }
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            mostRecentLog {
                id
                date
                isCompletion
                readingDate
                entry
                book {
                    id
                    book {
                        id
                        title
                    }
                }
                author {
                    id
                    name
                    role
                    displayName
                    firstName
                    lastName
                }
                problemWords {
                    id
                    word
                }
                pageStart
                pageEnd
            }
            status
            completedAt
            createdAt
            hideComments
        }
        errors
    }
}
`

export const MARK_AS_COMPLETE = gql`
mutation markReadingBookAsCompleted(
    $studentReadingBookId: ID!
) {
    markReadingBookAsCompleted(studentReadingBookId: $studentReadingBookId) {
        studentReadingBook {
            id
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            mostRecentLog {
                id
                date
                isCompletion
                readingDate
                entry
                author {
                    id
                    name
                    role
                    displayName
                }
                book {
                    id
                    bookType
                    book {
                        id
                        title
                    }
                }
                problemWords {
                    id
                    word
                }
                pageStart
                pageEnd
            }
            status
            completedAt
            createdAt
            isReread
            hideComments
            bookType
        }
        errors 
    }
}
` 

export const MARK_GB_AS_COMPLETE = gql`
mutation markGroupReadingBookAsCompleted(
    $groupReadingBookId: ID!
) {
    markGroupReadingBookAsCompleted(groupReadingBookId: $groupReadingBookId) {
        groupReadingBook {
            id
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            mostRecentLog {
                id
                date
                isCompletion
                readingDate
                entry
                author {
                    id
                    name
                    role
                    displayName
                }
                book {
                    id
                    book {
                        id
                        title
                    }
                }
                problemWords {
                    id
                    word
                }
                pageStart
                pageEnd
            }
            status
            completedAt
            createdAt
            hideComments
        }
        errors 
    }
}
` 