import React from 'react';
import { useTranslation } from 'react-i18next';

const ChartMedian = ({ data, height, roundedMax, chartWidth }) => {
    const { t } = useTranslation();


    const currentLogs = data.filter(el => el.counter !== null).map(el => el.counter);
    const sorted = currentLogs.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    let median;
    if (sorted.length % 2 === 0) {
        median = (sorted[middle - 1] + sorted[middle]) / 2;
    } else {
        median = sorted[middle];
    }
    const xPos = (chartWidth * median) / roundedMax;

    if (median === null || isNaN(median)) return null;

    return (
        <g>
            <line x1={xPos} x2={xPos} y1="0" y2={height + 15} stroke='#696868' strokeDasharray="3, 3" strokeWidth="1"></line>
            <text textAnchor={median < 3 ? 'start' : 'middle'} fontSize={10} y={height + 35} x={xPos}> {t('median')} {median}</text>
        </g>
    )
}

export default ChartMedian;