import React, { useState, useEffect, useContext } from 'react';
import * as Icon from 'react-feather'
import moment from 'moment';
import RangeCalendar from '../../../../components/RangeCalendar';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from '@axeedge/go-teacher-components';
import { GET_CLASS_ANALYTICS_QUERY } from '../../services/graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import { LOG_BY_TEACHER, LOG_BY_STUDENT, LOG_BY_PARENT, GROUP_BOOK_LOG } from '../../../../utils/constants';
import { ClassFilterContext } from '../../../../services/class/ClassFilterProvider';

import AnalyticsData from './AnalyticsData';
import styles from './ClassStats.module.scss';
import cx from 'classnames';


const ClassStats = ({ classInfo, students }) => {

    const { t } = useTranslation();
    const classFilterContext = useContext(ClassFilterContext);

    const [analyticsData, setAnalyticsData] = useState(null);
    const { classAnalyticsFilters, setClassAnalyticsFilters } = classFilterContext;
   
    const classPref = classAnalyticsFilters.find(el => el.classId === classInfo.id)

    const [logFilters, setLogFilters] = useState(classPref ? classPref.logFilters : [LOG_BY_STUDENT, LOG_BY_PARENT]);
    const [sortType, setSortType] = useState('highest');

    const [dateRange, setDateRange] = useState({
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: moment().toDate()
    });

    const acadYear = moment().toDate().getMonth() < 8 ? moment().year() - 1 : moment().year();
    //go back to 1st of july to cover summer activies (instead of sept 1)
    const minDate = moment('01-07-' + acadYear, 'DD-MM-YYYY').toDate();
    //const minDate = moment('01-07-2022', 'DD-MM-YYYY').toDate(); //LP: temp change for a school (sept 2023)

    //set a max date to allow past year counters for next months:
    const maxDate = moment('01-07-' + acadYear, 'DD-MM-YYYY').add(1, 'years').toDate();
    const onSelectLogType = el => {
        if (logFilters.indexOf(el) !== -1) {
            setLogFilters(logFilters.filter(s => s !== el));
        } else {
            setLogFilters([...logFilters, el]);
        }
    }

    const [getClassAnalytics, { loading }] = useLazyQuery(GET_CLASS_ANALYTICS_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            const stData = [];
            data.studentsClass.studentsActivityReport.details.forEach(report => {
                const matchStudent = students.find(s => s.id === report.id);
                stData.push({
                    ...matchStudent,
                    counter: +report.counter,
                    previousYearCounter: +report.previousYearCounter,
                })
            });
            const { details, ...averages } = data.studentsClass.studentsActivityReport;
            setAnalyticsData({
                ...averages,
                students: [...stData]
            });
        }
    });

    useEffect(() => {
        if (!analyticsData) {
            getClassAnalytics({
                variables: {
                    id: classInfo.id,
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate,
                    logType: [...logFilters],
                }
            });
        }
    }, [analyticsData]);

    const applyChartFilters = () => {
        getClassAnalytics({
            variables: {
                id: classInfo.id,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                logType: [...logFilters],
            }
        });
        let newPref = [];
        if (!classAnalyticsFilters.find(el => el.classId === classInfo.id)) {
            newPref = [...classAnalyticsFilters, { classId: classInfo.id, logFilters }];
        } else {
            newPref = classAnalyticsFilters.map(el => (el.classId === classInfo.id ? { ...el, logFilters } : el));
        }
        setClassAnalyticsFilters(newPref);
    }

    return (
        <>

            <div className={styles.filters}>
                <ul className={cx(styles.filtersList, 'u-align-center')}>
                    <li><p onClick={() => onSelectLogType(LOG_BY_STUDENT)}>{logFilters.indexOf(LOG_BY_STUDENT) !== -1 ? <Icon.CheckSquare /> : <Icon.Square />} {t('pupil')}</p></li>
                    <li><p onClick={() => onSelectLogType(LOG_BY_PARENT)}>{logFilters.indexOf(LOG_BY_PARENT) !== -1 ? <Icon.CheckSquare /> : <Icon.Square />} {t('parent')}</p></li>
                    <li><p onClick={() => onSelectLogType(LOG_BY_TEACHER)}>{logFilters.indexOf(LOG_BY_TEACHER) !== -1 ? <Icon.CheckSquare /> : <Icon.Square />} {t('teacher')}</p></li>
                    <li><p onClick={() => onSelectLogType(GROUP_BOOK_LOG)}>{logFilters.indexOf(GROUP_BOOK_LOG) !== -1 ? <Icon.CheckSquare /> : <Icon.Square />} {t('group')}</p></li>
                    <li>
                        <RangeCalendar maxDate={maxDate} onSetRanges={setDateRange} minDate={minDate} dateRange={dateRange} />
                    </li>
                </ul>
                <Button className='hidden-print' disabled={logFilters.length === 0} onClick={() => applyChartFilters()}>Apply</Button>
            </div>
            <div className='card card-mobile'>

                {
                    loading ? <Loader /> :
                        analyticsData && analyticsData.students && analyticsData.students.length > 0 && (
                            <AnalyticsData
                                sortType={sortType}
                                setSortType={setSortType}
                                classInfo={classInfo}
                                acadYear={acadYear}
                                analyticsData={analyticsData}
                                dateRange={dateRange}
                            />
                        )
                }
            </div>
        </>
    )
}

export default ClassStats;