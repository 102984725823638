import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import iconTeacher from '../../images/icon_teacher.png';
import iconParent from '../../images/icon_home.png';
import iconBook from '../../images/icon_book.svg';
import boomer from '../../../../images/loader-boomer.png'

import { AlertCircle } from 'react-feather';
import styles from './StudentRow.module.scss';

const ReadingLogDatesRow = ({ student, viewType, intervalStats }) => {
    const { t } = useTranslation();

    const roleTypes = ['Parent', 'Teacher', 'Student', 'Book'];

    const getFormattedDateString = dateString => {
        if (!dateString) {
            return '--'
        }
        const local = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').local();
        return moment(local, "YYYY-MM-DD HH:mm:ss").fromNow();
    }

    const getRecentLogDate = type => {
        switch (type) {
            case 'Teacher':
                return getFormattedDateString(student.stats.lastTeacherLog);
            case 'Parent':
                return student.parentsCounter === 0 ? <AlertCircle size={20} className='u-text-accent-2' /> : getFormattedDateString(student.stats.lastParentLog);
            case 'Student':
                return getFormattedDateString(student.stats.lastStudentLog);
            case 'Book':
                return student.stats.currentYearBooksCounter || 0;
            default:
                return '-'
        }
    }

    const getWeeklyReads = type => {
        switch (type) {
            case 'Teacher':
                return student.stats.teacherLogs || 0;
            case 'Parent':
                return student.parentsCounter === 0 ? <AlertCircle size={20} className='u-text-accent-2' /> : student.stats.parentLogs || 0;
            case 'Student':
                return student.stats.studentLogs || 0;
            case 'Book':
                return student.stats.currentWeekBooksCounter || 0;
            default:
                return '-'
        }
    }

    const getYearlyReads = type => {
        switch (type) {
            case 'Teacher':
                return student.stats.currentYearTeacherLogsCounter || 0;
            case 'Parent':
                return student.parentsCounter === 0 ? <AlertCircle size={20} className='u-text-accent-2' /> : student.stats.currentYearParentLogsCounter || 0;
            case 'Student':
                return student.stats.currentYearStudentLogsCounter || 0;
            case 'Book':
                return student.stats.currentYearBooksCounter || 0;
            default:
                return '-'
        }
    }

    const [stStats, setStudentStats] = useState(null);

    useEffect(() => {
        if (intervalStats && intervalStats.length > 0) {
            setStudentStats(intervalStats.find(s => s.id === student.id));
        }
    }, [intervalStats, student.id]);

    const getCustomReads = type => {
        switch (type) {
            case 'Teacher':
                return stStats ? stStats.interval_teacher_logs : ' ...';
            case 'Parent':
                return student.parentsCounter === 0 ? 'No parent' : stStats ? stStats.interval_parent_logs : ' ...';
            case 'Student':
                return stStats ? stStats.interval_student_logs : ' ...';
            case 'Book':
                return stStats ? stStats.interval_books_counter : ' ...';
            default:
                return '-'
        }
    }

    return (
        <>
            {
                roleTypes.map((type, i) => (
                    <div key={`st-date-${i}`} className={styles.logDatesItem}>
                        <div className={styles.logDatesImgHolder}>
                            {type === 'Student' ?
                                <img src={student.boomerAvatarUrl ? `${student.boomerAvatarUrl}?v=${student.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.logDatesImg} />
                                : type === 'Parent' && student.parentsCounter === 0 ? '' : <img className={styles.logDatesImg} src={`${type === 'Teacher' ? iconTeacher : type === 'Parent' ? iconParent : iconBook}`} alt='' />
                            }
                        </div>
                        {viewType === 'lastRead' && <p>{getRecentLogDate(type)}</p>}
                        {viewType === 'weekRead' && <p>{getWeeklyReads(type)}</p>}
                        {viewType === 'yearRead' && <p>{getYearlyReads(type)}</p>}
                        {viewType === 'customRead' && <p>{getCustomReads(type)}</p>}
                    </div>
                ))
            }
            <div className={styles.logDatesItem}>
                {student.currentReadingRank &&
                    <p><span style={{ background: student.currentReadingRank.readingBandRank.colourCode }} className={styles.bandPill}></span>{student.currentReadingRank.readingBandRank.name}</p>
                }
            </div>
        </>
    )
}

export default ReadingLogDatesRow;