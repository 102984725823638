import React, { Fragment } from 'react';
import BandingInfo from '../../components/BandingInfoPanel';
import { useTranslation } from 'react-i18next';

const BandingView = () => {
    
    const { t } = useTranslation();
    return(
        <Fragment>
            <div className="page-header">
                <div className='page-header__content'>
                    <h1 className="page-header__content__title">{t('reading_bands')}</h1>
                </div>
            </div>
            <div>
                <BandingInfo />
            </div>
        </Fragment>
    )
}

export default BandingView;