import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash'
import styles from './Banding.module.scss';
import * as Icon from 'react-feather';
import Color from './components/color';
import { AuthContext } from '../../services/auth/AuthProvider';
import { ADD_READING_BAND } from './services/graphql';
import { GET_READING_BANDS } from '../../components/BandingInfoPanel/services/graphql';
import { useTranslation } from 'react-i18next';
import SortableBand from './SortableBand';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const Banding = () => {
    const { t } = useTranslation();

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;

    const [addBand, { loading }] = useMutation(ADD_READING_BAND, {
        awaitRefetchQueries: true,
        onCompleted: () => window.history.back(),
        refetchQueries: () => [
            {
                query: GET_READING_BANDS
            }
        ]
    });

    // function getRGB(str){
    //     var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    //     return match ? {
    //       red: match[1],
    //       green: match[2],
    //       blue: match[3]
    //     } : {};
    //   }

    const [banding, setBanding] = useState({
        title: "",
        bands: [{
            id: 1,
            name: t('lilac'),
            r: '178',
            g: '132',
            b: '185',
        }, {
            id: 2,
            name: t('pink'),
            r: '240',
            g: '136',
            b: '126',
        }, {
            id: 3,
            name: t('red'),
            r: '193',
            g: '47',
            b: '31',
        }, {
            id: 4,
            name: t('yellow'),
            r: '252',
            g: '224',
            b: '0',
        }, {
            id: 5,
            name: t('light_blue'),
            r: '106',
            g: '200',
            b: '234',
        }, {
            id: 6,
            name: t('green'),
            r: '1',
            g: '139',
            b: '62',
        }, {
            id: 7,
            name: t('orange'),
            r: '239',
            g: '124',
            b: '0',
        }, {
            id: 8,
            name: t('turquoise'),
            r: '0',
            g: '159',
            b: '142',
        }, {
            id: 9,
            name: t('purple'),
            r: '104',
            g: '62',
            b: '144',
        }, {
            id: 10,
            name: t('gold'),
            r: '240',
            g: '172',
            b: '0',
        }, {
            id: 11,
            name: t('white'),
            r: '255',
            g: '255',
            b: '255',
        }, {
            id: 12,
            name: t('lime'),
            r: '201',
            g: '211',
            b: '1',
        }, {
            id: 13,
            name: t('lime_plus'),
            r: '148',
            g: '193',
            b: '30',
        }, {
            id: 14,
            name: t('brown'),
            r: '177',
            g: '92',
            b: '1',
        }, {
            id: 15,
            name: t('grey'),
            r: '111',
            g: '119',
            b: '122',
        }, {
            id: 16,
            name: t('dark_blue'),
            r: '1',
            g: '83',
            b: '144',
        }, {
            id: 17,
            name: t('dark_red'),
            r: '159',
            g: '21',
            b: '18',
        },
        ],
    })

    const [editBand, setEditBand] = useState({
        show: false,
        band: null,
        index: null,
    })

    const updateBandName = (e, i) => {
        let items = banding.bands;
        let item = {
            ...items[i],
            name: e.currentTarget.value,
        }
        items[i] = item;
        setBanding({ ...banding, bands: items });
    }

    const updateBandColor = (band, index) => {
        let items = banding.bands;
        items[index] = band;
        setBanding({ ...banding, bands: items });
        setEditBand({ show: false, band: null, index: null })
    }

    const removeBand = (i) => {
        let items = banding.bands;
        items.splice(i, 1);
        setBanding({ ...banding, bands: items });
    }

    const sensors = useSensors(
        useSensor(TouchSensor),
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            let oldIndex = banding.bands.findIndex(el => el.id === active.id);
            let newIndex = banding.bands.findIndex(el => el.id === over.id);
            let orderedBands = arrayMove(banding.bands, oldIndex, newIndex);
            setBanding({...banding, bands: orderedBands });
        }
    }

    const getRanks = () => {
        let ranks = [];
        _.map(banding.bands, band => {
            let rank;
            rank = {
                name: band.name,
                colourCode: `rgb(${band.r}, ${band.g}, ${band.b})`,
            }
            ranks.push(rank);
        })
        return ranks;
    }

    return <>
        <div className="icon-heading">
            <h1 className="altTitle title">{t('reading_bands')}</h1>
            <span className="altDesc">{t('colour_bands')}</span>
        </div>
        <div className='card card-mobile'>
            <div>
                <label className="u-m-right-10">{t('name_of_band')}</label>
                <input className={styles.bandInput} type="text" value={banding.title} onChange={(e) => { setBanding({ ...banding, title: e.currentTarget.value }) }} placeholder={t('new_reading_band')} />
            </div>
            <div>
                <ul className="u-m-top-20">


                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={banding.bands.map(el => el.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <ul>
                                {
                                    banding.bands.map((band, i) => (
                                        <SortableBand
                                            key={`band${band.id}`}
                                            band={band}
                                            i={i}
                                            removeBand={() =>removeBand(i)}
                                            updateBandName={(e) => updateBandName(e, i)}
                                            setEditBand={() => setEditBand({ show: true, band: band, index: i })}
                                        />
                                    ))
                                }
                            </ul>
                        </SortableContext>
                    </DndContext>
                    {/* {_.map(banding.bands, (band, i) => {
                        return (
                            <li key={`band${i}`} className={styles.band}>
                                <span className={styles.bandIndex}>{i + 1}</span>
                                <input className={styles.bandInput} type="text" value={band.name} onChange={(e) => updateBandName(e, i)} />
                                <div className={styles.bandColor} style={{ background: `rgb(${band.r}, ${band.g}, ${band.b})` }} onClick={() => setEditBand({ show: true, band: band, index: i })}></div>
                                <Icon.XCircle size="28" onClick={() => removeBand(i)} className={styles.bandRemove} />
                            </li>
                        )
                    })} */}
                </ul>
                <button className="button" onClick={() => setBanding({ ...banding, bands: [...banding.bands, { name: t('new_band'), r: "0", g: "0", b: "0" , id: banding.bands.length + 1}] })}><Icon.PlusCircle size="14" /> {t('button.add')}</button>
            </div>
            <div className="u-m-top-20">
                <button className="button u-m-right-10 buttonSave" onClick={() => addBand({
                    variables: {
                        schoolId: teacher && teacher.school.id,
                        name: banding.title === "" ? t('new_reading_band') : banding.title,
                        ranks: getRanks(),
                    }
                })}>{loading ? t('loading') : t('button.save')}</button>
                <button className="button" onClick={() => window.history.back()}>{t('button.cancel')}</button>
            </div>
        </div>
        {editBand.show &&
            <Color band={editBand.band} update={updateBandColor} index={editBand.index} />
        }
    </>
}

export default Banding;