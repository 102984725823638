import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';
import { useTranslation } from 'react-i18next';
import { UPDATE_STUDENT_NOTE, DELETE_STUDENT_NOTE } from '../../../services/graphql';
import { BooksContext } from '../../../../../services/books/BooksProvider';

const EditNote = ({ close, note, student, notes, setNotes, notesCount, setNotesCount }) => {

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [formErrors, setFormErrors] = useState(false);

    const bookContext = useContext(BooksContext)
    const { activityFeed, setActivityFeed, classActivityFeed, setClassActivityFeed } = bookContext;


    const [updateNote, { loading }] = useMutation(UPDATE_STUDENT_NOTE, {
        update: (_, { data }) => {
            if (data.updateStudentNote.errors && data.updateStudentNote.errors.length > 0) {
                setFormErrors(data.updateStudentNote.errors);
                return
            }
            if (data.updateStudentNote.note && data.updateStudentNote.note.id) {
                const newNotes = [];
                notes.forEach(note => {
                    if (note.id === data.updateStudentNote.note.id) {
                        newNotes.push(data.updateStudentNote.note)
                    } else {
                        newNotes.push(note)
                    }
                })
                setNotes(newNotes);
                close();
            }
        }
    });

    const [deleteNote, { deleting }] = useMutation(DELETE_STUDENT_NOTE, {
        update: (_, { data }) => {
            if (data.deleteStudentNote.errors && data.deleteStudentNote.errors.length > 0) {
                setFormErrors(data.deleteStudentNote.errors);
                return
            }
            if (data.deleteStudentNote && data.deleteStudentNote.deleted) {
                setConfirmDelete(false);
                setNotes(notes.filter(el => el.id !== note.id));
                setActivityFeed([...activityFeed.filter(x => x.studentNoteId !== note.id)]);
                setClassActivityFeed([...classActivityFeed.filter(x => x.studentNoteId !== note.id)]);
                setNotesCount(notesCount - 1);
                close();
            }
        },
    })

    const schema = Yup.object().shape({
        body: Yup.string().required('Please write your note'),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = values => {
        const { title, body } = values;
        updateNote({
            variables: {
                studentId: student.id,
                studentNoteId: note.id,
                title,
                body
            }
        })
    }


    return (
        <>
            <h2 className='u-m-base-3'>Edit note</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <p className='heavy'>Title (optional)</p>
                <div className="basic-form__group ">
                    <input
                        name="title"
                        className="basic-form__text-box"
                        ref={register}
                        placeholder='Title (optional)'
                        defaultValue={note.title}
                    />
                </div>
                <p className='heavy'>Note</p>

                <div className="basic-form__group ">
                    <textarea
                        name="body"
                        rows="6"
                        className="basic-form__text-box"
                        ref={register}
                        placeholder='Add your note'
                        defaultValue={note.body}
                    ></textarea>
                </div>
                {errors.body && <p className='basic-form__hint'>{errors.body.message}</p>}
                {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                <Button type='submit' disabled={loading} className='u-m-right-1'>{loading ? 'Saving' : 'Update'}</Button>
                <Button type="button" outline onClick={() => close()}>{t('button:cancel')}</Button>
                <hr className='separator' />
                <Button type='button' onClick={() => setConfirmDelete(true)} className='u-m-right-1 btn-danger'>Delete</Button>
            </form>
            {confirmDelete && (
                <Modal closeModal={() => setConfirmDelete(false)}>
                    <div className="u-text-center">
                        <h3 className='u-m-base-3'>Are you sure?</h3>
                        <Button
                            type="button"
                            disabled={deleting}
                            onClick={() => deleteNote({
                                variables: {
                                    studentNoteId: note.id
                                }
                            })}
                        >{t('button:yes')}</Button>
                        <Button type="button" className='u-m-left-2' outline onClick={() => setConfirmDelete()}>{t('button:no')}</Button>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default EditNote;