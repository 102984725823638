import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './Banding.module.scss';
import { Move, XCircle } from 'react-feather';

const SortableBand = ({ band, removeBand, updateBandName, setEditBand, i  }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: band.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <li key={`band${band.id}`} ref={setNodeRef} style={style} className={styles.band}>
            <span className={styles.bandIndex}>{i + 1}</span>
            <input className={styles.bandInput} type="text" value={band.name} onChange={updateBandName} />
            <div className={styles.bandColor} style={{ background: `rgb(${band.r}, ${band.g}, ${band.b})` }} onClick={setEditBand}></div>
            <XCircle size="28" onClick={removeBand} className={styles.bandRemove} />
            <span className={styles.bandDrag} {...attributes} {...listeners} ><Move size="16" /></span>
        </li>
    );
}

export default SortableBand;