import React, { useState } from "react";
import _ from 'lodash';
import styles from './TruncateLog.module.scss';

const TruncateLog = ({ str, maxChars = 60 }) => {
    const [showFull, setShowFull] = useState(false);

    if (str.length <= maxChars) {
        return <span className="u-m-right-2">{str}</span>
    }

    return (
        <span className="u-m-right-2">
            {showFull ? <>{str} <span className={styles.underLink} onClick={() => setShowFull(false)}>Read less</span></> :
                <>{_.truncate(str, { 'length': maxChars, 'separator': ' ' })} <span className={styles.underLink} onClick={() => setShowFull(true)}>Read all</span></>
            }
        </span>
    )
}

export default TruncateLog;