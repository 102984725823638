import React, { Fragment, useState } from 'react';
import styles from '../BookSearch.module.scss';
import cx from 'classnames';
import TitleSearchQueries from './titleSearchQueries';
import * as Icon from 'react-feather';
import { useTranslation } from 'react-i18next';

const TitleSearch = ({studentId, close, hide, publisher = null, reset, studentIds, classInfo, setFilter, bookToExistingGroup, groupBookId = null}) => {
    
    const { t } = useTranslation();
    const [bookCode, setBookCode] = useState("");
    const [showSearch, setShowSearch] = useState({
        showSearch: false,
        code: null,
    })
    const handleSearch = (e) => {
        e.preventDefault();
        setShowSearch({
            show: true,
            code: bookCode,
        });
    }

    return (
        <Fragment>
            <div className={styles.bookSearchPanel}>
                <Icon.XCircle className={cx(styles.closeSearch)} onClick={() => hide()}/>
                <Icon.Search size="40" color="#c33764"/>
                {publisher ?
                    <h3 className={styles.optionTitle}>{publisher.name}</h3> :               
                    <h3 className={styles.optionTitle}>{t('title_search')}</h3>
                }
                <form onSubmit={(e) => {handleSearch(e)}} className={cx(styles.searchForm, "u-m-top-10")}>
                    <input type="text" placeholder={t('book_title')} onChange={(e) => {setBookCode(e.currentTarget.value)}} value={bookCode} className={cx(styles.searchInput, "u-m-right-10")} />
                    <button type="submit" className={cx(styles.searchBtn, "u-m-right-10", bookCode !== "" && styles.searchBtnActive)} disabled={bookCode === "" && true}>{t('button.search')}</button>
                    {/*<input type="button" value="Clear" onClick={() => {setBookCode("");setShowSearch({showSearch: false, code: null})}} />*/}
                </form>
            </div>
            {showSearch.show &&
            <TitleSearchQueries code={showSearch.code} studentId={studentId} close={close} publisher={publisher} reset={reset} studentIds={studentIds} bookToExistingGroup={bookToExistingGroup} classInfo={classInfo} setFilter={setFilter} groupBookId={groupBookId} />
            }
            {showSearch.show && <Icon.XCircle onClick={() => {setShowSearch({...showSearch, show: false}); setBookCode('')}} className={styles.closeBook}/> }
        </Fragment>
    )
}

export default TitleSearch;
