import React, { useState, createContext } from 'react';

export const BooksContext = createContext();

export const BooksProvider = ({ children }) => {
    const [books, setBooks] = useState([]);
    const [logs, setLogs] =useState([]);
    const [activityFeed, setActivityFeed] =useState([]);
    const [classActivityFeed, setClassActivityFeed] =useState([]);
    const [currentClass, setCurrentClass] = useState(null)
    return (
        <BooksContext.Provider
            value={{
                books,
                setBooks,
                logs,
                setLogs,
                activityFeed,
                setActivityFeed,
                classActivityFeed,
                setClassActivityFeed,
                currentClass,
                setCurrentClass,
            }}
        >
            {children}
        </BooksContext.Provider>
    )
}