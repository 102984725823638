import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { GET_STUDENT_REPORT } from '../../../services/graphql';
import { READING_BOOK_TYPE } from '../../../../../components/BookSearch/services/constants';

import { Link } from '@reach/router';
import styles from './StudentData.module.scss';
import cx from 'classnames';

const StudentData = ({ student, classInfo, dateRange }) => {

    const [sortType, setSortType] = useState(READING_BOOK_TYPE.reading);
    const [sortedBooks, setSortedBooks] = useState([]);

    const { data, loading, error } = useQuery(GET_STUDENT_REPORT, {
        fetchPolicy: 'network-only',
        variables: {
            id: student.id,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        }
    });

    const getBookCounters = readBooks => {
        return {
            reading: readBooks.filter(el => el.studentBook && el.studentBook.bookType === READING_BOOK_TYPE.reading).length,
            library: readBooks.filter(el => el.studentBook && el.studentBook.bookType === READING_BOOK_TYPE.library).length,
            home: readBooks.filter(el => el.studentBook && el.studentBook.bookType === READING_BOOK_TYPE.home).length,
            group: readBooks.filter(el => el.groupBook).length,
        }
    }

    useEffect(() => {
        if (data && data.student && data.student.detailedStats) {
            const { student: { detailedStats } } = data;
            if (sortType === READING_BOOK_TYPE.group) {
                setSortedBooks(detailedStats.readBooks.filter(el => el.groupBook));
            } else {
                setSortedBooks(detailedStats.readBooks.filter(el => el.studentBook && el.studentBook.bookType === sortType));
            }
        }
    }, [sortType, data, setSortedBooks])

    const getBarWidth = (readBooks, bookType) => {
        const max = Math.max.apply(Math, Object.values(getBookCounters(readBooks)))
        return max === 0 ? 0 : ((getBookCounters(readBooks)[bookType] / max) * 100) + '%';
    }

    if (loading) return <div>Loading...</div>;

    if (error) return <div>{error.message}</div>;

    if (data && data.student && data.student.detailedStats) {

        const { student } = data;
        const { detailedStats } = student;

        return (
            <>
                <h2 className={styles.stHeader}>
                    {student.name}
                    <Link to={`/student/${student.id}`} className='link-underline' state={{ classInfo: classInfo }}>view</Link>
                </h2>

                {dateRange.startDate && dateRange.endDate && <p className='u-m-top-2 u-m-base-3'>{moment(dateRange.startDate).format('DD/MM/YYYY')} - {moment(dateRange.endDate).format('DD/MM/YYYY')} </p>}
                <div className={styles.wrapper}>
                    <div className={styles.wrapperMain}>
                        <h4 className={styles.countItem}>
                            <span>{detailedStats.completedBooksCounter}</span> books completed
                        </h4>
                        <ul className={styles.bookStats}>
                            <li>
                                <div className={styles.bookStatsLabel}>Reading books</div>
                                <div className={styles.bookStatsBarHolder}>
                                    <div className={styles.bookStatsBarValue} style={{ width: `${getBarWidth(detailedStats.readBooks, 'reading')}` }}></div>
                                </div>
                                <div className={styles.bookStatsBarNo}>{getBookCounters(detailedStats.readBooks).reading}</div>
                            </li>
                            <li>
                                <div className={styles.bookStatsLabel}>Library books</div>
                                <div className={styles.bookStatsBarHolder}>
                                    <div className={styles.bookStatsBarValue} style={{ width: `${getBarWidth(detailedStats.readBooks, 'library')}` }}></div>
                                </div>
                                <div className={styles.bookStatsBarNo}>{getBookCounters(detailedStats.readBooks).library}</div>
                            </li>
                            <li>
                                <div className={styles.bookStatsLabel}>Home books</div>
                                <div className={styles.bookStatsBarHolder}>
                                    <div className={styles.bookStatsBarValue} style={{ width: `${getBarWidth(detailedStats.readBooks, 'home')}` }}></div>
                                </div>
                                <div className={styles.bookStatsBarNo}>{getBookCounters(detailedStats.readBooks).home}</div>
                            </li>
                            <li>
                                <div className={styles.bookStatsLabel}>Group books</div>
                                <div className={styles.bookStatsBarHolder}>
                                    <div className={styles.bookStatsBarValue} style={{ width: `${getBarWidth(detailedStats.readBooks, 'group')}` }}></div>
                                </div>
                                <div className={styles.bookStatsBarNo}>{getBookCounters(detailedStats.readBooks).group}</div>
                            </li>
                        </ul>


                        <div className={styles.bookHistory}>
                            <h4>Book History</h4>
                            <div className={styles.bookHistorySelector}>
                                <select value={sortType} className={cx('basic-form__text-select', styles.bookHistsorySelector)} onChange={(e) => setSortType(+(e.target.value))}>
                                    <option value={READING_BOOK_TYPE.reading}>Reading Books</option>
                                    <option value={READING_BOOK_TYPE.library}>Library Books</option>
                                    <option value={READING_BOOK_TYPE.home}>Home Books</option>
                                    <option value={READING_BOOK_TYPE.group}>Group Books</option>
                                </select>
                            </div>
                        </div>
                        <ul className='u-m-top-2'>
                            {sortedBooks.map(el => {
                                return (
                                    <li key={el.id}>
                                        <h4>{el.groupBook ? el.groupBook.book.title : el.studentBook.book.title}</h4>
                                        <p className='smaller-text u-m-base-2'>{el.groupBook ? el.groupBook.readDates : el.studentBook.readDates}</p>
                                    </li>
                                )
                            })}
                        </ul>

                    </div>
                    <div className={styles.wrapperSide}>
                        <div className={styles.wrapperPanel}>
                            <p className='u-m-base-2'>Reading logs</p>
                            <div className={styles.countItem}>
                                <span>{detailedStats.counters.parentLogs}</span> Parent
                            </div>
                            <div className={styles.countItem}>
                                <span>{detailedStats.counters.teacherLogs}</span> Teacher
                            </div>
                            <div className={styles.countItem}>
                                <span>{detailedStats.counters.studentLogs}</span> Pupil
                            </div>
                        </div>

                        <div className={styles.wrapperPanel}>
                            <p className='u-m-base-2'>Reading bands</p>
                            {detailedStats.ranks.length === 0 ? <p className='small'>--</p> :
                                <ul className={styles.bands}>
                                    {[...detailedStats.ranks].reverse().map((rank, i) => (
                                        <li key={rank.id}>
                                            <span className={styles.bandsPill} style={{ background: rank.readingBandRank.colourCode }}></span>
                                            <div className={styles.bandsContent}>
                                                <div className={styles.bandsTitle}>
                                                    <p>{rank.readingBandRank.name}</p>
                                                    {i === 0 && <span>Current</span>}
                                                </div>
                                                <p className='smaller-text'>{moment(rank.assignedAt).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    };

    return null

}

export default StudentData;