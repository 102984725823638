import { gql } from 'apollo-boost';

export const EDIT_GROUP_LOG = gql`
mutation editGroupReadingLog($groupReadingBookLogId: ID!, $entry: String, $problemWords: [String!]) {
    editGroupReadingBookLog(groupReadingBookLogId: $groupReadingBookLogId, entry: $entry, problemWords: $problemWords) {
        groupReadingBookLog {
            id
            date
            isCompletion
            readingDate
            entry
            book {
                id
                book {
                    id
                    title
                }
            }
            studentIds
            author {
                id
                name
                role
                displayName
            }
            problemWords {
                id
                word
            }
            pageStart
            pageEnd
        }
        errors
    }
}
`;

export const REMOVE_FROM_GROUP = gql`
mutation removeStudentFromGroupReadingBook($groupReadingBookId: ID!, $studentId: ID!) {
    removeStudentFromGroupReadingBook(groupReadingBookId: $groupReadingBookId, studentId: $studentId) {
        deleted
        errors
    }
}
`

export const GET_CLASS_QUERY = gql`
query studentsClass($id: ID!) {
    studentsClass(id: $id) {
        id
        name
        students {
            id
            firstName
            lastName
        }
    }
}
`;

export const EDIT_GROUP_BOOK = gql`
    mutation editGroupReadingBook($groupReadingBookId: ID!, $hideComments: Boolean) {
        editGroupReadingBook(groupReadingBookId: $groupReadingBookId, hideComments: $hideComments) {
            groupReadingBook {
                id
                book {
                    id
                    coverUrl
                    title
                    author
                    publisher {
                        id
                        name
                    }
                    isbn
                    isbn13
                    reviews {
                        id
                        author {
                            id
                        }
                        reviewText
                        reviewRate
                        date
                    }
                }
                status
                students {
                    id
                    firstName
                    lastName
                }
                completedAt
                createdAt
                hideComments
                mostRecentLog {
                    id
                    readingDate
                    pageEnd
                    pageStart
                }
            }
            errors
        }
    }
`

export const DELETE_GROUP_BOOK = gql`
    mutation deleteGroupReadingBook($groupReadingBookId: ID!) {
        deleteGroupReadingBook(groupReadingBookId: $groupReadingBookId) {
            deleted
            errors
        }
    }
`

export const DELETE_GROUP_LOG = gql`
    mutation deleteGroupReadingBookLog($groupReadingBookLogId: ID!) {
        deleteGroupReadingBookLog(groupReadingBookLogId: $groupReadingBookLogId) {
            deleted
            errors
        }
    }
`