// Queries and mutations for the Home module
import { gql } from 'apollo-boost';

export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;

export const GET_USER = gql`
query currentTeacher {
    currentTeacher {
        id
        avatarUrl
        displayName
        email
        expiredToken
        firstName
        isSchoolAdmin
        lastName
        name
        lastTermsVersionApproved
        roleId
        school {
            id
            canAccessGoReadApp
            canAccessDataDashboard
            canAccessWonde
            isBeta
            name
            schoolCode
            weekStart
            hasSetWeekStart
            hasRegistrationClasses
            allowSeens
            allowLikes
            classes {
                id
                name
                description
            }
        }
        studentsClasses {
            id
            name
            createdAt
            description
        }
        visibleStudentsClasses {
            id
            name
            createdAt
            description
        }
        settings {
            classFeedActivityFilters
        }
    }
}
`;

export const CHECK_DOWNLOAD_PERMISSIONS = gql`
query currentTeacher($schoolId: ID!) {
    currentTeacher {
        id
        canDownloadStudentData(schoolId: $schoolId)
        canManageStudentNotes(schoolId: $schoolId)
    }
}
`;


export const CHECK_NOTES_PERMISSIONS = gql`
query currentTeacher($schoolId: ID!) {
    currentTeacher {
        id
        canManageStudentNotes(schoolId: $schoolId)
    }
}
`;