import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { REQUEST_DATA_DOWNLOAD } from './services/graphql';
import RangeCalendar from '../../../../components/RangeCalendar';
import moment from 'moment';
import { ClassFilterContext } from '../../../../services/class/ClassFilterProvider';
import cx from 'classnames';
import styles from './DataDownload.module.scss';

const RequestDataForm = ({ student, close, setRequestDone, userPermissions }) => {

    const [formErrors, setFormErrors] = useState([]);

    const classFilterContext = useContext(ClassFilterContext);
    const { setPupilCsvExport } = classFilterContext;
    const filtersLength = userPermissions && userPermissions.canManageStudentNotes ? 10 : 9;


    const acadYear = moment().toDate().getMonth() < 8 ? moment().year() - 1 : moment().year();
    //go back to 1st of july to cover summer activies
    const minDate = moment('01-07-' + acadYear, 'DD-MM-YYYY').toDate();
    //const minDate = moment('01-07-2022', 'DD-MM-YYYY').toDate(); //LP: temp change for a school (sept 2023)

    const [range, setRange] = useState({
        startDate: null,
        endDate: moment().toDate()
    })

    const [all, setAll] = useState(true);
    const [filters, setFilters] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);


    useEffect(() => {
        if (userPermissions && userPermissions.canManageStudentNotes) {
            setFilters([1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]);
        }
    }, [userPermissions]);

    const handleAll = () => {
        if (all) {
            setAll(false)
            setFilters([])
        } else {
            setFilters(userPermissions && userPermissions.canManageStudentNotes ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,] : [1, 2, 3, 4, 5, 6, 7, 8, 9,])
            setAll(true)
        }
    }

    const [requestDataDownload, { loading: requesting }] = useMutation(REQUEST_DATA_DOWNLOAD, {
        onCompleted: (data) => {
            if (data.requestDataDownload.errors && data.requestDataDownload.errors.length > 0) {
                setFormErrors(data.requestDataDownload.errors);
                return
            }
            if (data.requestDataDownload && data.requestDataDownload.exportDataTicket && data.requestDataDownload.exportDataTicket.id) {
                setPupilCsvExport(data.requestDataDownload.exportDataTicket);
                setRequestDone(true);
                close();
            }
        }
    });


    const onChangeFilter = (e) => {
        if (e.target.value.length > 1 && e.target.value.indexOf(',') > -1) {
            var filterNums = [];
            [...e.target.value].filter(i => i !== ',').forEach(str => {
                filterNums.push(Number(str));
            })
            if (filterNums.every(elem => filters.indexOf(elem) > -1)) {
                setFilters(filters.filter(i => !filterNums.includes(i)))
                setAll(false)
            } else {
                if (filters.concat(filterNums).length === filtersLength) {
                    setAll(true)
                }
                setFilters(filters.concat(filterNums))
            }
        } else {
            if (filters.indexOf(parseInt(e.target.value)) !== -1) {
                setFilters(filters.filter(s => s !== parseInt(e.target.value)));
                setAll(false)
            } else {
                if ([...filters, parseInt(e.target.value)].length === filtersLength) {
                    setAll(true)
                }
                setFilters([...filters, parseInt(e.target.value)]);
            }
        }
    }

    return (
        <>
            <h3 className='u-m-base-2'>Customize download</h3>
            <div className={styles.panel}>
                <p className='u-bold'>1. Include in export</p>
                <div className={styles.filterList}>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input onChange={(e) => handleAll()} className={"basic-form__check-box"} type="checkbox" value={'ALL'} checked={all} id="filterCheckAll" />
                        <label className="basic-form__check-label" htmlFor="filterCheckAll">All</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={9} checked={filters.indexOf(9) > -1} id="filterCompletedBook" />
                        <label className="basic-form__check-label" htmlFor="filterCompletedBook">Completed Book</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input id="filterBookAssigned" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={[7, 8]} checked={[7, 8].every(elem => filters.indexOf(elem) > -1)} />
                        <label htmlFor="filterBookAssigned">New Book Added</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input id="filterReadingTeacher" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={[1, 4]} checked={[1, 4].every(elem => filters.indexOf(elem) > -1)} />
                        <label htmlFor="filterReadingTeacher">Reading Log - Teacher</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input id="filterReadingParent" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={3} checked={filters.indexOf(3) > -1} />
                        <label htmlFor="filterReadingParent">Reading Log - Parent</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input id="filterReadingStudent" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={2} checked={filters.indexOf(2) > -1} />
                        <label htmlFor="filterReadingStudent">Reading Log - Student</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input id="filterReview" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={5} checked={filters.indexOf(5) > -1} />
                        <label htmlFor="filterReview">Book Review</label>
                    </div>
                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                        <input id="filterReadingBand" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={6} checked={filters.indexOf(6) > -1} />
                        <label htmlFor="filterReadingBand">New Reading Band</label>
                    </div>
                    {userPermissions && userPermissions.canManageStudentNotes && (
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="filterNotes" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={10} checked={filters.indexOf(10) > -1} />
                            <label htmlFor="filterNotes">Notes</label>
                        </div>
                    )}
                </div>
                <p className='u-bold u-m-base-1'>2. Select interval</p>
                <RangeCalendar
                    showTextLabels={true}
                    onSetRanges={setRange}
                    minDate={minDate}
                    dateRange={range} 
                />
            </div>
            <Button disabled={requesting || !range.startDate || !range.endDate || filters.length === 0}
                onClick={() => requestDataDownload({
                    variables: {
                        schoolId: student.school.id,
                        studentId: student.id, 
                        startDate: moment(range.startDate).format('DD/MM/YYYY'),
                        duration: moment(range.endDate).diff(moment(range.startDate), 'days'),
                        activityType: filters
                    }
                })}
            >Export to CSV</Button>
            <Button outline onClick={close} className='u-m-left-2'>Cancel</Button>
            {formErrors && <FormErrors errors={formErrors} />}
        </>
    )
}

export default RequestDataForm;