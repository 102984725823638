import React, { useState, useRef, useEffect } from 'react';
import SaveConfirm from '../../../components/SaveConfirm';
import { useMutation } from '@apollo/react-hooks';
import { getFlagColor, FLAGS } from '../../../utils/constants';
import { SET_STUDENTS_FLAG } from '../../ClassNew/services/graphql';
import { GET_STUDENT_QUERY } from '../services/graphql';
import cx from 'classnames';
import * as Icon from 'react-feather';
import styles from '../Student.module.scss';

const SetFlag = ({ student }) => {

    const [showFlagSuccess, setShowFlagSuccess] = useState(false);

    const [flagMenu, setFlagMenu] = useState(false);

    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setFlagMenu(false);
        }
    };

    const [setStudentsFlag] = useMutation(SET_STUDENTS_FLAG, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }],
        onCompleted: () => {
            setShowFlagSuccess(true);
            setFlagMenu(false);
        }
    });

    const flagStudent = (type, remove) => {
        setStudentsFlag({
            variables: {
                studentIds: [student.id],
                flagType: type,
                removeFlags: remove

            }
        })
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <>
            <div ref={ref} className='dropdown dropdownTop'>
                <button className={cx('dropdownBtn')} onClick={() => setFlagMenu(!flagMenu)}>
                    <Icon.Flag size="20" onClick={() => ''} fill={student.flagType ? getFlagColor(student.flagType) : '#ffffff'} color={student.flagType ? getFlagColor(student.flagType) : '#cccccc'} className={cx(styles.flagBtn, 'u-m-left-1 u-m-right-2')} />
                </button>
                {flagMenu && (
                    <ul className="dropdownMenu dropdownMenuLeft">
                        {FLAGS.map(flag =>
                            <li key={`flag-${flag.name}`}>
                                <button  onClick={() => flagStudent(flag.type, false)}>
                                    <span className='u-display-flex u-align-center light'><Icon.Flag size="20" fill={flag.color} color={flag.color} className={cx(styles.flagBtn, 'u-m-right-1')} /> {flag.name}</span>
                                </button>
                            </li>
                        )}
                        <li>
                            <button onClick={() => flagStudent(null, true)}>
                                <span className='u-display-flex u-align-center light'>   <Icon.Flag size="20" className={cx(styles.flagBtn, 'u-m-right-1')} /> Un-flag</span>
                            </button>
                        </li>
                    </ul>
                )}
            </div>
            {showFlagSuccess && <SaveConfirm classNames={styles.flagSuccess} hideConfirm={() => setShowFlagSuccess(false)} message={'Done'} />}
        </>
    )
}

export default SetFlag;