import React from "react";
import { getUrl, APPS } from '@axeedge/go-shared-utils';
import { Settings } from "react-feather";
import styles from '../SchoolData.module.scss';
import cx from 'classnames';

const AccountStats = ({ statsData, showSchool }) => {

    return (
        <div className={cx(styles.accounts, 'u-m-base-4')}>
            <div className={cx(styles.accountsStats, styles.accountsGreen)}>
                <p>{showSchool ? 'Purchased Accounts' : 'Total accounts'}</p>
                <span>{statsData.purchasedAccounts}</span>
            </div>
            <div className={cx(styles.accountsStats, styles.accountsRed)}>
                <p>Active Accounts</p>
                <span>{statsData.activeAccounts}</span>
            </div>
            <div className={cx(styles.accountsStats, styles.accountsLightBlue)}>
                <p>No. of Active Parents</p>
                <span>{statsData.activeParents}</span>
            </div>
            <div className={cx(styles.accountsStats, styles.accountsBlue)}>
                <p className="u-display-flex u-align-center">Account Expires <a href={`${getUrl(APPS.go_portal_teacher)}/settings/boomreader`} className="u-m-left-2"
                ><Settings size={20} /></a></p>
                <span>{statsData.subscriptionExpiresAt}</span>
            </div>
        </div>
    )
}

export default AccountStats;