import { Button } from '@axeedge/go-teacher-components';
import React from 'react';

const ComparisonFilters = ({ filters, setFilters, applyFilters, criteria, loading }) => {

    const titleCase = str => str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()).split('_').join(' ');

    const onChangeFilter = (name, category) => {

        const updatedValues = filters[category].values.includes(name) ? filters[category].values.filter(v => v !== name) : [...filters[category].values, name]
        setFilters((prevFilters) => ({
            ...prevFilters,
            [category]: {
                ...prevFilters[category],
                values: updatedValues,
            },
        }));
    }

    const onDeselectAll = () => {
        const f = [];
        criteria.forEach(el => {
            f.push({
                category: el.name,
                values: []
            })
        })
        const filtersObjects = f.reduce((acc, curr) => {
            acc[curr.category] = { values: curr.values };
            return acc;
        }, {});
        setFilters(filtersObjects);
    }
    const getCritDetailName = (text, categName) => {
        let name = text;
        if (text === 'true') {
            name = titleCase(categName);
        }
        if (text === 'false') {
            name = 'None'
        }
        if (text === '') {
            name = 'Not specified'
        }
        return name;
    }

    return (
        <>
            <h1 className='u-m-base-2'>Filters</h1>
            <p className='lead u-bold '>Select the criteria you want to compare</p>
            <div className='u-m-base-3 u-m-top-1'>
                <input onChange={() => onDeselectAll()} checked={Object.entries(filters).every(el => el[1].values.length === 0)} type='checkbox' className='checkBoxRegular' /> <label htmlFor='none'>None</label>
            </div>
          {criteria.sort((a, b) => a.name.localeCompare(b.name)).map(crit => (
                <div key={crit.id}>
                    <p className='lead u-bold u-m-base-2'>{titleCase(crit.name)}</p>
                    {
                        crit.details.map(critDetail => (
                            <div className='u-m-base-1' key={`${critDetail.id}`}>
                                <input checked={filters[crit.name].values.includes(critDetail.name)} value={critDetail.name} onChange={(e) => onChangeFilter(e.target.value, crit.name)} id={`${critDetail.id}-${critDetail.name}`} type='checkbox' className='checkBoxRegular' />
                                <label htmlFor={`${critDetail.id}-${critDetail.name}`}> {getCritDetailName(critDetail.name, crit.name)}</label>
                            </div>
                        ))
                    }
                    <hr className='separator u-m-top-2 u-m-base-2' />
                </div>
            ))}

            <Button disabled={loading} onClick={() => applyFilters(filters)}>Update</Button>
        </>
    )
}

export default ComparisonFilters;