import React, { Fragment, useState, useContext } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClassFilterContext } from '../../../../services/class/ClassFilterProvider';

import { useTranslation } from 'react-i18next';

import { Button } from '@axeedge/go-teacher-components';
import styles from './CustomReadDates.module.scss';

const CustomReadDates = ({ onDisplayCustom }) => {

    const { t } = useTranslation();
    const classFilterContext = useContext(ClassFilterContext);
    const {intervalStats} = classFilterContext;

    const [readDateRange, setReadDateRange] = useState({
        startDate: intervalStats && intervalStats.startDate ? moment(intervalStats.startDate, 'DD/MM/YYYY').toDate() : moment().subtract(30, 'days').toDate(),
        endDate: intervalStats && intervalStats.endDate ? moment(intervalStats.endDate, 'DD/MM/YYYY').toDate()  : moment().toDate()
    });

    const minDate = moment().subtract(1, 'year').toDate();

    return (
        <Fragment>
            <h2 className='u-m-base-4 u-text-center'>Choose dates</h2>
            <div className={styles.form}>
                <div className='u-m-right-2'>
                    <p>{t('Start date')}</p>
                    <DatePicker
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName='picker'
                        className="basic-form__text-box picker"
                        selected={readDateRange.startDate}
                        selectsStart
                        startDate={readDateRange.startDate}
                        minDate={minDate}
                        maxDate={moment().toDate()}

                        endDate={readDateRange.endDate}
                        onChange={(date) => setReadDateRange({ ...readDateRange, startDate: date })}
                    />
                </div>
                <div>
                    <p>{t('End date')}</p>
                    <DatePicker
                        dateFormat='dd/MM/yyyy'
                        wrapperClassName='picker'
                        className="basic-form__text-box picker"
                        selected={readDateRange.endDate}
                        selectsEnd
                        maxDate={moment().toDate()}
                        startDate={readDateRange.startDate}
                        endDate={readDateRange.endDate}
                        onChange={(date) => setReadDateRange({ ...readDateRange, endDate: date })}
                    />
                </div>
            </div>
            <Button className='u-m-top-3' onClick={() => onDisplayCustom(readDateRange)}>{t('Apply')}</Button>
        </Fragment>
    )
}

export default CustomReadDates;