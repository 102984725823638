import React from "react";
import styles from '../SchoolData.module.scss';
import cx from 'classnames';

const Top20Books = ({ books }) => {

 

    return (
        <>
            <h2 className="u-bold u-m-base-2">Top 20 Books</h2>
            <ul className={cx(styles.bookList, 'u-m-top-2 u-m-base-2')}>
                <li className={styles.bookListItem}>
                    <p className="u-bold">Title</p>
                    <p className="u-bold">Author</p>
                </li>
                {books && books.map((book, i) => (
                    <li key={book.id} className={styles.bookListItem}>
                        <div>
                            <span className="u-bold u-m-right-2">{i + 1}</span>
                            {book.title}
                        </div>
                        <div className={styles.bookListAuthor}>{book.author}</div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Top20Books;