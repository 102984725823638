import { gql } from 'apollo-boost';

export const SEARCH_STUDENTS = gql`
query studentsByName($schoolId: ID!, $name: String!) {
    studentsByName(schoolId: $schoolId, name: $name) {
        id
        firstName
        lastName
        avatarJson
        updatedAt
        boomerAvatarUrl
        classes {
            id
            name
            typeId
        }
    }
}
`;

