import { gql } from 'apollo-boost';

export const ADD_READING_BAND = gql`
mutation addReadingBand(
        $schoolId: ID!,
        $name: String!,
        $ranks: [ReadingBandRankAttributes!]!,
    ) {
        addReadingBand(schoolId: $schoolId, name: $name, ranks: $ranks) {
        readingBand {
            id
        }
        errors
    }
}
`;

export const ASSIGN_BAND = gql`
mutation assignReadingBand(
        $studentId: ID!,
        $readingBandRankId: ID!,
    ) {
        addReadingBandToStudent(studentId: $studentId, readingBandRankId: $readingBandRankId) {
            student {
                id
                currentReadingRank {
                    id
                    assignedAt
                    readingBandRank {
                        id
                        name
                        colourCode
                    }
                }
            }
            errors
        } 
    }
`;