import { gql } from 'apollo-boost';

export const REQUEST_DATA_DOWNLOAD = gql`
mutation requestDataDownload($schoolId: ID!, $studentId: ID!, $startDate: String!, $duration: Int!, $activityType: [Int!]) {
    requestDataDownload(schoolId: $schoolId, studentId: $studentId, startDate: $startDate, duration: $duration, activityType: $activityType) {
        errors
        exportDataTicket {
            id
            student {
                id
                name
            }
        }
    }
}
`;

export const EXPORT_DATA_TICKET = gql`
query exportDataTicket($id: ID!) {
    exportDataTicket(id: $id) {
        id
        status
        exportUrl
        requestedBy {
            id
            name
            displayName
        }
        student {
            id
            name
        }
    }
}
`;

export const GET_PUPIL_EXPORTS = gql`
query student($id: String!) {
    student(id: $id) {
        id
        exportDataTickets {
            id
            from
            to
            exportUrl
            activityType
            status
            student {
                id
                name
            }
            requestedBy {
                id
                name
                displayName
            }
        }
    }
}
`;
