import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Tabs from '../../../../components/Tabs';
import Tab from '../../../../components/Tab';
import { APPROVE_REVIEW, ARCHIVE_REVIEW, GET_CLASS_REVIEWS, GET_SINGLE_REVIEW } from "../../services/graphql";
import { Star } from "react-feather";
import { FormErrors } from '@axeedge/go-teacher-components';
import EditReview from './EditReview';
import { REVIEW_STATUS } from "../../../../utils/constants";
import styles from './BookReviews.module.scss';
import moment from "moment/moment";

const stars = [1, 2, 3, 4, 5]
const limit = 5;

const BookReviews = ({ viewReview = null, classId }) => {

    const [review, setReview] = useState(null);


    const [reviews, setReviews] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [filter, setFilter] = useState(REVIEW_STATUS.new);

    const [showEdit, setShowEdit] = useState(null);
    const [mutationErrors, setMutationErrors] = useState([]);

    const [getInitialReviews, { loading: loadingInitial }] = useLazyQuery(GET_CLASS_REVIEWS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            const newReviews = data.studentsClass.bookReviews || [];
            setReviews(newReviews);
            setHasMore(newReviews.length === limit);
            setOffset(newReviews.length);
        }
    });


    const [getSingleReview] = useLazyQuery(GET_SINGLE_REVIEW, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setReview(data.readingBookReview);
        }
    });

    useEffect(() => {
        if (viewReview && !review) {
            getSingleReview({
                variables: {
                    readingBookReviewId: viewReview.readingBookReviewId
                }
            });
        }
    }, []);

    const [getMoreReviews, { loading: loadingMore }] = useLazyQuery(GET_CLASS_REVIEWS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            const moreReviews = data.studentsClass.bookReviews || [];
            if (moreReviews.length > 0) {
                setReviews(prevReviews => prevReviews.concat(moreReviews));
                setOffset(prevOffset => prevOffset + moreReviews.length);
            }
            setHasMore(moreReviews.length === limit);
        }
    });


    useEffect(() => {
        setReviews([]);
        getInitialReviews({
            variables: {
                id: classId,
                offset: 0,
                status: filter,
                limit
            }
        });
        setReview(null);
    }, [filter, classId, getInitialReviews]);

    const loadMoreReviews = () => {
        getMoreReviews({
            variables: {
                id: classId,
                // offset: reviews.length,
                offset: offset,
                status: filter,
                limit
            }
        })
    }

    const [approveReadingBookReview, { loading: publishing }] = useMutation(APPROVE_REVIEW, {
        update: (_, { data }) => {
            if (data.approveReadingBookReview?.errors?.length > 0) {
                setMutationErrors(data.approveReadingBookReview.errors);
                return;
            }
            if (data.approveReadingBookReview.readingBookReview?.id) {
                const updatedReview = data.approveReadingBookReview.readingBookReview;
                setReview({ ...review, status: updatedReview.status });
                setReviews(prevReviews => prevReviews.filter(review => review.id !== updatedReview.id));

            }
        }
    });

    const [archiveReadingBookReview, { loading: archiving }] = useMutation(ARCHIVE_REVIEW, {
        update: (_, { data }) => {
            if (data.archiveReadingBookReview?.errors?.length > 0) {
                setMutationErrors(data.archiveReadingBookReview.errors);
                return;
            }
            if (data.archiveReadingBookReview?.readingBookReview?.id) {
                const updatedReview = data.archiveReadingBookReview.readingBookReview;
                setReview({ ...review, status: updatedReview.status });
                setReviews(prevReviews => prevReviews.filter(review => review.id !== updatedReview.id));
            }
        }
    });


    if (reviews) {
        return (
            <div className={styles.reviews}>
                <div>
                    <Tabs className='u-m-base-3'>
                        <Tab selected={filter === REVIEW_STATUS.new} onClick={() => setFilter(REVIEW_STATUS.new)}>Reviews</Tab>
                        <Tab selected={filter === REVIEW_STATUS.approved} onClick={() => setFilter(REVIEW_STATUS.approved)}>Published</Tab>
                        <Tab selected={filter === REVIEW_STATUS.archived} onClick={() => setFilter(REVIEW_STATUS.archived)}>Archived</Tab>
                    </Tabs>
                    {loadingInitial ? <p>Loading...</p> :
                        <>
                            {reviews.length > 0 ?
                                <>
                                    {reviews.map(review => (
                                        <div onClick={() => setReview(review)} className={styles.reviewItem} key={review.id}>
                                            <div className={styles.reviewItemStar}>
                                                <Star size={30} color="#ffffff" />
                                            </div>
                                            <div className="u-m-left-2 u-full-width">
                                                <h3 className="light u-display-flex u-align-center u-m-base-1">
                                                    {review.author.name}
                                                    <span className="u-m-left-2">
                                                        {stars.map(star => {
                                                            return <Star color="#000000" key={`star${star}`} size="18" fill={star <= review.reviewRate ? '#FFD506' : 'none'} />
                                                        })}
                                                    </span>
                                                </h3>
                                                <div className="u-display-flex u-justify-between">
                                                    <p className="small">{review.book.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {hasMore ? <button disabled={loadingMore} onClick={() => loadMoreReviews()} className="btn btn-outline">Load more</button> : limit < reviews.length && <p>End of list</p>}
                                </> :
                                <p>No reviews</p>
                            }
                        </>
                    }
                </div>


                {showEdit ? <EditReview stars={stars} review={review} setReview={setReview} setShowEdit={setShowEdit} /> :
                    <div className={styles.reviewsPanel}>
                        {!review ? <div className={styles.reviewsInner}>Select a review on the left to see it</div> :
                            <>
                                <div className={styles.reviewsInner}>
                                    <div className={styles.reviewHeader}>
                                        <div><p className="bold">{review.author.name}</p></div>
                                        <div>
                                            {stars.map(star => {
                                                return <Star color="#000000" key={`star${star}`} size="26" fill={star <= review.reviewRate ? '#FFD506' : 'none'} />
                                            })}
                                        </div>
                                    </div>
                                    <p className="u-m-base-1 bold lead">{review.book.title}</p>
                                    <p className="small u-m-base-3">Reviewed on: {moment(review.date).format("DD/MM/YYYY")}</p>

                                    <div className="newlines">
                                        {review.reviewText}
                                    </div>
                                    <FormErrors errors={mutationErrors || []} />
                                </div>
                                <div className={styles.reviewFooter}>

                                    <button
                                        onClick={() => approveReadingBookReview({
                                            variables: { readingBookReviewId: review.id, approve: review.status === REVIEW_STATUS.approved ? false : true }
                                        })}
                                        disabled={publishing}
                                        type="button"
                                        className="btn-primary"
                                    >{review.status === REVIEW_STATUS.approved ? 'Un-Publish' : 'Publish'}</button>

                                    <button type="button" onClick={() => setShowEdit(review)} className="btn-purple">Edit</button>
                                    <button
                                        disabled={archiving}
                                        type="button"
                                        onClick={() => archiveReadingBookReview({
                                            variables: { readingBookReviewId: review.id, archive: review.status === REVIEW_STATUS.archived ? false : true }
                                        })}
                                        className="btn-blue">{review.status === REVIEW_STATUS.archived ? 'Un-Archive' : 'Archive'}</button>

                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        )
    }
    return null;
}

export default BookReviews;