import React, {useEffect, useRef, useState, useContext} from 'react';

import moment from 'moment';
import cx from 'classnames';
import * as Icon from 'react-feather'
import { Tooltip } from 'react-tippy';

import { Button } from '@axeedge/go-teacher-components';

import { GET_STUDENT_BOOKS} from '../services/graphql';
import { RB_COMPLETE, RB_NEW, RB_ACTIVE } from '../../../utils/constants';
import { useEffectOnce } from 'react-use';
import { useLazyQuery } from '@apollo/react-hooks'; 
import { BooksContext } from '../../../services/books/BooksProvider';

import BookTypeLabel from '../../../components/BookTypeLabel'

import styles from './Search.module.scss';
import loader from '../../../images/loader.svg';

import { useTranslation } from 'react-i18next';

const Search = ({type, status, term, search, searchMethod, student, event}) => {
    const shouldSearch = useRef(search);

    const bookContext = useContext(BooksContext)
    const {books, setBooks} = bookContext;
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const { t } = useTranslation();

    const [fetchBooks, { loading }] = useLazyQuery(GET_STUDENT_BOOKS, {
        onCompleted: data => {
            setCount(data.student.readingBooksByQuery.total + data.student.groupReadingBooksByQuery.total)
            setBooks([...data.student.readingBooksByQuery.items, ...data.student.groupReadingBooksByQuery.items])
        },
        fetchPolicy: 'network-only'
    });
    
    const [loadMore, {loading: loadingMore}] = useLazyQuery(GET_STUDENT_BOOKS, {
        onCompleted: data => {
            setBooks(books.concat([...data.student.readingBooksByQuery.items, ...data.student.groupReadingBooksByQuery.items]))
            setPage(page + 1);
        },
        fetchPolicy: 'network-only',  
    })

    useEffect(() => {
        if (search !== shouldSearch.current) {
            shouldSearch.current = search;
            if (search) {
                type === 'all' ?
                fetchBooks({variables: {
                    id: student.id,
                    status: status === 'active' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE],
                    title: term,
                }})
                :
                fetchBooks({variables: {
                    id: student.id,
                    status: status === 'active' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE],
                    title: term,
                    bookType: type,
                }});
                searchMethod(false)
            }
        }
    }, [search])

    useEffectOnce(() => {
        fetchBooks({variables: {
            id: student.id,
            status: status === 'active' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE],
        }});
    })

    const loadMoreBooks = () => {
        type === 'all' ?
        loadMore({variables: {
            id: student.id,
            status: status === 'active' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE],
            title: term,
            page: page + 1,
        }})
        :
        loadMore({variables: {
            id: student.id,
            status: status === 'active' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE],
            title: term,
            bookType: type,
            page: page + 1,
        }});
    }

    return (
        <>
            {loading ? <img src={loader} alt="loading" />:
            <>
                {(books.length) > 0 ? books.map(book => {
                    return (
                        <div key={`book${book.id}`} className={styles.book}>
                            <div className={styles.bookDetails} onClick={() => event({open: true, book: book, history: false,})}>
                                <div>
                                    <h1 className={styles.bookTitle}>{book.book.title}</h1>
                                    {book.group && <h2 className={styles.bookGroup}>{book.group.name}</h2>}
                                    <div className={styles.bookMeta}>
                                        {book.bookType ? <BookTypeLabel type={book.bookType}/> : <BookTypeLabel type={'NewGroup'} />}
                                        {book.completedAt ? <p className={cx(styles.bookMeta, styles.bookCompletedFlag)}><Icon.CheckCircle size="16" className="u-m-right-5" />{moment(book.completedAt).format("DD MMM YY")}</p> : 
                                            book.mostRecentLog ?
                                                <p className={cx(styles.bookMeta, styles.bookCompletedFlag)}>{moment(book.mostRecentLog.readingDate).format("DD MMM YY")} <Icon.FileText size="16" color="#E1E1E1" className="u-m-right-5 u-m-left-2" /> {book.mostRecentLog.pageStart} - {book.mostRecentLog.pageEnd}</p>
                                            :
                                                <p className={cx(styles.bookMeta, styles.bookCompletedFlag)}>{t('no_reading_activity')}</p>
                                        }
                                        {book.students && book.students.length > 0 && <p className={cx(styles.bookMeta)}><Icon.Users size="16" color="#E1E1E1" className="u-m-right-5 u-m-left-2" /> {book.students.length} Pupil{book.students.length > 1 && 's'}</p>}
                                    </div>
                                </div>
                                {!book.completedAt &&
                                    <Tooltip
                                    className={cx(styles.bookIcon, styles.bookIconAdd)}
                                    title={t('add_new_log')}
                                    size="big"
                                    >
                                        <Icon.PlusCircle size="48" />
                                    </Tooltip>
                                }
                            </div>
                            <div className={styles.bookIcons} onClick={() => event({open: true, book: book, history: true,})}>
                                <Tooltip
                                className={cx(styles.bookIcon, styles.bookIconLogs)}
                                title={t('view_log_history')}
                                size="big"
                                >
                                    <Icon.Clipboard size="48" />
                                </Tooltip>
                            </div>
                        </div>
                    )
                }) : <p>{t('no_books_found')}</p>}
                {books.length < count && <Button onClick={() => loadMoreBooks()}>{loadingMore ? t('loading') : t('load_more')}</Button>}
            </>
            }
        </>
    )
}

export default Search