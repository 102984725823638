import React, {useState} from "react";
import styles from './parentDataContent.module.scss'
import cx from 'classnames'
import { useQuery } from "@apollo/react-hooks";
import { GET_SCHOOL_STATS } from "../services/graphql";
import ParentsByYear from "./parentsByYear";
import ParentsByClass from "./parentsByClass"
import styles2 from '../../../../Student/Student.module.scss';
import ViewAllStudents from './viewAllStudents'
const ParentDataContent = () => {
    const [actionFilter, setActionFilter] = useState('year')
    const [viewStudents, setViewStudents] = useState(false)
    const { data, error, loading } = useQuery(GET_SCHOOL_STATS);
    if (loading) {
        return <p>Fetching Data</p>
    }
    if (data) {
    return (
        <div className={styles.container}>
            <p>Percentage of students at this school that have parent accounts linked to them.</p>
            <div className={styles.heroCounters}>
                <p className="u-bold">Across the whole school</p>
                <div className={styles.heroPara}>
                    <p>Has parent account linked:</p><span className={cx(styles.heroStat, styles.dataGreen)}>{data.currentTeacher?.school?.studentsWithParentsCounter}</span>
                </div>
                <div className={styles.heroPara}>
                    <p>No parent account linked:</p> <span className={cx(styles.heroStat, styles.dataRed)}>{data.currentTeacher?.school?.studentsWithNoParentsAssociatedCounter}</span>
                </div>
                <button className={styles.heroBtn} onClick={() => setViewStudents(true)}>View Students</button>
            </div>
            <div className="u-m-top-20">
                    <button className={styles2.filter} onClick={() => setActionFilter('year')}><span className={cx(styles2.filterInner, actionFilter === 'year' ? styles2.filterInnerActive : null)}>Year Groups</span></button>
                    <button className={styles2.filter} onClick={() => setActionFilter('classes')}><span className={cx(styles2.filterInner, actionFilter === 'classes' ? styles2.filterInnerActive : null)}>Classes</span></button>
                </div>
            {
            viewStudents ?
            <ViewAllStudents setViewStudents={setViewStudents}/> :
            actionFilter === 'year' ?
                <ParentsByYear />
                :
                <ParentsByClass />
            }
        </div>
    )
    }
    return <p>Something went wrong.</p>
}

export default ParentDataContent