
export const READING_BOOK_TYPE = {
    reading: 1,
    library: 2,
    home: 3,
    group: 4
  };

export const BOOK_TYPES = [
    {type: 1, label: 'Reading Book', extended_label: 'School Reading Book'},
    {type: 2, label: 'Library Book', extended_label: 'Library Reading Book'},
    {type: 3, label: 'Home Book', extended_label: 'Home Reading Book'},
    {type: 4, label: 'Group Book (Legacy)', extended_label: 'Group Reading Book (Legacy)'},
    {type: 'NewGroup', label: 'Group Book', extended_label: 'Group Reading Book'}
]

