import React from 'react';
import cx from 'classnames';
import { Button } from '@axeedge/go-teacher-components';
import logo from '../../../../images/logos/boomreader-logo-full.svg'
import goLogo from '../../../../images/logos/go-read-logo.svg'
import studentQrImg from '../../../../images/pupil_app_url.svg';
import studentQrImgBoom from '../../../../images/pupil_app_url_boom.svg';

import styles from './PrintableStudentsList.module.scss';
import { useTranslation } from 'react-i18next';

const PrintableStudentsList = ({ school, students }) => {
    const { t } = useTranslation();

    const hostname = window && window.location && window.location.hostname;


    return (
        <div className={styles.studentsPrint}>
            <div className={cx(styles.noPrint, 'hidden-print')}>
                <div className={styles.noPrintContent}>
                    <p className='u-m-base-2'>{t('print_this_view_of_pupil_login_codes')}</p>
                    <Button onClick={() => window.print()}>{t('print')}</Button>
                </div>
            </div>

            {
                students.length > 0 ?
                    students.map(student => {
                        return (
                            <div key={`print-${student.id}`} className={cx(styles.student, styles.printSheet)}>
                                <img className={styles.studentAppLogo} src={hostname.includes('boom') ? logo : goLogo} alt='' />
                                <p className={styles.studentSchool}>{school.name}</p>
                                <h2 className={styles.studentName}>{student.name}</h2>

                                <p>{t('login_using_details_below')}</p>
                                <p className='u-m-base-3'><b>{t('keep_details_safe_secret')}</b></p>

                                <p className='u-m-base-3'>{t('scan_qr_type_address_and_use_codes_to_login')}</p>
                                <div className={styles.studentUrl}>
                                    {hostname.includes('boom') ? (
                                        <img className={styles.studentQrImg} src={studentQrImgBoom} alt='' />
                                    ) : <img className={styles.studentQrImg} src={studentQrImg} alt='' />}

                                    <div className={styles.studentUrlBody}>
                                        <strong>{hostname.includes('boom') ? 'pupils.boomhub.app' : 'pupils.goapps.app'}</strong>
                                    </div>
                                </div>
                                <p className={styles.studentCode}>{t('school_code')}: <strong>{school.schoolCode}</strong></p>
                                <p className={styles.studentCode}>{t('pin_code')} <strong>{student.loginCode}</strong></p>
                            </div>
                        )
                    })
                    : <p className='u-m-top-3 u-text-center'>{t('no_pupils_in_this_class')}</p>
            }
        </div>
    )
}

export default PrintableStudentsList;
