import React from 'react';
import styles from './bookTypeLabel.module.scss';
import { BOOK_TYPES } from '../BookSearch/services/constants';
import { useTranslation } from 'react-i18next';

const TypeLabel = ({type}) => {
    const { t } = useTranslation();

    const bookType = BOOK_TYPES.find(t => t.type === type);

    const labelClass = type => {
        switch(type) {
            case 1:
                return styles.typeLabel
            case 2:
            case 3:
                return styles.typeLabelAlt
            case 'NewGroup':
            case 4:
                return styles.typeLabelGroup
            default:
              return styles.typeLabelAlt
          }
    }

    return (
        <span className={labelClass(type)}>{t(bookType.label)}</span>
    )
}

export default TypeLabel;
