import React, { useRef } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar, getElementsAtEvent } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
);

function splitLabelForChart(label) {
    const words = label.split(' ');
    const lineLimit = 24;
    const lines = [];

    let line = '';
    let currentWordIdx = 0;

    while (currentWordIdx < words.length) {
        if (line.length + words[currentWordIdx].length < lineLimit) {
            line += `${words[currentWordIdx]} `;
            currentWordIdx++;
            if (currentWordIdx === words.length) {
                lines.push(line);
            }
        } else {
            if (line.length) {
                lines.push(line);
                line = '';
            }
            if (words[currentWordIdx].length >= lineLimit) {
                lines.push([words.currentWord]);
                currentWordIdx++;
            }
        }
    }

    return lines;
}

const EthnicityBar = ({ setShowPupils, ethnicityData }) => {

    const chartRef = useRef();

    const ethnicityDataChart = {
        labels: ethnicityData.details.map(detail => detail.name === '' ? 'Not specified' : detail.name),
        datasets: [
            {
                label: 'Number',
                ids: ethnicityData.details.map(detail => ({ name: detail.name, category: 'ethnicity' })),
                maxBarThickness: 30,
                backgroundColor: [
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 205, 86, 1)',
                    'rgba(254, 100, 132, 1)',
                    'rgba(53, 161, 235, 1)',
                    'rgba(33, 106, 157, 1)',
                ],
                borderSkipped: false,
                data: ethnicityData.details.map(detail => detail.value)
            }
        ]
    }

    const onClickBar = (event) => {
        if (getElementsAtEvent(chartRef.current, event).length > 0) {
            const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex;
            const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index;
            setShowPupils(ethnicityDataChart.datasets[datasetIndexNum].ids[dataPoint] || 'none');
        }
    }

    const options = {
        responsive: true,
        indexAxis: 'y',
        scales: {
            // x:{
            //     ticks: {
            //         stepSize: 1,
            //     }
            // },
            y: {
                ticks: {
                    autoSkip: false
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        onHover: (evt, activeEls) => {
            activeEls.length > 0 ? evt.chart.canvas.style.cursor = 'pointer' : evt.chart.canvas.style.cursor = 'default';
        }
    };


    return (
        <div className="u-m-base-4 u-m-top-4">
            <h2 className="heavy u-text-center">Ethnicity</h2>
            <Bar
                options={options}
                data={ethnicityDataChart}
                ref={chartRef}
                onClick={onClickBar}
            />
        </div>
    )
}

export default EthnicityBar;