import React from 'react';
import styles from '../../../DataDashboard.module.scss';
import cx from 'classnames';
import { ArrowDown, ArrowUp, Minus } from 'react-feather';
import { Link } from '@reach/router';

const ClassItem = ({ classData, threshold }) => {

    const { activityReport } = classData;

    return (
        <div className={styles.clsPanel}>
            <div className="card">
                <div className={cx("card-body", styles.clsStats)}>
                    <div className={styles.clsStatsPerc}>
                        <div className={styles.clsStatsPercData}>
                            <div className={styles.clsStatsPercNow}>
                                {activityReport.percentage}<span>%</span>
                            </div>
                            <div className={styles.clsStatsPercPrev}>
                                {activityReport.previousWeekPercentage < activityReport.percentage ? <ArrowUp /> : activityReport.previousWeekPercentage > activityReport.percentage ? <ArrowDown /> : <Minus size="18" />}
                                {activityReport.previousWeekPercentage}%
                            </div>
                        </div>

                        <p className='small u-text-center'>met target reads</p>
                    </div>
                    <div className={styles.clsStatsMain}>
                        <h3><Link to={`/class/${classData.id}?viewStats=true`}>{classData.name}</Link></h3>
                        <ul className={styles.clsStatsList}>
                            <li>
                                <div className={styles.clsStatsListNo}>
                                    {activityReport.studentsAboveThresholdCounter} / {activityReport.studentsTotal}
                                </div>
                                <p>{threshold}+ log</p>
                            </li>
                            <li>
                                <div className={styles.clsStatsListNo}>
                                    {activityReport.averageCounterPerStudent}
                                </div>
                                <p>avg/pupil</p>
                            </li>
                            <li>
                                <div className={styles.clsStatsListNo}>
                                    {activityReport.totalLogs}
                                </div>
                                <p>total logs</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ClassItem;