import React, { useState } from 'react';
import IsbnSearch from './components/isbnSearch';
import TitleSearch from './components/titleSearch';
import styles from './BookSearch.module.scss';
import * as Icon from 'react-feather';
import cx from 'classnames';
import { useQuery} from '@apollo/react-hooks';
import { GET_PUBLISHERS_QUERY } from './services/graphql';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const BookSearch = ({studentId, close, reset, studentIds, classInfo, setFilter, bookToExistingGroup, groupBookId = null}) => {
    const { t } = useTranslation();
    const [showSearch, setShowSearch] = useState({
        show: false,
        type: null,
        publisher: null,
    })

    const hideSearch = () => {
        setShowSearch({show: false, type: null})
    }

    const { data, loading } = useQuery(GET_PUBLISHERS_QUERY);

    return (
        <div className={styles.searchMain}>
            {loading && <p>{t('loading')}</p>}
            {data && <>
                <h2 className={styles.addTitle}>{t('add_book')}...</h2>
                <Icon.XCircle className={cx(styles.closeSearch, styles.clostSearchAlt, styles.addTitleClose)} onClick={() => close(false)}/>
                {!showSearch.show &&
                    <div className={styles.searchOptions}>
                        <button onClick={() => setShowSearch({show: true, type: "isbn", publisher: null})} className={styles.option}>
                            <Icon.Camera size="40" color="#c33764"/>
                            <h3 className={styles.optionTitle}>{t('isbn_scanner')}</h3>
                            <p>{t('use_camera_to_scan_isbn')}</p>
                        </button>
                        <button onClick={() => setShowSearch({show: true, type: "title", publihser: null})} className={styles.option}>
                            <Icon.Search size="40" color="#c33764"/>
                            <h3 className={styles.optionTitle}>{t('title_or_author_search')}</h3>
                            <p>{t('search_for_your_book_by_title_author')}</p>
                        </button>
                        {_.map(data.publishers, publisher => {
                            return <button className={styles.option} onClick={() => setShowSearch({show: true, type: "publisher", publisher: publisher})} key={`pub${publisher.id}`}>
                                <Icon.BookOpen size="40" color="#c33764"/>
                                <h3 className={styles.optionTitle}>{publisher.name}</h3>
                                <p>{t('search_collection_of_publisher_books', {publisherName: publisher.name})}</p>
                            </button>
                        })}
                    </div>
                }
                {showSearch.type === "isbn" &&
                    <IsbnSearch studentId={studentId} close={close} hide={hideSearch} reset={reset} studentIds={studentIds} classInfo={classInfo} setFilter={setFilter} bookToExistingGroup={bookToExistingGroup} groupBookId={groupBookId} />
                }
                {showSearch.type === "title" &&
                    <TitleSearch studentId={studentId} close={close} hide={hideSearch} reset={reset} studentIds={studentIds} classInfo={classInfo} setFilter={setFilter} bookToExistingGroup={bookToExistingGroup} groupBookId={groupBookId} />
                }
                {showSearch.type === "publisher" &&
                    <TitleSearch studentId={studentId} close={close} hide={hideSearch} reset={reset} publisher={showSearch.publisher} studentIds={studentIds} classInfo={classInfo} setFilter={setFilter} bookToExistingGroup={bookToExistingGroup} groupBookId={groupBookId} />               
                }
                </>
            }
        </div>
    )
}

export default BookSearch;