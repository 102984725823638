import React, { useState } from 'react';
import styles from './BandingInfo.module.scss';
import { useMutation } from '@apollo/react-hooks';
import { Trans } from 'react-i18next';
import { Modal, Button } from '@axeedge/go-teacher-components';
import { ArrowLeft, Edit } from 'react-feather';

import { useTranslation } from 'react-i18next';
import EditBandRank from './EditBandRank';
import SortableBand from './SortableBand';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';

import {
    ORDER_READING_BAND_RANKS,
    GET_READING_BANDS,
    EDIT_READING_BAND_RANK,
    ADD_READING_BAND_RANK,
    EDIT_READING_BAND
} from './services/graphql';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const EditBand = ({ band, closeEdit }) => {

    const [formErrors, setFormErrors] = useState(false);
    const { t } = useTranslation();

    const [bandState, setBandState] = useState(band);
    const [addRank, setAddRank] = useState(false);
    const [tempRank, setTempRank] = useState(null);
    const [editBand, setEditBand] = useState(false);

    const sensors = useSensors(
        useSensor(TouchSensor),
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [orderReadingBandRanks] = useMutation(ORDER_READING_BAND_RANKS, {
        update: (_, { data }) => {
            if (data.orderReadingBandRanks.errors && data.orderReadingBandRanks.errors.length !== 0) {
                setFormErrors(true);
                return;
            }
        },
        refetchQueries: () => [{ query: GET_READING_BANDS }]
    });

    const getRGB = (str) => {
        var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        return match ? {
            r: match[1],
            g: match[2],
            b: match[3]
        } : {};
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            let oldIndex = bandState.ranks.findIndex(el => el.id === active.id);
            let newIndex = bandState.ranks.findIndex(el => el.id === over.id);
            let newRanks = arrayMove(bandState.ranks, oldIndex, newIndex);
            setBandState({ ...bandState, ranks: newRanks });
            orderReadingBandRanks({
                variables: {
                    readingBandId: band.id,
                    orderedIds: newRanks.map(el => el.id)
                }
            });
        }
    }

    const [editReadingBandRank] = useMutation(EDIT_READING_BAND_RANK, {
        update: (_, { data }) => {
            if (data.editReadingBandRank.errors && data.editReadingBandRank.errors.length !== 0) {
                setFormErrors(true);
                return;
            }
            if (data.editReadingBandRank.readingBandRank && data.editReadingBandRank.readingBandRank.id) {
                setTempRank(null);
                const updatedRank = data.editReadingBandRank.readingBandRank
                const newRanks = []
                bandState.ranks.forEach(rank => {
                    if (rank.id === updatedRank.id) {
                        newRanks.push(updatedRank)
                    } else {
                        newRanks.push(rank)
                    }
                })
                setBandState({ ...bandState, ranks: newRanks });
            }
        }
    });

    const [addReadingBandRank] = useMutation(ADD_READING_BAND_RANK, {
        update: (_, { data }) => {
            if (data.addReadingBandRank.errors && data.addReadingBandRank.errors.length !== 0) {
                setFormErrors(true);
                return;
            }
            if (data.addReadingBandRank.readingBand && data.addReadingBandRank.readingBand.id) {
                setAddRank(null);
                setBandState({ ...bandState, ranks: data.addReadingBandRank.readingBand.ranks });
            }
        }
    });

    const [editReadingBand] = useMutation(EDIT_READING_BAND, {
        update: (_, { data }) => {
            if (data.editReadingBand.errors && data.editReadingBand.errors.length !== 0) {
                setFormErrors(true);
                return;
            }
            if (data.editReadingBand.readingBand && data.editReadingBand.readingBand.id) {
                setEditBand(null);
                setBandState({ ...bandState, name: data.editReadingBand.readingBand.name });
            }
        }
    });

    const schema = Yup.object().shape({
        name: Yup.string().required(t('please_enter_a_name')),
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = values => {
        const { name } = values;
        editReadingBand({
            variables: {
                name,
                readingBandId: bandState.id
            }
        })
    }

    return (
        <>
            <div className={styles.bandRankHeader}>
                <button className='btn-reset' onClick={closeEdit} ><ArrowLeft /></button>
                <h3>{bandState.name} <Edit size="18" className={styles.bandRankHeaderEdit} onClick={() => setEditBand(true)} /></h3>
            </div>

            <div className={styles.bandEditCols}>
            <div className={styles.bandEditColsSide}>
                <div className={styles.bandEditInfo}>
                    <h4 className='u-m-base-2'>{t('edit_this_band_set')}:</h4>
                    <p className='u-m-base-2'>{t('you_can_add_edit_reorder')}</p>
                    <p><b>{t('please_note_changes_immediately_visible')}</b></p>
                </div>
                </div>
                <div className={styles.bandEditColsMain}>

                    {formErrors && <p className="u-m-base-10 error smaller-text">
                        <Trans i18nKey="something_went_wrong_contact_us">
                            Something went wrong, please contact <a href="mailto:goapps@squirrellearning.co.uk">goapps@squirrellearning.co.uk</a> for support.
                                </Trans>
                    </p>}
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={bandState.ranks.map(el => el.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <ul>
                                {
                                    bandState.ranks.map((rank, i) => (
                                        <SortableBand
                                            key={`rank${rank.id}`}
                                            rank={rank}
                                            i={i}
                                            editRank={() => setTempRank({ name: rank.name, ...getRGB(rank.colourCode), id: rank.id })}
                                        />
                                    ))
                                }
                            </ul>
                            <Button onClick={() => setAddRank({
                                name: '',
                                r: 0,
                                g: 0,
                                b: 0
                            })}>Add</Button>
                        </SortableContext>
                    </DndContext>


                    {editBand && (
                        <Modal closeModal={() => { setEditBand(null) }}>
                            <h3 className='u-m-base-3'>{t('edit_band_name')}</h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="basic-form__group ">
                                    <input
                                        name="name"
                                        className="basic-form__text-box"
                                        ref={register}
                                        placeholder={t('enter_band_name')}
                                        defaultValue={bandState.name}
                                    />
                                </div>
                                {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                                <Button type='submit' className='u-m-right-1'>{t('button:save')}</Button>
                                <Button type="button" outline onClick={() => setEditBand(null)}>{t('button:cancel')}</Button>
                            </form>
                        </Modal>
                    )}

                    {tempRank && (
                        <Modal closeModal={() => { setTempRank(null) }}>
                            <EditBandRank
                                formErrors={formErrors}
                                update={editReadingBandRank}
                                rank={tempRank}
                                cancel={() => setTempRank(null)}
                            />
                        </Modal>
                    )}

                    {addRank && (
                        <Modal closeModal={() => { setAddRank(null) }}>
                            <EditBandRank
                                formErrors={formErrors}
                                update={addReadingBandRank}
                                rank={addRank}
                                bandId={bandState.id}
                                cancel={() => setAddRank(null)}
                            />
                        </Modal>
                    )}
                </div>
            </div>
        </>
    )
}

export default EditBand;