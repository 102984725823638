import React, { useState, createContext } from 'react';

export const ClassFilterContext = createContext();

export const ClassFilterProvider = ({ children }) => {
    const [sortType, setSortType] = useState('az');
    const [viewType, setViewType] = useState('lastRead');
    const [intervalStats, setIntervalStats] = useState({});
    const [sortOrder, setSortOrder] = useState('desc');
    const [classAnalyticsFilters, setClassAnalyticsFilters] = useState([]);
    const [pupilCsvExport, setPupilCsvExport] = useState(null);

    return (
        <ClassFilterContext.Provider
            value={{
                sortType,
                setSortType,
                viewType,
                setViewType,
                intervalStats,
                setIntervalStats,
                sortOrder,
                setSortOrder,
                classAnalyticsFilters,
                setClassAnalyticsFilters,
                pupilCsvExport,
                setPupilCsvExport
            }}
        >
            {children}
        </ClassFilterContext.Provider>
    )
}