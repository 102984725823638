import React from 'react';
import { GET_BOOK_REVIEW } from '../services/graphql';

import { useQuery } from '@apollo/react-hooks';
import ReviewAcknowledgement from './ReviewAcknoledgement';
import { useTranslation } from 'react-i18next';
import starRating from '../../../images/star-filled.svg';
import styles from '../Student.module.scss';

const StudentBookReview = ({ book, student, teacher, classId }) => {

    const { t } = useTranslation();

    const { data } = useQuery(GET_BOOK_REVIEW, {
        variables: {
            id: book.id
        },
    });

    const getStars = reviewRate => {
        return Array.from({ length: reviewRate }, (_, i) => i)
    }

    if (data && data.studentBook && data.studentBook.book && data.studentBook.book.reviews && data.studentBook.book.reviews.length > 0) {
        const { studentBook } = data;
        const review = studentBook.book.reviews.filter(review => review.author && review.author.id === student.id)[0]
        
        if (!review) {
            return null;
        }

        return (
            <>
                <div>
                    <span className={styles.reviewHeader}>{t('review_by_name', { studentName: student.firstName })}</span>
                    <ReviewAcknowledgement book={book} teacher={teacher} classId={classId} review={review} />
                </div>
                <div className={styles.reviewBlock}>
                    <ul className={styles.reviewImg}>
                        {getStars(review.reviewRate).map(star => <li className='u-m-right-1' key={`img-${star}`}><img src={starRating} alt='' /></li>)}
                    </ul>
                    <div className={styles.reviewBody}>
                        <p>{review.reviewText}</p>
                    </div>
                </div>
            </>
        )
    }
    return null;
}

export default StudentBookReview;