import React from 'react';
import styles from './JobRole.module.scss';
import { Button } from '@axeedge/go-teacher-components';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

const JobRoleCheck = () => {

    return (
        <div className={styles.modal}>
            <div className={styles.modalBlanker}>
                <div className={styles.modalContent}>
                    <h3 className='heavy u-m-base-3'>Please select your school role:</h3>
                    <p className='u-m-base-3'>Sorry for the inconvience but before you can continue we need to ask you to select your role within this school.</p>
                    <a href={`${getUrl(APPS.go_portal_teacher)}`}><Button>Set school role</Button></a>
                </div>
            </div>
        </div>
    )
}

export default JobRoleCheck;