import { gql } from 'apollo-boost';

export const GET_READING_BANDS = gql`
query getCurrentTeacher {
    currentTeacher {
        id
        school {
            id
            readingBands {
                id
                name
                ranks {
                    id
                    name
                    colourCode
                }
            }
        }
    }
}
`;

export const DELETE_READING_BAND = gql`
mutation deleteReadingBand(
        $readingBandId: ID!
    ) {
        deleteReadingBand(readingBandId: $readingBandId) {
        deleted
        errors
    }
}
`;

export const ORDER_READING_BAND_RANKS = gql`
mutation orderReadingBandRanks(
        $readingBandId: ID!, $orderedIds: [ID!]!
    ) {
        orderReadingBandRanks(readingBandId: $readingBandId, orderedIds: $orderedIds) {
        readingBand {
            id
            name
            ranks {
                id
                name
                colourCode
            }
        }
        errors
        
    }
}
`;

export const EDIT_READING_BAND_RANK = gql`
mutation editReadingBandRank(
        $readingBandRankId: ID!, $name: String!, $colourCode: String!
    ) {
        editReadingBandRank(readingBandRankId: $readingBandRankId, name: $name, colourCode: $colourCode) {
        readingBandRank {
            id
            name
            colourCode
        }
        errors
    }
}
`;

export const EDIT_READING_BAND = gql`
mutation editReadingBand($readingBandId: ID!, $name: String!) {
    editReadingBand(readingBandId: $readingBandId, name: $name) {
            readingBand {
                id
                name
                ranks {
                    id
                    name
                    colourCode
                }
            }
        errors
    }
}
`;

export const ADD_READING_BAND_RANK = gql`
mutation addReadingBandRank(
        $readingBandId: ID!, $name: String!, $colourCode: String!
    ) {
        addReadingBandRank(readingBandId: $readingBandId, name: $name, colourCode: $colourCode) {
        readingBand {
            id
            ranks {
                id
                name
                colourCode
            }
        }
        errors
    }
}
`;