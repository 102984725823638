import React from "react";

const WebinarBanner = () => {
    return(
        <div className='card card-shadow u-m-base-4'>
            <div className="card-body u-flex-center">
                <p className="u-bold u-m-right-2">Talk to us to get the most out of reading data - Reserve a place on one of our webinars </p>
                <a href="https://www.boomreader.co.uk/webinars" className='btn-outline u-m-left-auto' target='_blank' rel="noopener noreferrer">Book now</a>
            </div>
        </div>
    )
}

export default WebinarBanner;