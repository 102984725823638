import React from 'react'
import cx from 'classnames'
import styles from '../../../DataDashboard/scenes/ParentData/components/parentDataContent.module.scss'
const ParentData = ({students, classInfo}) => {
    return (
        <div className={styles.container}>
            <div className={styles.heroCounters}>
                <p className="u-bold">Parent Data Across This Class</p>
                <div className={styles.heroPara}>
                    <p>Has parent account linked:</p><span className={cx(styles.heroStat, styles.dataGreen)}>{classInfo.studentsWithParentsCounter}</span>
                </div>
                <div className={styles.heroPara}>
                    <p>No parent account linked:</p> <span className={cx(styles.heroStat, styles.dataRed)}>{classInfo.studentsWithNoParentsAssociatedCounter}</span>
                </div>
            </div>
            <div className={cx('u-m-top-4', styles.studentsHeader)}>
                <p>Students in this class <span className="u-bold">without</span> associated parent accounts.</p>
            </div>

            <ul className="u-m-top-4">
                <li className={styles.studentsHeaderItem}>Student Name<span>UPN Number</span></li>
                {students.filter(s => s.parentsCounter === 0).map(s => {
                    return(
                        <li key={s.id} className={styles.studentItem}><span>{s.firstName} {s.lastName}</span>{s.upn}</li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ParentData