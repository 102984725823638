import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CheckCircle } from 'react-feather';
import ImageFadeIn from 'react-image-fade-in';

import { Button } from '@axeedge/go-teacher-components';
import { AWARD_GEM_MUTATION } from './services/graphql';

import gem from './images/gem.svg';
import styles from './GemButton.module.scss';
import { useTranslation } from 'react-i18next';

const GemButton = ({ fullWidth, studentId, plus = false, }) => {

    const { t } = useTranslation();

    const [gemAwarded, setGemAwarded] = useState(false);

    const [awardGem, { loading }] = useMutation(AWARD_GEM_MUTATION, {
        variables: {
            studentId
        },
        onCompleted: ({ addRewardGemToStudent }) => {
            if (addRewardGemToStudent.errors && addRewardGemToStudent.errors.length !== 0) {
                alert(t('error_awarding_gem'));
                return;
            }
            if (addRewardGemToStudent.student && addRewardGemToStudent.student.id) {
                setGemAwarded(true);
            }
        }
    });

    return (
        <Button
            className={styles.gemButton}
            onClick={() => !gemAwarded && awardGem()}
        >
            <ImageFadeIn src={gem} alt='' className={styles.gemButtonImg} />
            {
                gemAwarded ? <CheckCircle className={styles.gemButtonConfirmed} /> : loading ? '...' : plus ? '+1' : t('award_bonus_gem')
            }
        </Button>
    )
}

export default GemButton;

