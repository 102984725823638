import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import { Link } from '@reach/router';
import * as Icon from 'react-feather';
import { GET_READING_BANDS, DELETE_READING_BAND } from './services/graphql';
import _ from 'lodash';
import styles from './BandingInfo.module.scss';
import styles2 from '../../scenes/Banding/Banding.module.scss';
import cx from 'classnames'
import { Button } from '@axeedge/go-teacher-components';
import { useTranslation, Trans } from 'react-i18next';

import EditBand from './EditBand';

const BandingInfo = () => {

    const { t } = useTranslation();
    const { data, error, loading } = useQuery(GET_READING_BANDS);

    const [editBand, setEditBand] = useState();

    const [deletePanel, setDeletePanel] = useState({
        band: null,
        show: false,
    })

    const [formErrors, setFormErrors] = useState(false);

    const [deleteBand, { loading: delLoading, error: delError }] = useMutation(DELETE_READING_BAND, {
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if (data.deleteReadingBand.errors && data.deleteReadingBand.errors.length !== 0) {
                setFormErrors(true);
                return;
            }
            if (data.deleteReadingBand.deleted) {
                setDeletePanel({ band: null, show: false, })
            }
        },
        refetchQueries: ['studentsClass', 'getCurrentTeacher']
    });

    if (editBand) {
        return <EditBand band={editBand} closeEdit={() => setEditBand(null)} />
    }

    return (
        <div className={styles.bandsWrapper}>
            <div className={styles.panel}>
                <h3 className={cx(styles.panelHeading, "u-m-base-20")}><Icon.Book size="34" className={styles.panelHeadingIcon} />Reading Bands</h3>
                {loading && <p>{t('loading')}</p>}
                {error && <p>{error.message}</p>}
                {data &&
                    <>
                        {data.currentTeacher.school.readingBands.length === 0 ?
                            <p className="u-m-base-20">{t('you_dont_have_reading_bands_set_up')}.</p>
                            :
                            <ul className="u-m-base-20">
                                {_.map(data.currentTeacher.school.readingBands, band => {
                                    return <li key={`band${band.id}`} className={styles.bandLink}>
                                        <span className={styles.bandLinkTitle} onClick={() => setEditBand(band)}>{band.name}</span>
                                        <Icon.XCircle className={styles.bandLinkDelete} onClick={() => setDeletePanel({ show: true, band: band })} />
                                    </li>
                                })}
                            </ul>
                        }
                        <Link to="/banding" className="button">{t('add_reading_band')}</Link>
                    </>
                }
            </div>
            {deletePanel.show &&
                <div className={styles2.modalBg} onClick={() => setDeletePanel({ show: false, band: null })}>
                    <div className={cx(styles2.modalInner, styles2.modalInnerAssign)} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.deleteInner}>
                            <h1 className="u-m-base-10 title">{t('delete_reading_band')}</h1>
                            <p className="smaller-text u-m-base-10">{t('are_you_sure_delete_band_action_cannot_be_undone')}.</p>
                            <p className="u-m-base-10">
                                <Trans i18nKey="delete_band_name">
                                    Delete <span className="u-bold">{{ bandName: deletePanel.band.name }}</span>
                                </Trans>
                            </p>

                            {(formErrors || delError) && <p className="u-m-base-10 error smaller-text">
                                <Trans i18nKey="something_went_wrong_contact_us">
                                    Something went wrong, please contact <a href="mailto:goapps@squirrellearning.co.uk">goapps@squirrellearning.co.uk</a> for support.
                                </Trans>
                            </p>}
                            <Button className="u-m-right-5" onClick={() => {
                                deleteBand({
                                    variables: {
                                        readingBandId: deletePanel.band.id
                                    }
                                })
                            }} disabled={delLoading && true}>{delLoading ? t('deleting') : t('button.yes')}</Button>
                            <Button outline onClick={() => setDeletePanel({ show: false, band: null })}>{t('button.no')}</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default BandingInfo;