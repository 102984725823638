import { gql } from 'apollo-boost';

export const DELETE_READING_BOOK_MUTATION = gql`
mutation deleteReadingBookFromStudent($readingBookId: ID!, $studentId: ID!) {
    deleteReadingBookFromStudent(readingBookId: $readingBookId, studentId: $studentId) {
        deleted
        errors
    }
}
`;

export const DELETE_READING_BOOK_FROM_CLASS = gql`
mutation deleteReadingBookFromClass($readingBookId: ID!, $studentsClassId: ID!) {
    deleteReadingBookFromClass(readingBookId: $readingBookId, studentsClassId: $studentsClassId) {
        deleted
        errors
    }
}
`;

export const EDIT_STUDENT_READING_BOOK = gql`
mutation editStudentReadingBook($studentReadingBookId: ID!, $bookType: Int, $hideComments: Boolean) {
    editStudentReadingBook(studentReadingBookId: $studentReadingBookId, bookType: $bookType, hideComments: $hideComments) {
        errors
        studentReadingBook {
            id
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            mostRecentLog {
                id
                date
                isCompletion
                readingDate
                entry
                author {
                    id
                    name
                    role
                    displayName
                }
                book {
                    id
                    bookType
                    book {
                        id
                        title
                    }
                }
                problemWords {
                    id
                    word
                }
                pageStart
                pageEnd
            }
            status
            completedAt
            createdAt
            hideComments
            isReread
            bookType
        }
    }
}
`;

export const UNCOMPLETE_STUDENT_READING_BOOK = gql`
mutation uncompleteStudentReadingBook($studentReadingBookId: ID!) {
    uncompleteStudentReadingBook(studentReadingBookId: $studentReadingBookId) {
        errors
        studentReadingBook {
            id
            book {
                id
                coverUrl
                title
                author
                publisher {
                    id
                    name
                }
                isbn
                isbn13
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            mostRecentLog {
                id
                date
                isCompletion
                readingDate
                entry
                author {
                    id
                    name
                    role
                    displayName
                }
                book {
                    id
                    bookType
                    book {
                        id
                        title
                    }
                }
                problemWords {
                    id
                    word
                }
                pageStart
                pageEnd
            }
            status
            completedAt
            createdAt
            hideComments
            isReread
            bookType
        }
    }
}
`;