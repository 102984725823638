import React, { useEffect, useState } from 'react';
import { Button, Loader } from '@axeedge/go-teacher-components';
import RequestDataForm from './RequestDataForm';
import { ACTIVITY_LABELS } from '../../../../utils/constants';
import { useLazyQuery } from '@apollo/react-hooks';
import { AlertCircle, Download } from 'react-feather';
import { GET_PUPIL_EXPORTS } from './services/graphql';
import moment from 'moment';
import styles from './DataDownload.module.scss';
import axios from 'axios';

const DataDownload = ({ student, backToStudent, setShowDataForm, showDataForm, userPermissions }) => {

    const [pupilExportTickets, setPupilExportTickets] = useState(null);
    const [requestDone, setRequestDone] = useState(false);


    const [getExports, { loading, error }] = useLazyQuery(GET_PUPIL_EXPORTS, {
        fetchPolicy: 'network-only',
        variables: {
            id: student.id
        },
        onCompleted: (data) => {
            setPupilExportTickets(data.student.exportDataTickets);
        }
    });

    useEffect(() => {
        if (!pupilExportTickets && !showDataForm) {
            getExports();
        }
    }, [pupilExportTickets, showDataForm]);

    const downloadFile = ticket => {
        axios({
            method: 'get',
            url: ticket.exportUrl,
            responseType: 'blob'
        }).then((response => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
            let link = document.createElement('a');
            link.href = downloadUrl;
            const fileName = `${ticket.requestedBy.name}-${ticket.student.name}`;
            link.setAttribute('download', fileName + '.csv');
            document.body.appendChild(link);
            link.click();
        }));
    }


    const getFilterLabels = filters => {
        let labels = [];
        if (filters.length === 9) {
            labels.push('All');
        } else {
            filters.forEach(el => {
                const found = ACTIVITY_LABELS.filter(f => f.types.includes(el))[0];
                if (found && labels.indexOf(found.label) === -1) {
                    labels.push(found.label);
                }
            });
        }
        return labels.join(', ');
    }

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }


    return (
        <>
            {
                showDataForm ?
                    <RequestDataForm
                        close={() => setShowDataForm(false)}
                        student={student}
                        setRequestDone={setRequestDone}
                        userPermissions={userPermissions}
                    /> :
                    <>
                        {requestDone ? (
                            <>
                                <h3>Preparing your file</h3>
                                <div className={styles.panel}>
                                    <p className='u-m-base-2'>We are preparing your file - we will nofity you once it's ready for download. Once it's ready, you will also be able to access it for 30 days from the "Manage Downloads" link in the Pupil Feed page.</p>
                                    <Button outline onClick={backToStudent}>Done</Button>
                                </div>
                            </>
                        ) : pupilExportTickets &&
                        <div className={styles.content}>
                            <div className='u-display-flex u-justify-between u-align-center'>
                                <h3 className='u-m-base-2'>Available downloads</h3>
                                <Button onClick={() => setShowDataForm(true)}>Request CSV file</Button>
                            </div>
                            {pupilExportTickets.length > 0 ? (
                                <ul className={styles.downloads}>
                                    {pupilExportTickets.map(ticket => (
                                        <li key={`item-${ticket.id}`}>
                                            <div className={styles.downloadsHeader}>
                                                {ticket.status === 1 ?
                                                    <p className={styles.downloadsTitle}>
                                                        <button className='btn-reset' onClick={() => downloadFile(ticket)}><Download size="16" /> Requested by {ticket.requestedBy.name} </button>
                                                        {/* <a href={ticket.exportUrl} download={`${ticket.requestedBy.displayName}.csv`}><Download size="16" /> Requested by {ticket.requestedBy.name} </a> */}
                                                    </p> :
                                                    <p className={styles.downloadsTitle}>
                                                        <span><AlertCircle size="20" style={{ color: 'red' }} />   Requested by {ticket.requestedBy.name} - There was an error processing the file, please contact <a href="mailto:goapps@squirrellearning.co.uk">goapps@squirrellearning.co.uk</a> for support.</span>
                                                    </p>
                                                }
                                            </div>
                                            <p className='small'>
                                                Includes: {getFilterLabels(ticket.activityType)}
                                            </p>
                                            <p className='small'>From {moment(ticket.from).format('DD/MM/YYYY')} to {moment(ticket.to).format('DD/MM/YYYY')}</p>
                                        </li>
                                    ))}
                                </ul>
                            ) : <p>No data downloads</p>}
                        </div>
                        }
                    </>
            }
        </>
    )

}

export default DataDownload;