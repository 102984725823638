import React, {useState, useEffect} from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import cx from 'classnames';
import * as Icon from 'react-feather'
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@axeedge/go-teacher-components';

import { GET_GROUPS } from './services/graphql';
import styles from '../../../Student/Components/Search.module.scss'
import styles2 from '../../../Student/Student.module.scss';

import AddLog from '../../../../components/AddLog'
import { RB_COMPLETE, RB_NEW, RB_ACTIVE } from '../../../../utils/constants';
import BookSearch from '../../../../components/BookSearch';

const Groups = ({ classId, classInfo }) => {

    const [selectedBook, setSelectedBook] = useState({
        open: false,
        book: null,
    });

    const [completeConfirmation, setCompleteConfirmation] = useState(false);
    const [initiateComplete, setInitiateComplete] = useState(false);
    const [books, setBooks] = useState([])
    const [actionFilter, setActionFilter] = useState("ALL");
    const [classData, setClassData] = useState(null);

    const [addNewBookToGroup, setAddNewBookToGroup] = useState(null);
    const [groupBookId, setGroupBookId] = useState(null)

    /*const { data, error, loading } = useQuery(GET_GROUPS, {
        variables: {
            id: classId,
            status: [RB_NEW, RB_ACTIVE],
        },
        fetchPolicy: 'cache-and-network'
    });*/

    const [fetchBooks, { data, loading, error }] = useLazyQuery(GET_GROUPS, {
        onCompleted: data => {
            setBooks(data.studentsClass.groups);
            setClassData({
                name: data.studentsClass.name,
                students: data.studentsClass.students
            })
        }
    });

    useEffect(() => {
        fetchBooks({variables: {
            id: classId,
            status: actionFilter === 'ALL' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE],
        }});
    }, [actionFilter]);
    
    useEffect(() => {
        if (data && data.studentsClass && data.studentsClass.groups !== books) {
            setBooks(data.studentsClass.groups);
            if (selectedBook.open) {
                let newBooks = getBooks(data.studentsClass.groups);
                setSelectedBook({
                    book: newBooks.find(x => x.id === selectedBook.book.id),
                    open: true
                })
            }
        }
    }, [data])

    const { t } = useTranslation();

    const getBooks = (groups, status) => {
        const books = [];
        groups.forEach(group => {
            if(group.books.length > 0) {
                group.books.forEach(book => {
                    books.push(book)
                })
            }
        })
        return books
    }

    const closeBook = () => {
        /*setShowSuccess(false);
        setShowCompleteLogSuccess(false);
        setShowLogSucces(false);
        setShowLogDeleteSuccess(false);
        setShowEditSuccess(false)*/
        setInitiateComplete(false)
        setSelectedBook({book: null, open: false})
    }

    if (error) {
        return <p>Oops, something went wrong&hellip;</p>
    }

    return (
            <>
                {addNewBookToGroup ? (
                   <BookSearch close={() => setAddNewBookToGroup(null)} bookToExistingGroup={addNewBookToGroup} classInfo={classInfo} setFilter={setActionFilter} groupBookId={groupBookId}  />
                ) :
                <>
                    <div className="u-m-base-20">
                        <button className={styles2.filter} onClick={() => setActionFilter('ALL')}><span className={cx(styles2.filterInner, actionFilter === 'ALL' ? styles2.filterInnerActive : null)}>Reading</span></button>
                        <button className={styles2.filter} onClick={() => setActionFilter('COMPLETE')}><span className={cx(styles2.filterInner, actionFilter === 'COMPLETE' ? styles2.filterInnerActive : null)}>Complete</span></button>
                    </div>
                    {loading && <p>Loading...</p>}
                    {data && getBooks(books).map(book => {
                        return (
                            <div key={`book${book.id}`} className={styles.book}>
                            <div className={styles.bookDetails} onClick={() => {setSelectedBook({open: true, book: book, history: false,});setGroupBookId(book.id)}}>
                                <div>
                                    <h1 className={styles.bookTitle}>{book.book.title}</h1>
                                    <h2 className={styles.bookGroup}>{book.group.name}</h2>
                                    <div className={styles.bookMeta}>
                                        <span className={styles.groupLabel}>Group Book</span>
                                        {book.completedAt ? <p className={cx(styles.bookMeta, styles.bookCompletedFlag)}><Icon.CheckCircle size="16" className="u-m-right-5" />{moment(book.completedAt).format("DD MMM YY")}</p> : 
                                            book.mostRecentLog ?
                                                <p className={cx(styles.bookMeta, styles.bookCompletedFlag)}>{moment(book.mostRecentLog.readingDate).format("DD MMM YY")} <Icon.FileText size="16" color="#E1E1E1" className="u-m-right-5 u-m-left-2" /> {book.mostRecentLog.pageStart} - {book.mostRecentLog.pageEnd}</p>
                                            :
                                                <p className={cx(styles.bookMeta, styles.bookCompletedFlag)}>{t('no_reading_activity')}</p>
                                        }
                                        <p className={cx(styles.bookMeta)}><Icon.Users size="16" color="#E1E1E1" className="u-m-right-5 u-m-left-2" /> {book.students.length} Pupil{book.students.length > 1 && 's'}</p>
                                    </div>
                                </div>
                                {!book.completedAt &&
                                    <Tooltip
                                    className={cx(styles.bookIcon, styles.bookIconAdd)}
                                    title={t('add_new_log')}
                                    size="big"
                                    >
                                        <Icon.PlusCircle size="48" />
                                    </Tooltip>
                                }
                            </div>
                            <div className={styles.bookIcons} onClick={() => setSelectedBook({open: true, book: book, history: true,})}>
                                <Tooltip
                                className={cx(styles.bookIcon, styles.bookIconLogs)}
                                title={t('view_log_history')}
                                size="big"
                                >
                                    <Icon.Clipboard size="48" />
                                </Tooltip>
                            </div>
                        </div>
                        )
                    })}
                    {selectedBook.open && <div onClick={() => closeBook()} className={styles2.bookBG}></div>}
                        <div className={cx(styles2.bookModal,selectedBook.open && styles2.bookModalOpen)}>
                            {selectedBook.book &&
                                <AddLog setAddNewBookToGroup={setAddNewBookToGroup} classData={classData} selectedBook={selectedBook} type={4} setSelectedBook={setSelectedBook} toggleComplete={setCompleteConfirmation} initiateCompleteGroup={initiateComplete} classId={classId} filter={actionFilter === 'ALL' ? [RB_NEW, RB_ACTIVE] : [RB_COMPLETE]} />
                            }
                        </div>
                    {selectedBook.open && <Icon.XCircle onClick={() => closeBook()} className={styles2.closeBook}/> }
                    {completeConfirmation &&
                        <Modal closeModal={() => {setCompleteConfirmation(false)}}>
                            <p>Are you sure you want to mark this book as complete?</p>
                            <Button onClick={() => {setInitiateComplete(true);setCompleteConfirmation(false)}} className="u-m-top-20 u-m-right-10">Yes</Button>
                            <Button outline onClick={() => {setCompleteConfirmation(false)}}>No</Button>
                        </Modal>
                    }
                </>
            }
            </>
        )

}

export default Groups