import React, { useState } from "react";
import { monthNames, radiusOpts, lineColors } from "../../../services/utils";
import Tab from '../../../../../components/Tab';
import Tabs from '../../../../../components/Tabs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const CompareByDemographyContent = ({ reportData }) => {

    const [currentTab, setCurrentTab] = useState('total');

    const titleCase = str => str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()).split('_').join(' ');

    const renderLegend = data => {
        return data.map((element, i) => {
            return (
                <div key={`filterset-${i}`}>
                    <li style={{ background: '#f2f2f2' }} className="u-p-2 u-display-flex u-align-center u-m-base-1 ">
                        <span className="u-m-right-2 u-text-white u-p-1 u-p-top-0 u-p-base-0" style={{ background: lineColors[i % lineColors.length]?.backgroundColor }}>
                            Set {i + 1}
                        </span>
                        <p>
                            {element.filters.map((filter,i) => (
                                <span key={`filter-${i}`}>
                                    <span className="u-bold u-m-right-1">{titleCase(filter.demographicsCategory)}</span>
                                    <span className="u-m-right-1">{filter.values.map(value => value || 'Not specified').join(', ')}</span>
                                </span>
                            ))}
                        </p>
                    </li>
                </div>
            )
        });
    }

    const getLabels = (item) => {
        const monthNumbers = item.results.map(result => result.month);
        return monthNumbers?.map(month => monthNames[month - 1]);
    }

    const chartData = {
        labels: getLabels(reportData[0]),
        datasets: [
            ...reportData.map((filterSet, i) => {
                return {
                    label: `Set ${i + 1}`,
                    data: filterSet.results.map(result => currentTab === 'total' ? result.data.totalReadingLogs : result.data.totalReadingBooksCompleted),
                    ...lineColors[i % lineColors.length],
                    ...radiusOpts
                }
            })
        ]
    }


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                offset: false
            }
        }
    };

    return (
        <>
            <div className="u-m-base-2 u-m-top-3">
                {renderLegend(reportData)}
            </div>
            <Tabs className='u-m-base-3 u-m-top-3'>
                <Tab selected={currentTab === 'total'} onClick={() => setCurrentTab('total')}>Reading logs</Tab>
                <Tab selected={currentTab === 'books'} onClick={() => setCurrentTab('books')}>Books completed</Tab>
                <span className="small u-text-muted u-m-left-auto">Data is calculated at the end of each day</span>
            </Tabs>
            <Line options={options} data={chartData} />
        </>
    )
}

export default CompareByDemographyContent;