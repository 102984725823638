import React, { useContext } from 'react';
import cx from 'classnames';
import { Tooltip } from 'react-tippy'
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

import styles from '../../ClassNew/components/StudentTileList/StudentTileList.module.scss'
import * as Icon from 'react-feather'
import { AuthContext } from '../../../services/auth/AuthProvider';

const DataDashNav = () => {

    const { currentUser } = useContext(AuthContext);

    const { t } = useTranslation();

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: cx(styles.searchBarFilter, styles.searchBarFilterActive) } : { className: styles.searchBarFilter }
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: cx(styles.searchBarFilter, styles.searchBarFilterActive) } : { className: styles.searchBarFilter }

    }

    return (
        <>
            <div className="page-header u-m-base-0">
                <div className='page-header__content'>
                    <h1 className="page-header__content__title">{t('data_dashboard')}</h1>
                </div>
            </div>
            <div className={cx(styles.classActions)}>
                <div className={styles.classActionsMain}>
                    <Tooltip title="Reading Data" size="big">
                        <Link to='/dataDashboard' getProps={isPartiallyActive}><Icon.BarChart2 />Data</Link>
                    </Tooltip>


                    <Tooltip title="Reading Targets" size="big">
                        <Link to='/dataReadingTargets' getProps={isActive}><Icon.Activity />Reading Targets
                        </Link>
                    </Tooltip>
                    <Tooltip title="Parent Data" size="big">
                        <Link to='/dataParent' getProps={isActive}><Icon.Home />Parent Data</Link>
                    </Tooltip>
                </div>
            </div>
        </>
    )
}

export default DataDashNav;