import React, { useContext, useState } from "react";
import Demographics from "./components/Demographics";
import { Button, Loader } from "@axeedge/go-teacher-components";
import ReadingLogsLineChart from "./components/ReadingLogsLineChart";
import Top20Books from "./components/Top20Books";
import AccountStats from "./components/AccountsStats";
import compareItemsImg from '../../../../images/data/compareItems.svg';
import compareDemographicsImg from '../../../../images/data/compareDemographicsImg.svg';
import CompareData from "./components/CompareData";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import DataDashNav from "../../components/DataDashNav";
import { X } from "react-feather";
import { useQuery } from "@apollo/react-hooks";
import { YEAR_GROUP_DATA, GET_SCHOOL_YEAR_GROUPS } from "../../services/graphql";
import WebinarBanner from "./components/WebinarBanner";
import moment from "moment";
import CompareByDemography from "./components/CompareByDemography";

import styles from './SchoolData.module.scss';

const DataDashboardYearGroup = ({ code, schoolId }) => {

    const { currentUser } = useContext(AuthContext);
    const currentAcadYear = moment().toDate().getMonth() >= 8 ? moment().year() + 1 : moment().year()

    const { data, loading, error } = useQuery(YEAR_GROUP_DATA, {
        variables: {
            code: +code,
            academicYear: +currentAcadYear,
            schoolId: schoolId
        }
    });

    const { data: dataYg, loading: loadingYg, error: errorYg } = useQuery(GET_SCHOOL_YEAR_GROUPS);

    const [selectCompare, setSelectCompare] = useState(false);
    const [viewComparison, setViewComparison] = useState(false);
    const [selectedYears, setSelectedYears] = useState([]);

    const [seletctByDemography, setSelectByDemography] = useState(false);



    const closeComparison = () => {
        setSelectedYears([]);
        setSelectCompare(false);
        setViewComparison(false);
    }
    if (error || errorYg) {
        return <p>{error.message}</p>
    }

    if (loading || loadingYg) {
        return <Loader />
    }

    if (data && data.yearGroup && dataYg?.currentTeacher?.school?.yearGroups && currentUser?.school?.canAccessDataDashboard) {

        const { yearGroup } = data;
        const { currentTeacher: teacher } = dataYg;

        return (
            <>
                <DataDashNav />

                <div className={styles.dashWrapper}>
                    {selectCompare || seletctByDemography ? (
                        <>
                            {seletctByDemography ? <CompareByDemography close={() => setSelectByDemography(false)} schoolCode={currentUser?.school.schoolCode} demoCriteria={yearGroup.demographics} yearGroup={yearGroup} /> :
                                <>
                                    <h4 className="u-m-base-2">Who do you want to compare {yearGroup.name} with?</h4>
                                    <div className="u-flex-center u-m-base-1">
                                        <select disabled={selectedYears.length >= 5} style={{ maxWidth: '240px' }} onChange={(e) => setSelectedYears([...selectedYears, e.target.value])} className='basic-form__text-select u-m-right-2'>
                                            <option value=''>{selectedYears.length === 0 ? 'Please select year group' : 'Add another year group'}</option>
                                            {teacher?.school.yearGroups.filter(el => !selectedYears.includes(el.id) && el.id !== yearGroup.id).map(yg => (
                                                <option key={yg.id} value={yg.id}>{yg.name}</option>
                                            ))}
                                        </select>
                                        <span className="small u-text-muted">You can select up to 5 year groups</span>
                                    </div>
                                    {selectedYears.length > 0 &&
                                        <div>
                                            <hr className="separator" />
                                            {selectedYears.map(year =>
                                                <span key={`selected-${year}`} className="label label-muted u-m-right-1 u-m-base-2 u-flex-center">
                                                    {teacher?.school.yearGroups.find(el => el.id === year)?.name}
                                                    {<button onClick={() => setSelectedYears([...selectedYears].filter(el => el !== year))} className="btn-reset u-m-left-2"><X size={16} /></button>}
                                                </span>
                                            )}
                                            <div>
                                                <Button onClick={() => { setViewComparison(true); setSelectCompare(false) }} className='u-m-top-2'>Compare</Button>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    ) : viewComparison ?
                        <CompareData
                            selectedItems={selectedYears}
                            currentUser={currentUser}
                            compareItem={yearGroup}
                            close={() => closeComparison()}
                        /> : <>
                            <h3 className="u-m-base-3">{yearGroup.name}</h3>
                            <WebinarBanner />

                            {yearGroup.academicYearReports.find(r => r.academicYear === +currentAcadYear) &&
                                <AccountStats showSchool={false} statsData={yearGroup.academicYearReports.find(r => r.academicYear === +currentAcadYear)} school={false} />
                            }
                            {currentUser.school.canAccessWonde &&
                                <Demographics
                                    school={currentUser.school}
                                    isFromClass={false}
                                    source={yearGroup}
                                    demographicsData={yearGroup.demographics}
                                />
                            }

                            <div className={styles.row2Cols}>
                                <div className='card card-shadow'>
                                    <div className="card-body u-flex-center">
                                        <div className="u-flex-center">
                                            <img src={compareItemsImg} alt='' />
                                            <p className="lead u-bold u-m-left-2">Compare reading data with another year group</p>
                                        </div>
                                        <Button className='u-m-left-auto' onClick={() => setSelectCompare(true)}>Compare</Button>
                                    </div>
                                </div>
                                <div className='card card-shadow u-m-left-2'>
                                    <div className="card-body u-flex-center">
                                        <div className="u-flex-center">
                                            <img src={compareDemographicsImg} alt='' />
                                            <p className="lead u-bold u-m-left-2">Compare reading data by demography</p>
                                        </div>
                                        <Button className='u-m-left-auto' onClick={() => setSelectByDemography(true)}>Compare</Button>
                                    </div>
                                </div>

                            </div>
                            <div className="u-m-top-4">
                                <ReadingLogsLineChart sourceData={yearGroup} />
                            </div>
                            <div className="u-m-top-4">
                                {yearGroup.academicYearReports.find(r => r.academicYear === +currentAcadYear) &&
                                    <Top20Books books={yearGroup.academicYearReports.find(r => r.academicYear === +currentAcadYear)?.topBooksCompleted} />
                                }
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
    return null;
}

export default DataDashboardYearGroup;