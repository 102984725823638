import React, { Fragment, useState, useEffect, useContext } from 'react';
import * as Icon from 'react-feather'
import moment from 'moment';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Loader } from '@axeedge/go-teacher-components';

import ls from 'local-storage';
import ClassItem from './components/ClassItem';
import YearGroupItem from './components/YearGroupItem';

import WeekSelector from './components/WeekSelector';
import { CLASS_REPORTS, YEAR_GROUP_REPORTS } from '../../services/graphql';
import { LOG_BY_TEACHER, LOG_BY_STUDENT, LOG_BY_PARENT, GROUP_BOOK_LOG } from '../../../../utils/constants';
import { getWeekDays, getWeekRange, logFilterLabel } from '../../utils';
import { useTranslation } from 'react-i18next';
import styles2 from '../../../Student/Student.module.scss';
import styles from '../../DataDashboard.module.scss';
import cx from 'classnames';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import DataDashNav from '../../components/DataDashNav';

const SORT_TYPES = {
    registration: 1,
    other: 2,
    all: 3,
    year: 4
}

const ReadingTargets = () => {

    const { currentUser} = useContext(AuthContext)

    const { t } = useTranslation();

    moment.updateLocale("en", {
        week: {
            dow: currentUser && currentUser.school.weekStart
        }
    });
    moment.locale('en');


    const prefsSaved = JSON.parse(ls('data_dashboard_filters'));
    const [hasPref, setHasPref] = useState(prefsSaved || 'none');

    const [filterOpts, setFilterOpts] = useState({
        logFilters: prefsSaved && prefsSaved.logFilters || [],
        threshold: prefsSaved && +prefsSaved.threshold || 0,
        sortType: SORT_TYPES.registration
    });

    const setDays = () => {
        const weekRange = getWeekRange(moment().toDate());
        if (moment(weekRange.to).isAfter(moment().toDate())) {
            return getWeekDays(getWeekRange(moment().subtract(7, 'days').toDate()).from);
        }
        return getWeekDays(weekRange.from);
    }

    const [selectedDays, setSelectedDays] = useState(() => setDays());

    const [orderType, setOrderType] = useState('az');
    const [baseItems, setBaseItems] = useState([])
    const [orderedItems, setOrderedItems] = useState(baseItems);

    const [getDashboarData, { data, loading }] = useLazyQuery(CLASS_REPORTS, {
        fetchPolicy: 'network-only'
    });

    const [getYearGroupReport, { data: yearData, loading: yearLoading }] = useLazyQuery(YEAR_GROUP_REPORTS, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data?.schoolByCode?.classes) {
            setBaseItems(data.schoolByCode.classes);
        }
    }, [data]);

    useEffect(() => {
        if (yearData?.schoolByCode?.yearGroupReports) {
            setBaseItems(yearData.schoolByCode.yearGroupReports);
        }
    }, [yearData]);

    useEffect(() => {
        if (currentUser && hasPref !== 'none' && selectedDays.length === 7 && filterOpts.logFilters.length > 0 && filterOpts.threshold > 0) {
            if (filterOpts.sortType !== SORT_TYPES.year) {
                getDashboarData({
                    variables: {
                        schoolCode: currentUser.school.schoolCode,
                        startDate: moment(selectedDays[0]).format(),
                        endDate: moment(selectedDays[6]).format(),
                        logType: filterOpts.logFilters,
                        threshold: filterOpts.threshold,
                        typeId: filterOpts.sortType !== SORT_TYPES.all ? filterOpts.sortType : null
                    }
                });
            } else if (filterOpts.sortType === SORT_TYPES.year) {
                getYearGroupReport({
                    variables: {
                        schoolCode: currentUser.school.schoolCode,
                        startDate: moment(selectedDays[0]).format(),
                        endDate: moment(selectedDays[6]).format(),
                        logType: filterOpts.logFilters,
                        threshold: filterOpts.threshold,
                    }
                });
            }
        }
    }, [filterOpts, selectedDays, currentUser]);

    useEffect(() => {
        if (orderType === 'most_active') {
            setOrderedItems([...baseItems].sort((a, b) => a.activityReport !== undefined ? b.activityReport.percentage - a.activityReport.percentage : b.percentage - a.percentage));
        } else if (orderType === 'least_active') {
            setOrderedItems([...baseItems].sort((a, b) => a.activityReport !== undefined ? a.activityReport.percentage - b.activityReport.percentage : a.percentage - b.percentage));
        } else {
            setOrderedItems([...baseItems].sort((a, b) => a.name !== undefined ? a.name.localeCompare(b.name) : a.year - b.year));
        }
    }, [orderType, baseItems, setOrderedItems]);

    const onSelectLogType = el => {
        if (filterOpts.logFilters.indexOf(el) !== -1) {
            setFilterOpts({ ...filterOpts, logFilters: filterOpts.logFilters.filter(s => s !== el) });
        } else {
            setFilterOpts({ ...filterOpts, logFilters: [...filterOpts.logFilters, el] });
        }
    }

    const applyFilters = () => {
        filterOpts.logFilters.length > 0 && filterOpts.threshold > 0 && ls('data_dashboard_filters', JSON.stringify(filterOpts));
        setHasPref(filterOpts);
        if (filterOpts.sortType !== SORT_TYPES.year) {
            getDashboarData({
                variables: {
                    schoolCode: currentUser.school.schoolCode,
                    startDate: moment(selectedDays[0]).format(),
                    endDate: moment(selectedDays[6]).format(),
                    logType: filterOpts.logFilters,
                    threshold: filterOpts.threshold,
                    typeId: filterOpts.sortType !== SORT_TYPES.all ? filterOpts.sortType : null
                }
            });
        } else if (filterOpts.sortType === SORT_TYPES.year) {
            getYearGroupReport({
                variables: {
                    schoolCode: currentUser.school.schoolCode,
                    startDate: moment(selectedDays[0]).format(),
                    endDate: moment(selectedDays[6]).format(),
                    logType: filterOpts.logFilters,
                    threshold: filterOpts.threshold,
                }
            });
        }

    }

    return (
        <Fragment>

            <DataDashNav/>
            {hasPref && hasPref !== 'none' ? (
                <div className={cx(styles.filterHeader, styles.filterHeaderSaved)}>
                    <ul className={cx(styles2.logListFilters, 'u-align-center u-m-base-0')}>
                        {prefsSaved.logFilters.map(f => (
                            <li key={`filter-${f}`}><p><Icon.CheckSquare /> {logFilterLabel(f)}</p></li>
                        ))}
                        <li><p><Icon.Target /> {prefsSaved.threshold}</p></li>
                    </ul>
                    <button className='u-m-left-auto btn-reset hidden-print' onClick={() => setHasPref('none')}><Icon.Edit3 /></button>
                </div>
            ) : (
                <div className={styles.filterHeader}>
                    <div>
                        <p className={styles.filterHeaderType}><Icon.Edit /> Data sources:</p>
                        <ul className={cx(styles2.logListFilters, 'u-align-center u-m-base-0')}>
                            <li><p onClick={() => onSelectLogType(LOG_BY_STUDENT)}>{filterOpts.logFilters.indexOf(LOG_BY_STUDENT) !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('pupil')}</p></li>
                            <li><p onClick={() => onSelectLogType(LOG_BY_PARENT)}>{filterOpts.logFilters.indexOf(LOG_BY_PARENT) !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('parent')}</p></li>
                            <li><p onClick={() => onSelectLogType(LOG_BY_TEACHER)}>{filterOpts.logFilters.indexOf(LOG_BY_TEACHER) !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('teacher')}</p></li>
                            <li><p onClick={() => onSelectLogType(GROUP_BOOK_LOG)}>{filterOpts.logFilters.indexOf(GROUP_BOOK_LOG) !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('group')}</p></li>
                        </ul>
                    </div>
                    <div className={styles.filterHeaderReads}>
                        <p className={styles.filterHeaderType}><Icon.Target /> Target reads:</p>
                        <input min="0" className={cx('basic-form__text-box', styles.filterHeaderReadsInput)} type='number' value={filterOpts.threshold} onChange={(e) => setFilterOpts({ ...filterOpts, threshold: +e.target.value })} />
                    </div>
                    <Button className='u-m-left-auto' disabled={filterOpts.logFilters.length === 0 || !filterOpts.threshold > 0} onClick={() => applyFilters()}>Apply</Button>
                </div>
            )}
            <div className={cx(styles.subFilters, 'card card-mobile')}>
                <WeekSelector
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                    firstDayOfWeek={currentUser?.school.weekStart}
                />
                <div className={styles.subFiltersSelectors}>
                    <select className={cx('basic-form__text-select  u-m-right-2', styles.subFiltersOpt)} onChange={(e) => setFilterOpts({ ...filterOpts, sortType: +e.target.value })}>
                        <option value={SORT_TYPES.registration}>Registration classes</option>
                        <option value={SORT_TYPES.other}>Other classes</option>
                        <option value={SORT_TYPES.year}>Year Groups</option>
                        <option value={SORT_TYPES.all}>All classes</option>
                    </select>
                    <select className={cx('basic-form__text-select', styles.subFiltersOpt)} onChange={(e) => setOrderType(e.target.value)}>
                        <option value='az'>Order: {filterOpts.sortType === SORT_TYPES.year ? 'Year' : 'Class Name'}</option>
                        <option value='most_active'>Order: Most active</option>
                        <option value='least_active'>Order: Least active</option>
                    </select>
                </div>
            </div>
            {
                filterOpts.logFilters.length === 0 || !filterOpts.threshold > 0 ? (
                    <p className={styles.noData}>Set your data source and target reads to see this report</p>
                ) : (
                    <div className={styles.analyticsWrapper}>
                        {loading || yearLoading ?
                            <Loader /> : (
                                <div className={styles.classItemsWrapper}>
                                    {orderedItems.length > 0 && orderedItems.map(item => {
                                        return item.activityReport ? <ClassItem key={item.id} classData={item} threshold={filterOpts?.threshold} /> :
                                            <YearGroupItem key={item.id} yearData={item} threshold={filterOpts?.threshold} />
                                    })}

                                    {/* {orderedClassed.length > 0 && orderedClassed.map(kls => <ClassItem key={kls.id} classData={kls} threshold={filterOpts?.threshold} />)} */}
                                </div>
                            )
                        }
                    </div>
                )
            }
        </Fragment >
    )
}

export default ReadingTargets;