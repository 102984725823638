import React, { Fragment } from 'react';
import ParentDataContent from './components/parentDataContent';
import DataDashNav from '../../components/DataDashNav';

const ParentData = () => {


    return (
        <Fragment>
            <DataDashNav/>
            <ParentDataContent />
        </Fragment >
    )
}

export default ParentData;