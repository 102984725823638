import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './BandingInfo.module.scss';
import { Move, Edit } from 'react-feather';
import cx from 'classnames';

const SortableBand = ({ rank, i, editRank }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: rank.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <li key={`band${rank.id}`} ref={setNodeRef} style={style}  {...attributes} {...listeners} className={styles.bandRankItem}>
            <span className={styles.bandIndex} >{i + 1}</span>
            {rank.name}
            <button onClick={editRank}  className={cx('btn-reset', styles.bandEdit)}><Edit size="18" /></button>
            <span className={styles.bandDrag} >
            <div className={styles.bandRankColor} style={{ background: rank.colourCode }}></div>
                <Move size="16" />
            </span>
        </li>
    );
}

export default SortableBand;