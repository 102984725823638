import React, {useState, useContext} from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { ASSIGN_BAND } from '../services/graphql';
import { GET_STUDENT_QUERY } from '../../Student/services/graphql';
import styles from '../Banding.module.scss';
import { Button } from '@axeedge/go-teacher-components';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BooksContext } from '../../../services/books/BooksProvider';

const AssignContent = ({data, student, close}) => {
    
    const { t } = useTranslation();
    const bands = data.currentTeacher.school.readingBands;

    const [form, setForm] = useState(bands[0].id)

    const [rank, setRank] = useState(bands[0].ranks[0].id)

    const updateForms = (band) => {
        setForm(band);
        let newBand = _.filter(bands, ['id', band]);
        setRank(newBand[0].ranks[0].id)
    }
    
    const bookContext = useContext(BooksContext)
    const {activityFeed, setActivityFeed} = bookContext;


    const [assignBand, { error, loading }] = useMutation(ASSIGN_BAND, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if(data.addReadingBandToStudent && data.addReadingBandToStudent.student && data.addReadingBandToStudent.student.currentReadingRank) {
                const newFeed = activityFeed;
                newFeed.push({
                    id: Date.now().toString(),
                    activityDate: data.addReadingBandToStudent.student.currentReadingRank.assignedAt,
                    activityType: 6,
                    rankColourCode: data.addReadingBandToStudent.student.currentReadingRank.readingBandRank.colourCode 
                })
                setActivityFeed(newFeed);
            }
            close({show: false});
        },
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: student.id
                }
            }
        ]
    });

    return (
        <>
            <h3 className={styles.assignHeading}>{t('assign_reading_band')}</h3>
            <div className={styles.assignContent}>
                {bands.length > 0 ? 
                <>
                <select value={form} onChange={(e) => updateForms(e.currentTarget.value)} className={cx(styles.assignSelect, "u-m-right-10")}>
                    {_.map(bands, band => {
                        return (
                            <option key={`band${band.id}`} value={band.id}>{band.name}</option>
                        )
                    })}
                </select>
                {_.map(bands, band => {
                    if (band.id !== form)
                    return null;
                    return (
                        <select value={rank} key={`ranks${band.id}`} className={styles.assignSelect} onChange={(e) => setRank(e.currentTarget.value)}>
                            {_.map(band.ranks, rank => {
                                return <option key={`rank${rank.id}`} value={rank.id}>{rank.name}</option>
                            })}
                        </select>
                    )
                })}
                <div className="u-m-top-20"> 
                    <Button className="u-m-right-10" onClick={() => assignBand({
                        variables: {
                            studentId: student.id,
                            readingBandRankId: rank,
                        }
                    })}>{loading ? t('saving') : t('button.save')}</Button>
                    <Button outline onClick={() => close({show: false})}>{t('button.cancel')}</Button>
                    {error && <p className="error">{t('oops_something_went_wrong_try_again')}.</p>}
                </div>
                </>
                :
                <p>{t('your_school_doesnt_have_reading_bands')}</p>
                }
            </div>
            {student.studentReadingBandRanks.length > 0 &&
                <div className={styles.prevBands}>
                    <h3 className={styles.prevBandsHeader}>{t('band_history')}</h3>
                    <ul className="u-m-top-20">
                        {_.map(student.studentReadingBandRanks, band => {
                            return (
                                <li className="u-m-base-5" key={`band${band.id}`}>
                                    <span className={styles.prevBandDate}>{moment(band.assignedAt).format('DD MMM YY')}</span>
                                    {band.readingBandRank.name}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default AssignContent;