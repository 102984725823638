import React, {useState} from 'react';
import styles from '../Banding.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const Color = ({band, update, index}) => {

    const { t } = useTranslation();
    const [tempBand, setBand] = useState(band);


    return (
        <div className={styles.modalBg} onClick={() => update(band, index)}>
            <div className={styles.modalInner} onClick={(e) => e.stopPropagation()}>
                <h3>{band.name}</h3>
                <div className={cx(styles.bandColor, styles.bandColorPreview)} style={{background: `rgb(${tempBand.r}, ${tempBand.g}, ${tempBand.b})`}}></div>
                <div className={styles.colorSet}>
                    <label className={styles.colorLabel}>{t('red')}</label>
                    <input type="range" min="0" max="255" value={tempBand.r} onChange={(e) => setBand({...tempBand, r: e.currentTarget.value})} />
                </div>
                <div className={styles.colorSet}>
                    <label className={styles.colorLabel}>{t('green')}</label>
                    <input type="range" min="0" max="255" value={tempBand.g} onChange={(e) => setBand({...tempBand, g: e.currentTarget.value})} />
                </div>
                <div className={styles.colorSet}>
                    <label className={styles.colorLabel}>{t('blue')}</label>
                    <input type="range" min="0" max="255" value={tempBand.b} onChange={(e) => setBand({...tempBand, b: e.currentTarget.value})} />
                </div>
                <button className="button" onClick={() => update(tempBand, index)}>{t('button.update')}</button>
            </div>
        </div>
    )
}

export default Color;