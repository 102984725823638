import React, { useContext, useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_CLASS_ACTIVITY, SAVE_CLASS_FEED_SETTINGS } from '../../services/graphql';
import moment from 'moment';
import cx from 'classnames';
import * as Icon from 'react-feather'
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { BooksContext } from '../../../../services/books/BooksProvider';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import ClassActivityItem from './ClassActivityItem';

import styles from '../../../Student/Components/Search.module.scss';
import { useTranslation } from 'react-i18next';
import SaveConfirm from '../../../../components/SaveConfirm';

const Activity = ({ classId, setViewFilter, setViewReview}) => {
    const auth = useContext(AuthContext);
    const { currentUser: teacher, setCurrentUser, userPermissions } = auth;
    const { t } = useTranslation();
    const [all, setAll] = useState(false);
    const [ready, setReady] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [settingsSaved, setSettingsSaved] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(true);

    const [filters, setFilters] = useState(teacher?.settings?.classFeedActivityFilters.length > 0 ? teacher.settings.classFeedActivityFilters : [1, 2, 3, 4, 5, 6, 7, 8, 9]);

    const filtersLength = userPermissions && userPermissions.canManageStudentNotes ? 10 : 9;

    const bookContext = useContext(BooksContext)
    const { classActivityFeed, setClassActivityFeed, currentClass, setCurrentClass } = bookContext;

    const [editTeacherActivityDisplay, { loading: savingFeed }] = useMutation(SAVE_CLASS_FEED_SETTINGS, {
        onCompleted: ({ editTeacherActivityDisplay }) => {
            if (editTeacherActivityDisplay.errors && editTeacherActivityDisplay.errors.length !== 0) {
                setFormErrors(editTeacherActivityDisplay.errors);
                return;
            }
            if (editTeacherActivityDisplay.teacher.id && editTeacherActivityDisplay.teacher.settings.classFeedActivityFilters) {
                const classFeedFilters = editTeacherActivityDisplay.teacher.settings.classFeedActivityFilters;
                setCurrentUser({ ...teacher, settings: { classFeedActivityFilters: classFeedFilters } });
                setSettingsSaved(true);
            }
        }
    })

    useEffect(() => {
        if (userPermissions) {
            userPermissions.canManageStudentNotes && setFilters(teacher?.settings?.classFeedActivityFilters.length > 0 ? teacher.settings?.classFeedActivityFilters : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]);
            setReady(true);
            if (!teacher.settings || teacher?.settings?.classFeedActivityFilters.length === filtersLength) {
                setAll(true);
            }
        }

    }, [userPermissions]);


    const handleAll = () => {
        if (all) {
            setAll(false)
            setFilters([])
        } else {
            setFilters(userPermissions && userPermissions.canManageStudentNotes ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,] : [1, 2, 3, 4, 5, 6, 7, 8, 9,])
            setAll(true)
        }
    }

    useEffect(() => {
        if (ready) {
            fetchInitial({
                variables: {
                    id: classId,
                    duration: 7,
                    startDate: moment(Date.now()).format("DD/MM/YYYY"),
                    activityType: filters,
                }
            })
        }
    }, [ready])

    const [fetchInitial, { loading, error }] = useLazyQuery(GET_CLASS_ACTIVITY, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (classId !== currentClass || !currentClass) {
                setCurrentClass(classId)
                setClassActivityFeed(data.studentsClass.activities.items);
            } else if (classId === currentClass) {
                let newActivs = []
                data.studentsClass.activities.items.forEach(i => {
                    if (classActivityFeed.filter(a => a.id === i.id).length === 0) {
                        newActivs.push(i)
                    }
                })
                // filter out old items which were added manually with id: Date.now()
                setClassActivityFeed(classActivityFeed.filter(a => typeof (a.id) !== 'number').concat(newActivs))
                // setClassActivityFeed(classActivityFeed.concat(newActivs))
            }
        }
    })

    const [fetchLogs, { loading: loadingFetch }] = useLazyQuery(GET_CLASS_ACTIVITY, {
        onCompleted: data => {
            setClassActivityFeed(data.studentsClass.activities.items);
        },
        fetchPolicy: 'network-only'
    });

    const [loadMore, { loading: loadingMore }] = useLazyQuery(GET_CLASS_ACTIVITY, {
        onCompleted: data => {
            setClassActivityFeed(classActivityFeed.concat(data.studentsClass.activities.items));
            if (data.studentsClass.activities.items.length === 0) {
                setShowLoadMore(false)
            }
        },
        fetchPolicy: 'network-only',
    })

    const loadMoreLogs = () => {
        loadMore({
            variables: {
                id: classId,
                startDate: moment(classActivityFeed[classActivityFeed.length - 1].activityDate).subtract(1, 'days').format("DD/MM/YYYY"),
                duration: 7,
                activityType: filters,
            }
        })
    }



    const onChangeFilter = (e) => {
        if (e.target.value.length > 1 && e.target.value.indexOf(',') > -1) {
            var filterNums = [];
            [...e.target.value].filter(i => i !== ',').forEach(str => {
                filterNums.push(Number(str));
            })
            if (filterNums.every(elem => filters.indexOf(elem) > -1)) {
                setFilters(filters.filter(i => !filterNums.includes(i)))
                setAll(false)
            } else {
                if (filters.concat(filterNums).length === filtersLength) {
                    setAll(true)
                }
                setFilters(filters.concat(filterNums))
            }
        } else {
            if (filters.indexOf(parseInt(e.target.value)) !== -1) {
                setFilters(filters.filter(s => s !== parseInt(e.target.value)));
                setAll(false)
            } else {
                if ([...filters, parseInt(e.target.value)].length === filtersLength) {
                    setAll(true)
                }
                setFilters([...filters, parseInt(e.target.value)]);
            }
        }
    }

    return (
        <>
            <div className={styles.activityContainer}>
                {(classId !== currentClass || !currentClass) ? <p>Loading...</p> :
                    <div className={styles.activities}>
                        {classActivityFeed.length > 0 ? classActivityFeed.sort((a, b) => b.activityDate.localeCompare(a.activityDate)).map((item, i) => {
                            return (
                                <ClassActivityItem
                                    key={`log${item.id}`}
                                    i={i}
                                    item={item}
                                    classActivityFeed={classActivityFeed}
                                    setClassActivityFeed={setClassActivityFeed}
                                    teacher={teacher}
                                    classId={classId}
                                    setViewFilter={setViewFilter}
                                    setViewReview={setViewReview}
                                />
                            )
                        })
                            :
                            <p>Tap the class tab ( <Icon.BookOpen style={{ marginBottom: '-5px' }} /> ) to get started adding books and logs</p> 
                        }

                    </div>
                }
                <div className={styles.actSide}>
                    <div className={styles.actFilters}>
                        <h2 className="u-m-base-2">Display</h2>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input onChange={(e) => handleAll()} className={"basic-form__check-box"} type="checkbox" value={'ALL'} checked={all} id="FilterCheckAll" />
                            <label className="basic-form__check-label" htmlFor="FilterCheckAll">All</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={9} checked={filters.indexOf(9) > -1} id="FilterCompletedBook" />
                            <label className="basic-form__check-label" htmlFor="FilterCompletedBook">Completed Book</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="FilterBookAssigned" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={[7, 8]} checked={[7, 8].every(elem => filters.indexOf(elem) > -1)} />
                            <label htmlFor="FilterBookAssigned">New Book Added</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="FilterReadingTeacher" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={[1, 4]} checked={[1, 4].every(elem => filters.indexOf(elem) > -1)} />
                            <label htmlFor="FilterReadingTeacher">Reading Log - Teacher</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="FilterReadingParent" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={3} checked={filters.indexOf(3) > -1} />
                            <label htmlFor="FilterReadingParent">Reading Log - Parent</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="FilterReadingStudent" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={2} checked={filters.indexOf(2) > -1} />
                            <label htmlFor="FilterReadingStudent">Reading Log - Student</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="FilterReview" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={5} checked={filters.indexOf(5) > -1} />
                            <label htmlFor="FilterReview">Book Review</label>
                        </div>
                        <div className={cx(styles.stCheck, "basic-form__group--check")}>
                            <input id="FilterReadingBand" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={6} checked={filters.indexOf(6) > -1} />
                            <label htmlFor="FilterReadingBand">New Reading Band</label>
                        </div>
                        {userPermissions && userPermissions.canManageStudentNotes && (
                            <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                <input id="FilterNotes" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={10} checked={filters.indexOf(10) > -1} />
                                <label htmlFor="FilterNotes">Notes</label>
                            </div>
                        )}
                        <Button className="u-m-top-2 u-m-right-1" onClick={() => fetchLogs({
                            variables: {
                                id: classId,
                                startDate: moment(Date.now()).format("DD/MM/YYYY"),
                                duration: 7,
                                activityType: filters
                            }
                        })}>{loadingFetch ? t('loading') : t('Apply')}</Button>

                        <button
                            className='btn-reset link-underline u-text-primary'
                            disabled={savingFeed || filters.length === 0}
                            onClick={() => editTeacherActivityDisplay({
                                variables: {
                                    classFeedActivityFilters: [...filters]
                                }
                            })}
                        >Save settings</button>
                        {formErrors && <FormErrors errors={formErrors} />}
                        {settingsSaved && <SaveConfirm message='Filters saved!' hideConfirm={() => setSettingsSaved(false)} />}

                    </div>
                </div>
            </div>
            {classActivityFeed.length > 0 && (
                showLoadMore ? <Button className={styles.loadMoreActivity} onClick={() => loadMoreLogs()}>{loadingMore ? t('loading') : t('load_more')}</Button> :
                    <p className={styles.loadMoreActivity}>No more items to load</p>
            )}
        </>
    )
}

export default Activity