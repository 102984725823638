import React, { useContext, useState } from "react";
import Demographics from "./components/Demographics";
import { Button, Loader } from "@axeedge/go-teacher-components";
import ReadingLogsLineChart from "./components/ReadingLogsLineChart";
import Top20Books from "./components/Top20Books";
import AccountStats from "./components/AccountsStats";
import compareItemsImg from '../../../../images/data/compareItems.svg';
import compareDemographicsImg from '../../../../images/data/compareDemographicsImg.svg';
import CompareData from "./components/CompareData";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import DataDashNav from "../../components/DataDashNav";
import { X } from "react-feather";
import { useQuery } from "@apollo/react-hooks";
import { CLASS_REPORT_DATA } from "../../services/graphql";
import WebinarBanner from "./components/WebinarBanner";
import moment from "moment";

import styles from './SchoolData.module.scss';
import CompareByDemography from "./components/CompareByDemography";

const DataDashboardClass = ({ id }) => {

    const { currentUser } = useContext(AuthContext);
    const currentAcadYear = moment().toDate().getMonth() >= 8 ? moment().year() + 1 : moment().year()

    const { data, loading, error } = useQuery(CLASS_REPORT_DATA, {
        variables: {
            id,
            academicYear: +currentAcadYear
        }
    })

    const [selectCompare, setSelectCompare] = useState(false);
    const [viewComparison, setViewComparison] = useState(false);
    const [selectedClasses, setSelectedClasses] = useState([]);

    const [seletctByDemography, setSelectByDemography] = useState(false);


    const closeComparison = () => {
        setSelectedClasses([]);
        setSelectCompare(false);
        setViewComparison(false);
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.studentsClass && currentUser?.school?.canAccessDataDashboard) {
        const { studentsClass } = data;
        return (
            <>
                <DataDashNav />

                <div className={styles.dashWrapper}>

                    {selectCompare || seletctByDemography ? (
                        <>
                            {seletctByDemography ? <CompareByDemography close={() => setSelectByDemography(false)} schoolCode={currentUser?.school.schoolCode} demoCriteria={studentsClass.demographics} studentsClass={studentsClass} /> :
                                <>
                                    <h4 className="u-m-base-2">Who do you want to compare {studentsClass.name} with? </h4>
                                    <div className="u-m-base-1 u-flex-center">
                                        <select disabled={selectedClasses.length >= 5} style={{ maxWidth: '200px' }} onChange={(e) => setSelectedClasses([...selectedClasses, e.target.value])} className='basic-form__text-select u-m-right-2 u-m-base-0'>
                                            <option value=''>{selectedClasses.length === 0 ? 'Please select class' : 'Add another class'}</option>
                                            {currentUser?.school.classes.filter(el => !selectedClasses.includes(el.id) && el.id !== studentsClass.id).map(kls => (
                                                <option key={kls.id} value={kls.id}>{kls.name}</option>
                                            ))}
                                        </select>
                                        <span className="small u-text-muted">You can select up to 5 classes</span>
                                    </div>
                                    {selectedClasses.length > 0 &&
                                        <div>
                                            <hr className="separator" />
                                            {selectedClasses.map(sc =>
                                                <span key={`selected-${sc}`} className="label label-muted u-m-right-1 u-m-base-2 u-flex-center">
                                                    {currentUser?.school.classes.find(el => el.id === sc)?.name}
                                                    {<button onClick={() => setSelectedClasses([...selectedClasses].filter(el => el !== sc))} className="btn-reset u-m-left-2"><X size={16} /></button>}
                                                </span>
                                            )}
                                            <div>
                                                <Button onClick={() => { setViewComparison(true); setSelectCompare(false) }} className='u-m-top-2'>Compare</Button>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    ) : viewComparison ?
                        <CompareData
                            selectedItems={selectedClasses}
                            currentUser={currentUser}
                            compareItem={studentsClass}
                            isFromClass={true}
                            close={() => closeComparison()}
                        /> :
                        <>
                            <h3 className="u-m-base-3">{studentsClass.name}</h3>
                            <WebinarBanner />
                            {studentsClass.academicYearReports.find(r => r.academicYear === +currentAcadYear) &&
                                <AccountStats showSchool={false} statsData={studentsClass.academicYearReports.find(r => r.academicYear === +currentAcadYear)} school={false} />
                            }
                            {currentUser.school.canAccessWonde &&
                                <Demographics
                                    isFromClass={true}
                                    source={studentsClass}
                                    school={currentUser.school}
                                    demographicsData={studentsClass.demographics}
                                />
                            }

                            <div className={styles.row2Cols}>
                                <div className='card card-shadow u-m-right-2'>
                                    <div className="card-body u-flex-center">
                                        <div className="u-flex-center">
                                            <img src={compareItemsImg} alt='' />
                                            <p className="lead u-bold u-m-left-2">Compare reading data with another class</p>
                                        </div>
                                        <Button className='u-m-left-auto' onClick={() => setSelectCompare(true)}>Compare</Button>
                                    </div>
                                </div>
                                <div className='card card-shadow u-m-left-2'>
                                    <div className="card-body u-flex-center">
                                        <div className="u-flex-center">
                                            <img src={compareDemographicsImg} alt='' />
                                            <p className="lead u-bold u-m-left-2">Compare reading data by demography</p>
                                        </div>
                                        <Button className='u-m-left-auto' onClick={() => setSelectByDemography(true)}>Compare</Button>
                                    </div>
                                </div>

                            </div>
                            <div className="u-m-top-4">
                                <ReadingLogsLineChart sourceData={studentsClass} />
                            </div>
                            <div className="u-m-top-4">
                                {studentsClass.academicYearReports.find(r => r.academicYear === +currentAcadYear) &&
                                    <Top20Books books={studentsClass.academicYearReports.find(r => r.academicYear === +currentAcadYear)?.topBooksCompleted} />
                                }
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
    return null;
}

export default DataDashboardClass;