import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { Link } from '@reach/router';
import { useLazyQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../services/auth/AuthProvider';
import { SEARCH_STUDENTS } from './services/graphql';
import styles from './Dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import loader from '../../images/loader.svg';
import boomer from '../../images/loader-boomer.png'
import cx from 'classnames'
import * as Icon from 'react-feather'

const Dashboard = () => {
    const { t } = useTranslation();

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const [otherClasses, setOtherClasses] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([])
    const [noStudents, setNoStudents] = useState(false)
    const [hasClasses, setHasClasses] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (teacher && ((teacher.isSchoolAdmin && teacher.school?.classes?.length > 0) || teacher.visibleStudentsClasses?.length > 0)) {
            const otherCls = teacher.isSchoolAdmin ? teacher.school.classes : teacher.visibleStudentsClasses;

            const cls = otherCls
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter(c => !teacher.studentsClasses.find(stClass => stClass.id === c.id));
            setOtherClasses(cls);
        }
        if (teacher && ((teacher.isSchoolAdmin && teacher.school?.classes?.length > 0) || (teacher.studentsClasses?.length + teacher.visibleStudentsClasses?.length) > 0)) {
            setHasClasses(true);
        }
    }, [teacher]);

    const [searchStudents, { loading, error }] = useLazyQuery(SEARCH_STUDENTS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.studentsByName) {
                setResults(data.studentsByName);
                if (data.studentsByName.length === 0) {
                    setNoStudents(true)
                } else {
                    setNoStudents(false)
                }
            }
        }
    });

    useEffect(() => {
        if (searchTerm && searchTerm.length > 1) {
            intervalRef.current = setTimeout(() => {
                searchStudents({ variables: { schoolId: teacher.school.id, name: searchTerm } })
            }, 600);
        } else {
            setResults([]);
            setNoStudents(false)
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [searchTerm]);


    const search = () => {
        return (
            <div className={styles.search}>
                <div className={styles.inputHolder}>
                    <input
                        name='search'
                        className={cx('basic-form__text-box u-m-base-0', styles.searchBox)}
                        type='text'
                        autoComplete='off'
                        placeholder='Type to search by student name'
                        value={searchTerm}
                        style={{ marginRight: '10px' }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Icon.Search size="20" className={styles.searchIcon} />
                </div>
                {loading && <img width="30" src={loader} alt='loading' />}
                {error && <p>{error.message}</p>}
                {results.length > 0 && (
                    <div className={styles.resultsWrapper}>
                        <ul className={styles.resultsWrapperList}>
                            {results.map(result => (
                                <li key={result.id}>
                                    <Link to={`/student/${result.id}`} className={styles.searchLink}>
                                        <img src={result.boomerAvatarUrl ? `${result.boomerAvatarUrl}?v=${result.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.studentAva} />
                                        <span className={styles.studentName}>{result.firstName} {result.lastName}</span>
                                        <span className={styles.className}>{result.classes.filter(x => x.typeId === 1).length > 0 && result.classes.filter(x => x.typeId === 1)[0].name}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {noStudents &&
                    <div className={styles.resultsWrapper}>
                        <ul className={styles.resultsWrapperList}>
                            <li className={styles.searchLinkNotFound}>
                                <Icon.AlertCircle size="25" style={{ marginRight: '5px' }} />
                                <span>No Students Found</span>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        )
    }

    const ClassList = () => {
        return (
            <div>
                <div className="page-header">
                    <div className='page-header__content'>
                        <h1 className="page-header__content__title">{t('select_a_class')}</h1>
                    </div>
                </div>
                {search()}
                <div className="u-m-top-2">
                    <ul className={styles.classList}>
                        {teacher.studentsClasses.length > 0 && teacher.studentsClasses.sort((a, b) => a.name.localeCompare(b.name)).map(group => {
                            return (
                                <li key={`group${group.id}`} className={styles.classListItem}>
                                    <Link className={styles.classLink} to={`/class/${group.id}`}>
                                        <div className={styles.classLinkMain}>
                                            <p>{group.name}</p>
                                            <p className={styles.classLinkDesc}>{group.description}</p>
                                        </div>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    {
                        otherClasses.length > 0 && (
                            <Fragment>
                                <h3 className={styles.title}>Other {teacher.isSchoolAdmin ? 'School' : 'Visible'} Classes</h3>
                                <ul className={styles.classList}>
                                    {otherClasses.map(group => {
                                        return (
                                            <li key={`visiblegroup${group.id}`} className={styles.classListItem}>
                                                <Link className={styles.classLink} to={`/class/${group.id}`}>
                                                    <div className={styles.classLinkMain}>
                                                        <p>{group.name}</p>
                                                        <p className={styles.classLinkDesc}>{group.description}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Fragment>
                        )
                    }
                </div>
            </div>
        )
    }

    if (!teacher)
        return (<p>{t('loading')}</p>)

    if (!teacher || !hasClasses) {
        return <>
            {search()}
            <p className='u-m-top-2'>{t('no_classes_assigned_yet')}</p>
        </>
    }

    return ClassList();
}

export default Dashboard;
