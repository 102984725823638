import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import styles from './ClassChart.module.scss';

import ChartYLines from './components/ChartYLines';
import ChartMedian from './components/ChartMedian';
import SingleRow from './components/SingleRow';
import ChartLabels from './components/ChartLabels';

const rowHeight = 32;

const ClassChart = ({ classInfo, studentsData, dateRange }) => {

    const chartRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(300);

    const isPrint = window.matchMedia("print");


    useLayoutEffect(() => {
        if (chartRef.current) {
            setChartWidth(chartRef.current.offsetWidth);
        }
    }, []);

    const setChartLayout = (e) => {
        if (e.matches) {
            setChartWidth(350) 
        } else {
           chartRef.current && setChartWidth(chartRef.current.offsetWidth)
        }
    }

    useEffect(() => {
        if (isPrint?.addEventListener) {
            isPrint.addEventListener('change', setChartLayout);
            return () => isPrint.removeEventListener('change', setChartLayout);
        }
    },[])

    const getMax = () => {
        return Math.max.apply(Math, studentsData.map(el => Math.max(el['counter'], el['previousYearCounter'])))
    }

    const getRoundedMax = () => {
        const mRound = Math.round(getMax() / 10) === 0 ? 1 : Math.ceil(getMax() / 10);
        return mRound * 10;
    }
    const height = rowHeight * studentsData.length;

    return (
        <div className={styles.chartWrapper}>
            <ul className={styles.chartLabels}>
                {studentsData.map((student, i) => {
                    return <ChartLabels key={`label-${i}`} stData={student} dateRange={dateRange} classInfo={classInfo} />
                })}
            </ul>
            <div ref={chartRef} className={styles.chartArea}>
                <svg viewBox={`0 0 ${chartWidth} ${height + 40}`} width={chartWidth} height={height + 40} preserveAspectRatio='none'>
                    <ChartYLines roundedMax={getRoundedMax()} height={height} chartWidth={chartWidth} />
                    <ChartMedian chartWidth={chartWidth} data={studentsData} height={height} roundedMax={getRoundedMax()} />
                    {studentsData.map((student, i) => {
                        return <SingleRow key={`bar-${i}`} chartWidth={chartWidth} originY={i * rowHeight} stData={student} maxNumber={getMax()} roundedMax={getRoundedMax()} />
                    })}
                </svg>
            </div>
        </div>
    )
}

export default ClassChart;