import React, { useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, } from 'chart.js';
import { Doughnut, getElementsAtEvent } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const Doughnutchart = ({ dataSet, setShowPupils }) => {

    const chartRef = useRef();

    //https://www.youtube.com/watch?v=A_V14e6zC6s
    const onClickSlice = (event) => {
        if (getElementsAtEvent(chartRef.current, event).length > 0) {
            const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0].datasetIndex;
            const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index;
            setShowPupils(dataSet.datasets[datasetIndexNum].ids[dataPoint] || 'none');
        }
    }
    const options = {
        plugins: {
            legend: {
                display: true,
                responsive: true,
                position: "bottom",
                align: "center",
            },
        },
        onHover: (evt, activeEls) => {
            activeEls.length > 0 ? evt.chart.canvas.style.cursor = 'pointer' : evt.chart.canvas.style.cursor = 'default';
        }
    }
    return(
        <>
            <Doughnut
                ref={chartRef}
                data={dataSet}
                options={options}
                onClick={onClickSlice}
            />
        </>
    )
}

export default Doughnutchart;