import React, { useState, useContext } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import * as Icon from 'react-feather';
import { useMutation } from '@apollo/react-hooks';

import { Button } from '@axeedge/go-teacher-components';

import { ADD_READING_BOOK, ASSIGN_READING_BOOK, ADD_GROUP, ASSIGN_READING_BOOK_GROUP, ADD_READING_BOOK_AND_ASSIGN_TO_GROUP, ASSIGN_STUDENTS_TO_GROUP} from '../services/graphql';
import { GET_STUDENT_QUERY  } from '../../../scenes/Student/services/graphql';
import { GET_GROUPS  } from '../../../scenes/ClassNew/components/Groups/services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { BOOK_TYPES, READING_BOOK_TYPE } from '../services/constants';
import {  RB_NEW, RB_ACTIVE } from '../../../utils/constants';
import BookTyle from '../../BookTile';
import { BooksContext } from '../../../services/books/BooksProvider';
import { useTranslation } from 'react-i18next';

import styles from '../BookSearch.module.scss';

import Bugsnag from '@bugsnag/js';

const TitleSearchResults = ({ multipleBooks, studentId, close, publisher = null , reset, code, studentIds, classInfo, setFilter, bookToExistingGroup, groupBookId = null}) => {
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const { t } = useTranslation();
    const [selectedBook, setSelectedBook] = useState({open: false, book: null})

    const bookContext = useContext(BooksContext)
    const { classActivityFeed, setClassActivityFeed } = bookContext;


    const bookTypes = BOOK_TYPES.filter(t => (t.type !== READING_BOOK_TYPE.group && t.type !== 'NewGroup'));

    const [readingBook, setReadingBook] = useState({
        bookType: studentIds || bookToExistingGroup ? READING_BOOK_TYPE.group : null,
        hideComments: true
    });

    const [newBook, setNewBook] = useState({
        title: '',
        author: '',
        open: false,
    })

    const [groupName, setGroupName] = useState("");
    const [addGroupErrors, setAddGroupErrors] = useState(null);

    const [addReadingBook] = useMutation(ADD_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close(false); reset();},
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: studentId,
                }
            }
        ]
    });

    const [assignReadingBook] = useMutation(ASSIGN_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close(false); reset();},
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: studentId,
                }
            }
        ]
    });

    const assignGroupBookQueries = () => {
        if (bookToExistingGroup) {
            return [{
                query: GET_GROUPS,
                variables: {
                    id: classInfo.id,
                    status: [RB_NEW, RB_ACTIVE]
                }
            }];
        }
        return [];
    }

    const [assignReadingBookToGroup] = useMutation(ASSIGN_READING_BOOK_GROUP, {
        awaitRefetchQueries: true,
        refetchQueries: () => assignGroupBookQueries(),
        onCompleted: (data) => {
            close(false);
            setFilter(bookToExistingGroup ? 'ALL': "groups");
            if (data?.assignReadingBookToGroup?.groupReadingBook?.id) {
                const newClassFeed = classActivityFeed;
                newClassFeed.unshift({
                    id: Date.now(),
                    activityDate: data.assignReadingBookToGroup.groupReadingBook.createdAt,
                    activityType: 12,
                    author: teacher.firstName + ' ' + teacher.lastName,
                    bookName: data.assignReadingBookToGroup.groupReadingBook.book.title,
                    bookType: data.assignReadingBookToGroup.groupReadingBook.bookType,
                    students: data.assignReadingBookToGroup.groupReadingBook.students.map(s => s.firstName + ' ' + s.lastName),
                })
                setClassActivityFeed(newClassFeed)
            }
        }, 
    })

    const [addAndAssignReadingBookToGroup] = useMutation(ADD_READING_BOOK_AND_ASSIGN_TO_GROUP, {
        onCompleted: (data) => {
            close(false);
            setFilter(bookToExistingGroup ? 'ALL': "groups");
            if (data?.addReadingBookAndAssignToGroup?.groupReadingBook?.id) {
                const newClassFeed = classActivityFeed;
                newClassFeed.unshift({
                    id: Date.now(),
                    activityDate: data.addReadingBookAndAssignToGroup.groupReadingBook.createdAt,
                    activityType: 12,
                    author: teacher.firstName + ' ' + teacher.lastName,
                    bookName: data.addReadingBookAndAssignToGroup.groupReadingBook.book.title,
                    bookType: data.addReadingBookAndAssignToGroup.groupReadingBook.bookType,
                    students: data.addReadingBookAndAssignToGroup.groupReadingBook.students.map(s => s.firstName + ' ' + s.lastName),
                })
                setClassActivityFeed(newClassFeed)
            }
        }, 
        awaitRefetchQueries: true,
        refetchQueries: () => assignGroupBookQueries()
    })

    const [assignStudentsToGroup] = useMutation(ASSIGN_STUDENTS_TO_GROUP, {
        onCompleted: () => {
            /*close(false);
            setFilter("groups");*/
        }, 
    })

    const [assignGroupBook] = useMutation(ADD_GROUP, {
        onCompleted: (data) => {
            Bugsnag.leaveBreadcrumb('group data', data, 'state')
            if (data.addGroup.errors && data.addGroup.errors.length > 0) {
                setAddGroupErrors(data.addGroup.errors[0]);
                return null;
            }
            if (data.addGroup.group) {
                assignStudentsToGroup({
                    variables: {
                        groupId:data.addGroup.group.id,
                        studentIds,       
                    }
                }).then(() => 
                assignReadingBookToGroup({
                    variables: {
                        bookId: selectedBook.book.id,
                        hideComments: !readingBook.hideComments,
                        groupId: data.addGroup.group.id
                    }
                })
                )
            }
            return null;
        },
        awaitRefetchQueries: true,
        onError: (e) => {
            Bugsnag.notify(e)
        },
        refetchQueries: () => [            {
            query: GET_GROUPS,
            variables: {
                id: classInfo.id
            }
        }]
    });
    


    const AddBookForm = (book) => {

        return (
            <>
                <div className={cx(styles.addBookHeader, 'u-m-base-20')}>
                    <div>
                        <h1 className={styles.bookHeading}>{book.title}</h1>
                        <h2 className={styles.bookAuthor}>{book.author}</h2>
                        <div className={styles.resultBookCode}>ISBN: {book.isbn ? book.isbn : 'NA'}</div>
                        <div className={cx(styles.resultBookCode, 'u-m-base-5')}>ISBN13: {book.isbn13 ? book.isbn13 : 'NA'}</div>
                    </div>
                </div>
                {readingBook.bookType === READING_BOOK_TYPE.group ? <>
                    <div className='u-display-flex u-align-center u-m-base-3'>
                        <Icon.AlertCircle />
                        <div className='u-m-left-2 u-bold'>
                            <p>Group book</p>
                        </div>
                    </div>
                    <div className={cx(styles.addBookField, "u-m-base-20")}>
                        <label className="u-m-right-5">Group Name:</label>
                       {bookToExistingGroup ? <span>{bookToExistingGroup.name}</span> : <input type="text" placeholder={t('optional')} value={groupName} onChange={(e) => {setGroupName(e.currentTarget.value)}} className={styles.styledText} />}
                    </div>
                </> :
                <div className={styles.addBookField}>
                    <p className="u-m-right-5">{t('book_type')}</p>
                    <ul className={styles.addBookSelectType}>
                        {bookTypes.map(btype => (
                            <li key={`type${btype.type}`} className={styles.addBookSelectTypeItem}>
                                <button
                                    type='button' 
                                    className={cx(styles.addBookSelectTypeOpt, {[styles.addBookSelectTypeOptSelected]: readingBook.bookType === btype.type})}
                                    onClick={() => setReadingBook({...readingBook, bookType: btype.type})}
                                >{t(btype.label)}</button>
                            </li>
                        ))}
                    </ul>
                </div>
                }

                <p className="u-m-base-5">{t('pupil_comments_enabled')}</p>
                <input type="checkbox" className="switchToggle" id="CommentsSwitch" onChange={() => setReadingBook({...readingBook, hideComments: !readingBook.hideComments})} checked={readingBook.hideComments} value={readingBook.hideComments}/>
                <label className={styles.switchLabel} htmlFor="CommentsSwitch">{t('toggle')}</label>
              
                {addGroupErrors && <p className="u-bold">Oops something went wrong&hellip; {addGroupErrors.length > 0 && addGroupErrors.includes('authorized') && <>Please ask your school admin to assign you to the class to add a group book</>}</p>}
                {readingBook.bookType === READING_BOOK_TYPE.group ? 
                    <>
                        {
                            bookToExistingGroup ? 
                            <Button
                                className='u-m-top-20'
                                onClick={() => {
                                   Bugsnag.leaveBreadcrumb('group data sent', {schoolId: teacher.school.id,studentsClassId: classInfo.id, name: groupName.length > 0 ? groupName : classInfo.name}, 'state');
                                    assignReadingBookToGroup({
                                        variables: {
                                            bookId: selectedBook.book.id,
                                            hideComments: !readingBook.hideComments,
                                            groupId: bookToExistingGroup.id,
                                            groupReadingBookId: groupBookId
                                        }
                                    })
                                }}
                            >{t('add_book')}</Button> :
                            <Button
                                className='u-m-top-20'
                                onClick={() => {
                                    Bugsnag.leaveBreadcrumb('group data sent', {schoolId: teacher.school.id,studentsClassId: classInfo.id, name: groupName.length > 0 ? groupName : classInfo.name}, 'state');
                                    assignGroupBook({
                                        variables: {
                                            schoolId: teacher.school.id,
                                            studentsClassId: classInfo.id,
                                            name: groupName.length > 0 ? groupName : classInfo.name
                                        }
                                    })
                                }
                                }>{t('add_book')}</Button>  
                        }             
                    </> :
                    <>
                    {!book.existsInDb ?
                        <Button disabled={!readingBook.bookType} className='u-m-top-20' onClick={() => {addReadingBook({
                            variables: {
                                studentId: studentId,
                                author: book.author,
                                title: book.title,
                                isbn: book.isbn,
                                isbn13: book.isbn13,
                                coverUrl: book.coverUrl,
                                description: "test description",
                                bookType: readingBook.bookType,
                                hideComments: !readingBook.hideComments,
                            }
                        })}}>{t('add_book')}</Button> :
                        <Button disabled={!readingBook.bookType} className='u-m-top-20' onClick={() => {assignReadingBook({
                            variables: {
                                studentId: studentId,
                                bookId: book.id,
                                bookType: readingBook.bookType,
                                hideComments: !readingBook.hideComments,
                            }
                        })}}
                        >
                            {t('add_book')}</Button>
                    }
                    </>
                }
            </>
        )
    } 

    const NewBookForm = () => {
        const [addAndAssignGroupBook] = useMutation(ADD_GROUP, {
            onCompleted: (data) => {
                Bugsnag.leaveBreadcrumb('group data', data, 'state')
                if (data.addGroup.errors && data.addGroup.errors.length > 0) {
                    setAddGroupErrors(data.addGroup.errors[0])
                    return null;
                }
                if (data.addGroup.group) {
                assignStudentsToGroup({
                    variables: {
                        groupId:data.addGroup.group.id,
                        studentIds,
                    }
                }).then(() => {
                    addAndAssignReadingBookToGroup({
                    variables: {
                        groupId: data.addGroup.group.id,
                        author: newBook.author,
                        title: newBook.title,
                        description: "test description",
                        bookType: readingBook.bookType,
                        hideComments: !readingBook.hideComments,
                    }
                })
            })
            }
            return null;
            },
            onError: (e) => {
                Bugsnag.notify(e)
            },
            awaitRefetchQueries: true,
            refetchQueries: () => [            {
                query: GET_GROUPS,
                variables: {
                    id: classInfo.id
                }
            }]
        }); 
        return (
            <>
                <div className={cx(styles.addBookHeader, 'u-m-base-20')}>
                    <div>
                    <h1 className={styles.bookHeading}>{publisher && publisher.name}</h1>
                        <h2 className={styles.bookAuthor}>{t('add_new_book')}</h2>
                    </div>
                </div>
                <div className={cx(styles.addBookField, "u-m-base-20")}>
                    <label className="u-m-right-5">{t('book_title')}:</label>
                    <input placeholder={t('book_title')} type="text" value={newBook.title} onChange={(e) => {setNewBook({...newBook, title: e.currentTarget.value})}} className={styles.styledText} />
                </div>
                <div className={cx(styles.addBookField, "u-m-base-20")}>
                    <label className="u-m-right-5">{t('author')}:</label>
                    <input type="text" placeholder={t('optional')} value={newBook.author} onChange={(e) => {setNewBook({...newBook, author: e.currentTarget.value})}} className={styles.styledText} />
                </div>
                {readingBook.bookType === READING_BOOK_TYPE.group ? 
                    <>
                    <div className='u-display-flex u-align-center u-m-base-3'>
                        <Icon.AlertCircle />
                        <div className='u-m-left-2 u-bold'>
                            <p>Group book</p>
                        </div>
                    </div>
                    <div className={cx(styles.addBookField, "u-m-base-20")}>
                        <label className="u-m-right-5">Group Name:</label>
                       {bookToExistingGroup ? <span>{bookToExistingGroup.name}</span> : <input type="text" placeholder={t('optional')} value={groupName} onChange={(e) => {setGroupName(e.currentTarget.value)}} className={styles.styledText} />}
                    </div>
                    </>
                :
                <div className={styles.addBookField}>
                    <p className="u-m-right-5">{t('book_type')}</p>
                    <ul className={styles.addBookSelectType}>
                        {bookTypes.map(btype => (
                            <li key={`type${btype.type}`} className={styles.addBookSelectTypeItem}>
                                <button
                                    type='button' 
                                    className={cx(styles.addBookSelectTypeOpt, {[styles.addBookSelectTypeOptSelected]: readingBook.bookType === btype.type})}
                                    onClick={() => setReadingBook({...readingBook, bookType: btype.type})}
                                >{t(btype.label)}</button>
                            </li>
                        ))}
                    </ul>
                </div>
                }

                <p className="u-m-base-5">{t('pupil_comments_enabled')}</p>
                <input type="checkbox" className="switchToggle" id="CommentsSwitch" onChange={() => setReadingBook({...readingBook, hideComments: !readingBook.hideComments})} checked={readingBook.hideComments} value={readingBook.hideComments}/>
                <label className={styles.switchLabel} htmlFor="CommentsSwitch">{t('toggle')}</label>
                {addGroupErrors && <p className="u-bold">Oops something went wrong&hellip; {addGroupErrors.length > 0 && addGroupErrors.includes('authorized') && <>Please ask your school admin to assign you to the class to add a group book</>}</p>}

                {readingBook.bookType === READING_BOOK_TYPE.group ? 
                        <>
                            {
                            bookToExistingGroup ? 
                                <Button
                                    className='u-m-top-20'
                                    onClick={() => {
                                        Bugsnag.leaveBreadcrumb('group data sent', {schoolId: teacher.school.id,studentsClassId: classInfo.id, name: bookToExistingGroup.name}, 'state');
                                        addAndAssignReadingBookToGroup({
                                            variables: {
                                                groupId: bookToExistingGroup.id,
                                                author: newBook.author,
                                                title: newBook.title,
                                                description: "test description",
                                                bookType: readingBook.bookType,
                                                hideComments: !readingBook.hideComments,
                                                groupReadingBookId: groupBookId
                                            }
                                        })
                                    }}
                                >{t('add_book')}</Button> :
                                <Button className='u-m-top-20' onClick={() => {
                                    Bugsnag.leaveBreadcrumb('group data sent', {schoolId: teacher.school.id,studentsClassId: classInfo.id, name: groupName.length > 0 ? groupName : classInfo.name}, 'state');
                                    addAndAssignGroupBook({
                                    variables: {
                                        schoolId: teacher.school.id,
                                        studentsClassId: classInfo.id,
                                        name: groupName.length > 0 ? groupName : classInfo.name
                                    }
                                })}} disabled={(newBook.title === '' && true) || !readingBook.bookType}>{t('add_book')}</Button> 
                            }
                        </> :         
                        <Button className='u-m-top-20' onClick={() => {addReadingBook({
                            variables: {
                                studentId: studentId,
                                author: newBook.author,
                                title: newBook.title,
                                description: "test description",
                                bookType: readingBook.bookType,
                                hideComments: !readingBook.hideComments,
                                publisherId: publisher && publisher.id,
                            }
                        })}} disabled={(newBook.title === '' && true) || !readingBook.bookType}>{t('add_book')}</Button>
                    }
            </>
        )
    } 

    const bookListing = (book) => {
        return (
            <BookTyle key={`titleBook${book.isbn ? book.isbn : book.id}`} book={book} event={() => setSelectedBook({open: true, book: book})} />
            )
    }
    return (
        <div className={styles.bookResults}>
            {(multipleBooks.booksByTitle.items.length > 0) && 
            <ul className={styles.searchBooks}>
                {_.map(multipleBooks.booksByTitle.items, book => {
                    return bookListing(book);
                })}
            </ul>
            }
            {(multipleBooks.booksByTitle.items.length === 0) && <p className="u-m-top-20 altDesc">{t('no_matching_books_found')}</p>}
            <div className={styles.bookResultsAdd}>
                    <p className={cx("u-m-base-20 altDesc", styles.newBookPara)}>{t('cant_find_your_book')}</p>
                    <Button onClick={() => setNewBook({...newBook, open: true, title: code})}>{t('add_new_book')}</Button>
            </div>
            <div className={cx(styles.bookModal,selectedBook.open && styles.bookModalOpen)}>
                {selectedBook.book && AddBookForm(selectedBook.book)}
            </div>
            {selectedBook.open && <Icon.XCircle onClick={() => {setSelectedBook({...selectedBook, open: false})}} className={cx(styles.closeBook, styles.closeBookAlt)}/> }
            <div className={cx(styles.bookModal,newBook.open && styles.bookModalOpen)}>
                {NewBookForm()}
            </div>
            {newBook.open && <Icon.XCircle onClick={() => {setNewBook({...newBook, open: false})}} className={cx(styles.closeBook, styles.closeBookAlt)}/> }
        </div>
    )
}

export default TitleSearchResults;