import React from 'react';
import * as Icon from 'react-feather';

import BookTypeLabel  from '../BookTypeLabel';
import { RB_COMPLETE } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

import styles from './bookTile.module.scss';

const BookTile = ({ readingBook, book, event }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.bookTile}>
            <button onClick={event} className={styles.bookTileInner}>
                <div>
                    <span className={styles.bookTitle}>{book.title}</span>
                    {book.author && <span className={styles.bookAuthor}>{book.author}</span>}
                    {book.publisher && <span className={styles.bookPublisher}><i>{book.publisher.name}</i></span>}
                    {readingBook && (
                        <div className={styles.bookTileFooter}>
                            <BookTypeLabel type={readingBook.bookType}/>
                            {readingBook.status === RB_COMPLETE && <span className={styles.bookTileComplete}><Icon.CheckCircle size='20' className='u-text-primary' /> {t('complete')}</span>}
                        </div>
                    )}
                </div>
                
                <div className={styles.bookState}>
                    {readingBook && readingBook.status !== RB_COMPLETE && 
                        <>
                            <Icon.PlusCircle size={50} color={"#000"} />
                            <span className="u-m-top-5">{t('log_reading')}</span>
                        </>
                    }
                 
                    {!readingBook &&
                        <>
                            <Icon.PlusCircle size={50} color={"#000"} />
                            <span className="u-m-top-5">{t('add_book')}</span>
                        </>                
                    }
                   
                </div>
            </button>
        </div>
    )
}

export default BookTile;