import { gql } from 'apollo-boost';

export const GET_GROUPS = gql`
query studentsClassGroups($id: ID!, $status: [Int!]) {
    studentsClass(id: $id) {
        id
        name
        students {
            id
            firstName
            lastName
        }
        groups {
            id
            name
            books(status: $status) {
                id
                students{
                    id
                    firstName
                    lastName
                }
                group {
                    id
                    name
                }
                hideComments
                status
                completedAt
                createdAt
                book {
                    id
                    coverUrl
                    title
                    author
                    publisher {
                        id
                        name
                    }
                    isbn
                    isbn13
                    reviews {
                        id
                        author {
                            id
                        }
                        reviewText
                        reviewRate
                        date
                    }
                }
                mostRecentLog {
                    id
                    readingDate
                    pageEnd
                    pageStart
                }
            }
        }
    }
}
`;


