import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as Icon from 'react-feather';
import cx from 'classnames';
import _ from 'lodash';

import { Button } from '@axeedge/go-teacher-components';

import styles from './LogReading.module.scss';

import { ADD_READING_LOG, MARK_AS_COMPLETE } from './services/graphql';
import { GET_STUDENT_QUERY } from '../../scenes/Student/services/graphql';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

const Content = ({data, standalone=false,}) => {
    const { t } = useTranslation();

    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });

    const [record, setRecord] = useState({
        date: new Date().toDateInputValue(),
        pageStart: data.pageStart || 1,
        pageEnd: '',
        comment: "",
        problemWords: [],
        completed: false,
        showError: false
    })

    const [logSubmitted, setLogSubmiited] = useState(false);

    const tagInput = useRef(null);
    
    const addWordTags = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (record.problemWords.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            setRecord({
                ...record,
                problemWords: [...record.problemWords, val.trim()]
            });
            tagInput.current.value = null;
        }
    };

    const removeWordTag = i => {
        const newWords = [ ...record.problemWords ];
        newWords.splice(i, 1);
        setRecord({
            ...record,
            problemWords: newWords
        });
    };

    const completeLog = () => {
        /*if (!standalone) {
            window.history.back()
        } else {
            setLogSubmiited(true);
        }*/
        setLogSubmiited(true);
    }

    const [addLog,  { loading }] = useMutation(ADD_READING_LOG, {
        awaitRefetchQueries: true,
        onCompleted: () => completeLog(),
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: data.student.id
                }
            }
        ]
    });

    const [markAsComplete,  { loading: completeLoading }] = useMutation(MARK_AS_COMPLETE, {
        awaitRefetchQueries: true,
        onCompleted: () => completeLog(),
        refetchQueries: () => [
            {
                query: GET_STUDENT_QUERY,
                variables: {
                    id: data.student.id
                }
            }
        ]
    });

    const handleAdd = () => {
        if (record.completed) {
            addLog({
                variables: {
                    studentReadingBookId: data.readingBook.id, 
                    pageStart: parseInt(record.pageStart),
                    pageEnd: record.pageEnd !== '' ? parseInt(record.pageEnd) : parseInt(record.pageStart) + 1,
                    entry: record.comment,
                    problemWords: record.problemWords,
                    finished: record.completed,
                    readingDate: record.date
                }
            })
            return;
        }
        if (record.pageEnd === '' || record.pageStart === '' || record.pageEnd < 0 || record.pageStart < 0 || isNaN(record.pageStart) || isNaN(record.pageEnd) || record.pageStart % 1 !== 0 || record.pageEnd % 1 !== 0 || record.pageEnd > 100000 || record.pageStart > 100000) {
            setRecord({...record, showError: true})
        } else {
            addLog({
                variables: {
                    studentReadingBookId: data.readingBook.id, 
                    pageStart: parseInt(record.pageStart),
                    pageEnd: parseInt(record.pageEnd),
                    entry: record.comment,
                    problemWords: record.problemWords,
                    finished: record.completed,
                    readingDate: record.date
                }
            })
        }
    }

    return (
        <>
            <h1 className={styles.logReadingHeader}>{data.student.name}</h1>
            {logSubmitted ? 
                <>
                <p className="u-m-top-10 u-m-base-10">{t('thank_you_for_submitting_log')}.</p>
                {standalone ? <Link to={`/student/${data.student.id}`}><Button>{t('back')}</Button></Link> :
                    <Button outline onClick={() => window.history.back()}>{t('back')}</Button>
                    } 
                </> :
                <>
                {data.readingBook ?
                <>
                <h2 className={styles.bookTitleHeader}>{data.readingBook.book.title}</h2>
                <div className="u-display-flex">
                    <div className={cx(styles.logReadingField, 'u-m-right-20')}>
                        <Icon.Calendar className="u-m-right-10" />
                        <input placeholder="5/10/25" type="date" onChange={(e) => setRecord({...record, date: e.currentTarget.value})} className={styles.styledInput} value={record.date} />
                    </div>
                </div>
                <div className="u-display-flex u-m-top-20">
                    <div className={styles.logReadingField}>
                        <Icon.FileText className="u-m-right-10" />
                        <input type="number" inputMode="numeric" className={cx(styles.pageNum,'u-m-right-10', styles.styledInput)} placeholder="Start" onChange={(e) => setRecord({...record, pageStart: e.currentTarget.value, showError: false})} value={record.pageStart}  step="1" min="1" max="100000"/>
                        <span className="u-m-right-10">{t('to')}</span>
                        {record.completed ?
                        <p>The End</p> :
                        <input type="number" inputMode="numeric" className={cx(styles.pageNum,'u-m-right-10', styles.styledInput)} onChange={(e) => setRecord({...record, pageEnd: e.currentTarget.value, showError: false})} value={record.pageEnd}  step="1" min="1" max="100000"/>}
                    </div>
                </div>
                <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-20')}>{t('comments')}</h2>
                <textarea className={cx(styles.logReadingComment, styles.styledInput, 'u-m-top-5')} rows="3" placeholder="Write your comment here..." onChange={(e) => setRecord({...record, comment: e.currentTarget.value})}></textarea>
                <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-20')}>{t('any_problem_words_sounds')}</h2>
                <p>{t('type_words_sound_info')}</p>
                <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                    <input
                        type="text"
                        placeholder={t('separate_words_comma_enter')}
                        className='basic-form__text-box'
                        onKeyPress={(e) => addWordTags(e)}
                        ref={tagInput}
                    />
                </div>
                <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                    {_.map(record.problemWords, (word, i) => {
                        return (
                            <span className={styles.logReadingProbWord} key={`probWord${i}`}>
                                {word}
                                <button className={styles.logReadingProbWordDelete} type="button" onClick={() => removeWordTag(i)}><Icon.X size="12" /></button>
                            </span>
                        )
                    })}
                </div>
                <div className={cx(styles.logReadingField, 'u-m-top-20')}>
                    <h2 className={cx(styles.logReadingSubHeader, 'u-m-right-10')}>{t('book_is_completed')}</h2>
                    <input type="checkbox" className='switchToggle' id="switch" onChange={(e) => setRecord({...record, completed: !record.completed})}  value={record.completed}/><label className={styles.switchLabel} htmlFor="switch">{t('toggle')}</label>
                </div>
                <div className="u-m-top-20">
                    {record.showError && <p className="u-m-base-5 error">{t('make_sure_page_start_end_valid_numbers')}.</p>}
                    <Button className="u-m-right-10" onClick={() => handleAdd()} disabled={loading && true} disabledStyle={(record.pageEnd === '' && record.completed) ? false : record.pageEnd === '' ? true : false}>{loading ? t('adding') : t('add_record')}</Button>
                    {standalone ? <Link to={`/student/${data.student.id}`}>{t('button.cancel')}</Link> :
                    <Button outline onClick={() => window.history.back()}>{t('button.cancel')}</Button>
                    }
                </div>
                <div className={styles.sep}>
                    <h2 className={cx(styles.logReadingSubHeader, 'u-m-base-10')}>Mark As Complete (No Log)</h2>
                    <p className="u-m-top-10 u-m-base-10">This will mark the book as completed without creating a reading log.</p>
                    <Button className="u-m-right-10" onClick={() => markAsComplete({variables: {
                        studentReadingBookId: data.readingBook.id, 
                    }})} disabled={(loading || completeLoading) && true}>{(loading || completeLoading) ? 'Saving' : 'Mark as complete'}</Button>
                </div>
                </> : <>
                    <p className="u-m-base-2">This student currently does not have any active reading books.</p>
                    {standalone ? <Link to={`/student/${data.student.id}`}>{t('button.cancel')}</Link> :
                    <Button outline onClick={() => window.history.back()}>{t('button.cancel')}</Button>
                    }
                </>}
                </>
                }
        </>
        )
}

export default Content;