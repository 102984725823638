import React from 'react';
import cx from 'classnames';

import * as Icon from 'react-feather';

import styles from './Tab.module.scss';

const Tab = ({ children, disabled, icon, onClick, selected }) => {
    const IconTag = icon ? Icon[icon] : null;

    return (
        <li
            className={cx(styles.tab, { [styles.disabled]: disabled }, { [styles.selected]: selected }, { [styles.iconTab]: icon })}
            onClick={onClick}
        >
            {icon && <IconTag className={selected ? styles.iconSelected : ''} />}
            {children}
        </li>
    )
}

export default Tab;