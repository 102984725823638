import React from 'react';
import { ActionCableConsumer,
} from '@thrash-industries/react-actioncable-provider';

const ClassActionCable = ({ setCustomStats, teacherId, classId }) => {


  return (
      <ActionCableConsumer
        channel={{
          channel: "StatsNotificationsChannel",
          id: `${classId}_${teacherId}`
        }}
        onReceived={(message) => {
          setCustomStats(message)
        }}
      ></ActionCableConsumer>
  );
};

export default ClassActionCable;

