import React, {useState, useEffect} from 'react'
import styles from './parentDataContent.module.scss'
import cx from 'classnames'
import { useQuery } from '@apollo/react-hooks'
import {GET_SCHOOL_YEAR_STATS} from "../services/graphql";
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';
import { Maximize2 } from 'react-feather';
import ViewStudents from './viewStudents'
const ParentsByYear = () => {
    const { data, error, loading } = useQuery(GET_SCHOOL_YEAR_STATS);
    const [years, setYears] = useState(null)
    const [sort, setSort] = useState(0) 
    const [viewYear, setViewYear] = useState(null)
    useEffect(() => {
        if(data) {
            let tempYears = []
            YEAR_GROUPS.map((y) => {
                let withParents = data.currentTeacher.school.yearGroupWithParentAccountCounters.filter(x => x.yearGroup === y.val)[0] ? data.currentTeacher.school.yearGroupWithParentAccountCounters.filter(x => x.yearGroup === y.val)[0].studentCounter : 0;
                let withoutParents = data.currentTeacher.school.yearGroupWithoutParentAccountCounters.filter(x => x.yearGroup === y.val)[0] ? data.currentTeacher.school.yearGroupWithoutParentAccountCounters.filter(x => x.yearGroup === y.val)[0].studentCounter : 0
                let total = withParents + withoutParents;
                if (total === 0) {
                    return null
                }
                let year = {
                    year: y.val,
                    name: y.name,
                    withParents: withParents,
                    withoutParents: withoutParents,
                    percentWith: (withParents/total)*100
                }
                tempYears.push(year)
            })
            setYears(tempYears)
        }
    }, [data])
    
    function compareWithParents( a, b ) {
        if ( a.withParents < b.withParents ){
          return -1;
        }
        if ( a.withParents > b.withParents ){
          return 1;
        }
        return 0;
    }

    function compareWithoutParents( a, b ) {
        if ( a.withoutParents < b.withoutParents ){
          return -1;
        }
        if ( a.withoutParents > b.withoutParents ){
          return 1;
        }
        return 0;
    }

    function comparePercentWithout( a, b ) {
        if ( a.percentWithout < b.percentWithout ){
          return -1;
        }
        if ( a.percentWithout > b.percentWithout ){
          return 1;
        }
        return 0;
    }

    useEffect(() => {
        if (sort === 1) {setYears([...years].sort(compareWithParents))};
        if (sort === 2) {setYears([...years].sort(compareWithParents).reverse())}
        if (sort === 3) {setYears([...years].sort(compareWithoutParents))}
        if (sort === 4) {setYears([...years].sort(compareWithoutParents).reverse())}
        if (sort === 5) {setYears([...years].sort(comparePercentWithout))}
        if (sort === 6) {setYears([...years].sort(comparePercentWithout).reverse())}
    }, [sort])

    if (loading) {
        return <p>Fetching Data&hellip;</p>
    }
    if (data) {
        return (<>
            {
            
            viewYear ?
            <ViewStudents yearGroup={viewYear} setViewYear={setViewYear} />
            :
                <table className={cx("u-m-top-4", styles.dataTable)}>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className={styles.colHeader} onClick={() => setSort(sort === 1 ? 2 : 1)}>Parent Linked</th>
                            <th className={styles.colHeader} onClick={() => setSort(sort === 3 ? 4 : 3)}>Parent Not Linked</th>
                            <th className={styles.colHeader} onClick={() => setSort(sort === 5 ? 6 : 5)}>% With Linked Parent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {years && years.map(y => {
                            return (
                                <tr className={styles.dataRow} key={`y${y.year}`}>
                                    <td className={styles.dataName}><button className={styles.dataNameInner} onClick={() => setViewYear(y.year)}>{y.name} <Maximize2 size="16"/></button></td>
                                    <td className={styles.dataCell}><span className={cx(styles.heroStat, styles.dataGreen)}>{y.withParents}</span></td>
                                    <td className={styles.dataCell}><span className={cx(styles.heroStat, styles.dataRed)}>{y.withoutParents}</span></td>
                                    <td className={styles.dataCell}><span className={cx(styles.heroStat)}>{(Math.round(y.percentWith * 10) / 10)}%</span></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
        </>)
    }
    return <p>Something went wrong</p>
}

export default ParentsByYear;