import React from 'react';
import { useQuery} from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { BOOK_ISBN_SEARCH_QUERY } from '../services/graphql';
import SearchResults from './searchResults';

const SearchQueries = ({code, studentId, close, reset, studentIds, classInfo, setFilter, bookToExistingGroup, groupBookdId = null}) => {

    const { t } = useTranslation();
    const { data, error, loading } = useQuery(BOOK_ISBN_SEARCH_QUERY, {
        variables: {
            isbn: code.replace(/-/g, "")
        },
        fetchPolicy: 'network-only',
    });


    if (error) {
    return <p>{error && error.message && error.message}</p>
    }

    if (loading) {
        return <p>{t('loading')}</p>
    }

   if (data) {
        return <SearchResults singleBook={data} code={code} studentId={studentId} close={close} reset={reset} studentIds={studentIds} bookToExistingGroup={bookToExistingGroup} classInfo={classInfo} setFilter={setFilter} />
    }
    return null
}

export default SearchQueries;