import React, { useState, forwardRef } from 'react';
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import * as Icon from 'react-feather'
import moment from 'moment';
import styles from '../../../DataDashboard.module.scss';
import { getWeekDays, getWeekRange } from '../../../utils';

const WeekSelector = ({ selectedDays, setSelectedDays, firstDayOfWeek }) => {

    const [hoverRange, setHoverRange] = useState(undefined);

    // only allow days in a full/completed week (for calendar days hovering/click)
    const checkDateAllowed = date => {
        return moment(date).endOf('week').isBefore(moment().toDate());
    }

    const handleDayChange = date => {
        checkDateAllowed(date) && setSelectedDays(() => getWeekDays(getWeekRange(date).from))
    };

    const onPrevWeek = () => {
        const prevWeek = moment(selectedDays[0]).subtract(1, 'week').toDate();
        setSelectedDays(() => getWeekDays(getWeekRange(prevWeek).from));
    }
    const onNextWeek = () => {
        const nextWeek = moment(selectedDays[6]).add(1, 'week').toDate();
        setSelectedDays(() => getWeekDays(getWeekRange(nextWeek).from));
    }

    const modifiers = {
        hoverRange: hoverRange,
        selectedRange: selectedDays.length > 0 && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: selectedDays.length > 0 && selectedDays[0],
        selectedRangeEnd: selectedDays.length > 0 && selectedDays[6],
        disabled: {
            after: getWeekRange(moment().subtract(7, 'days').toDate()).to
        },
    };



    const CustomInput = ({ onClick, onFocus, onBlur }, ref) => (
        <button ref={ref} className={styles.inputCalendarBtn} onFocus={onFocus} onBlur={onBlur} onClick={onClick}>
            {selectedDays.length === 7 ? (
                <>
                    {moment(selectedDays[0]).format('ll')} – {' '}
                    {moment(selectedDays[6]).format('ll')}
                </>
            ) : 'Choose dates'}
        </button>
    )

    return (
        <div className={styles.subFiltersDates}>
            <button className={styles.subFiltersWeekNav} onClick={() => onPrevWeek()}><Icon.ChevronLeft /></button>
            <div className="week-calendar-wrapper">
                <DayPickerInput
                    component={forwardRef(CustomInput)}
                    dayPickerProps={{
                        firstDayOfWeek: firstDayOfWeek,
                        selectedDays,
                        modifiers,
                        showOutsideDays: true,
                        month: moment(selectedDays[0]).toDate(),
                        toMonth: moment().toDate(),
                        onDayClick: handleDayChange,
                        onDayMouseEnter: (date) => setHoverRange(checkDateAllowed(date) ? getWeekRange(date) : undefined),
                        onDayMouseLeave: () => setHoverRange(undefined)
                    }}
                />
            </div>
            {
                moment(selectedDays[6]).add(7, 'days').toDate() < moment().toDate() && (
                    <button type='button' className={styles.subFiltersWeekNav} onClick={() => onNextWeek()}><Icon.ChevronRight /></button>
                )
            }
        </div>
    );
}

export default WeekSelector;