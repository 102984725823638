import React, { useState } from 'react';
import * as Icon from 'react-feather'
import styles from '../ClassChart.module.scss';
import StudentData from '../../StudentData';
import styles2 from '../../../../../Student/Student.module.scss';
import cx from 'classnames';
import { getFlagColor } from '../../../../../../utils/constants';

const ChartLabels = ({ stData, classInfo, dateRange }) => {

    const [viewStudent, setViewStudent] = useState(null);

    return (
        <>
            <li className={styles.chartLabelsItem}>
                <span className={styles.chartLabelsFlag}>
                    {stData.flagType && <Icon.Flag fill={getFlagColor(stData.flagType)} color={getFlagColor(stData.flagType)} size='14' />}
                </span>
                <button className={cx('btn-reset', styles.chartLabelsItemName)} onClick={() => setViewStudent(stData)} >{stData.name}</button>
                <span className={styles.chartLabelsData}>
                    {stData.counter}
                </span>
            </li>

            {viewStudent && <div onClick={() => setViewStudent(null)} className={styles2.bookBG}></div>}
            <div className={cx(styles2.bookModal, viewStudent && styles2.bookModalOpen)}>
                {viewStudent &&
                    <StudentData student={viewStudent} dateRange={dateRange} classInfo={classInfo} />
                }
            </div>
            {viewStudent && <Icon.XCircle onClick={() => setViewStudent(null)} className={styles2.closeBook}/> }
        </>
    );
}

export default ChartLabels;