import React, { useEffect, useState, useContext } from 'react';

import moment from 'moment';
import cx from 'classnames';
import { Button } from '@axeedge/go-teacher-components';

import { GET_STUDENT_LOGS } from '../services/graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import { BooksContext } from '../../../services/books/BooksProvider';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import ActivityItem from './activityItem';

import styles from './Search.module.scss';
import loader from '../../../images/loader.svg';


const Activity = ({student, classId, initiate, downloadData, userPermissions, setSection }) => {
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const { t } = useTranslation();

    const [completed, setCompleted] = useState(true);
    const [all, setAll] = useState(true);

    const [filters, setFilters] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9,])

    const bookContext = useContext(BooksContext)
    const { activityFeed, setActivityFeed, classActivityFeed, setClassActivityFeed } = bookContext;
    const [showLoadMore, setShowLoadMore] = useState(true);

    const [days, setDays] = useState(1);

    const filtersLength = userPermissions && userPermissions.canManageStudentNotes ? 10 : 9;

    useEffect(() => {
        if (userPermissions && userPermissions.canManageStudentNotes) {
            setFilters([1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]);
        }
    }, [userPermissions]);

    const handleAll = () => {
        if (all) {
            setAll(false)
            setCompleted(false);
            setFilters([])
        } else {
            setCompleted(true);
            setFilters(userPermissions && userPermissions.canManageStudentNotes ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,] : [1, 2, 3, 4, 5, 6, 7, 8, 9,])
            setAll(true)
        }
    }

    const [fetchLogs, { loading }] = useLazyQuery(GET_STUDENT_LOGS, {
        onCompleted: data => {
            setActivityFeed(data.student.activities.items);
        },
        fetchPolicy: 'network-only'
    });

    const [loadMore, { loading: loadingMore }] = useLazyQuery(GET_STUDENT_LOGS, {
        onCompleted: data => {
            setActivityFeed(activityFeed.concat(data.student.activities.items));
            setDays(days + 1);
            if (data.student.activities.items.length === 0) {
                setShowLoadMore(false)
            }
        },
        fetchPolicy: 'network-only',
    })


    useEffect(() => {
        setActivityFeed([])
        fetchLogs({
            variables: {
                id: student.id,
                startDate: moment(Date.now()).format("DD/MM/YYYY"),
                duration: 7,
                activityType: filters,
                isBookCompletion: completed
            }
        })
    }, [initiate])

    const loadMoreLogs = () => {
        loadMore({
            variables: {
                id: student.id,
                startDate: moment(activityFeed[activityFeed.length - 1].activityDate).subtract(1, 'days').format("DD/MM/YYYY"),
                duration: 7,
                activityType: filters,
                isBookCompletion: completed
            }
        })
    }


    const onChangeFilter = (e) => {
        if (e.target.value.length > 1 && e.target.value.indexOf(',') > -1) {
            var filterNums = [];
            [...e.target.value].filter(i => i !== ',').forEach(str => {
                filterNums.push(Number(str));
            })
            if (filterNums.every(elem => filters.indexOf(elem) > -1)) {
                setFilters(filters.filter(i => !filterNums.includes(i)))
                setAll(false)
            } else {
                if (filters.concat(filterNums).length === filtersLength) {
                    setAll(true)
                }
                setFilters(filters.concat(filterNums))
            }
        } else {
            if (filters.indexOf(parseInt(e.target.value)) !== -1) {
                setFilters(filters.filter(s => s !== parseInt(e.target.value)));
                setAll(false)
            } else {
                if ([...filters, parseInt(e.target.value)].length === filtersLength) {
                    setAll(true)
                }
                setFilters([...filters, parseInt(e.target.value)]);
            }
        }
    }

    return (
        <>
            {loading ? <img src={loader} alt="loading" /> :
                <>
                    <div className={styles.activityContainer}>
                        <div className={styles.activities}>
                            {activityFeed.length > 0 ? activityFeed.sort((a, b) => b.activityDate.localeCompare(a.activityDate)).map((item, i) => {
                                return (
                                    <ActivityItem
                                        teacher={teacher}
                                        key={`log${item.id}`}
                                        item={item}
                                        i={i}
                                        activityFeed={activityFeed}
                                        setActivityFeed={setActivityFeed}
                                        classActivityFeed={classActivityFeed}
                                        setClassActivityFeed={setClassActivityFeed}
                                        setSection={setSection}
                                        classId={classId}
                                    />
                                )
                            })
                                :
                                <p>No Activities</p>
                            }
                        </div>
                        <div className={styles.actSide}>
                            <div className={styles.actFilters}>
                                <h2 className="u-m-base-2">Display</h2>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input onChange={(e) => handleAll()} className={"basic-form__check-box"} type="checkbox" value={'ALL'} checked={all} id="FilterCheckAll" />
                                    <label className="basic-form__check-label" htmlFor="FilterCheckAll">All</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={9} checked={filters.indexOf(9) > -1} id="FilterCompletedBook" />
                                    <label className="basic-form__check-label" htmlFor="FilterCompletedBook">Completed Book</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input id="FilterBookAssigned" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={[7, 8]} checked={[7, 8].every(elem => filters.indexOf(elem) > -1)} />
                                    <label htmlFor="FilterBookAssigned">New Book Added</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input id="FilterReadingTeacher" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={[1, 4]} checked={[1, 4].every(elem => filters.indexOf(elem) > -1)} />
                                    <label htmlFor="FilterReadingTeacher">Reading Log - Teacher</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input id="FilterReadingParent" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={3} checked={filters.indexOf(3) > -1} />
                                    <label htmlFor="FilterReadingParent">Reading Log - Parent</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input id="FilterReadingStudent" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={2} checked={filters.indexOf(2) > -1} />
                                    <label htmlFor="FilterReadingStudent">Reading Log - Student</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input id="FilterReview" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={5} checked={filters.indexOf(5) > -1} />
                                    <label htmlFor="FilterReview">Book Review</label>
                                </div>
                                <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                    <input id="FilterReadingBand" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={6} checked={filters.indexOf(6) > -1} />
                                    <label htmlFor="FilterReadingBand">New Reading Band</label>
                                </div>
                                {userPermissions && userPermissions.canManageStudentNotes && (
                                    <div className={cx(styles.stCheck, "basic-form__group--check")}>
                                        <input id="FilterNotes" onChange={(e) => onChangeFilter(e)} className={"basic-form__check-box"} type="checkbox" value={10} checked={filters.indexOf(10) > -1} />
                                        <label htmlFor="FilterNotes">Notes</label>
                                    </div>
                                )}
                                <Button className="u-m-top-2" onClick={() => fetchLogs({
                                    variables: {
                                        id: student.id,
                                        startDate: moment(Date.now()).format("DD/MM/YYYY"),
                                        duration: 7,
                                        activityType: filters
                                    }
                                })}>{loadingMore ? t('loading') : t('Apply')}</Button>
                            </div>

                            {userPermissions && userPermissions.canDownloadStudentData && (
                                <div className='u-text-center'>
                                    <Button className='u-m-top-2 u-display-block u-full-width' outline onClick={downloadData}>Download activity</Button>
                                </div>
                            )}

                        </div>
                    </div>
                    {activityFeed.length > 0 && (
                        showLoadMore ? <Button className={styles.loadMoreActivity} onClick={() => loadMoreLogs()}>{loadingMore ? t('loading') : t('load_more')}</Button> :
                            <p className={styles.loadMoreActivity}>No more items to load</p>
                    )}
                </>
            }
        </>
    )
}

export default Activity
