import React, { useState } from 'react';
import styles from './BandingInfo.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, FormErrors } from '@axeedge/go-teacher-components';

const EditBandRank = ({ bandId, rank, cancel, formErrors, update }) => {

    const { t } = useTranslation();
    const [tempRank, setTempRank] = useState(rank);
    const [nameError, setNameError] = useState(false);

    const saveBandRank = () => {
        rank.id ?
            update({
                variables: {
                    readingBandRankId: rank.id,
                    name: tempRank.name,
                    colourCode: `rgb(${tempRank.r}, ${tempRank.g}, ${tempRank.b})`
                }
            }) : update({
                variables: {
                    readingBandId: bandId,
                    name: tempRank.name,
                    colourCode: `rgb(${tempRank.r}, ${tempRank.g}, ${tempRank.b})`
                }
            });
    }

    return (
        <div>
            <h3 className='u-m-base-4 u-text-center'>{rank.id ? t('edit_band') : t('add_band')}</h3>
            <div className={styles.bandNameEdit}>
                <input
                    className='basic-form__text-box'
                    type="text"
                    placeholder="Enter Band Name"
                    defaultValue={tempRank.name} 
                    onChange={(e) => {
                        setTempRank({ ...tempRank, name: e.target.value });
                        nameError && setNameError(null);
                    }}
                />
                <div className={styles.bandRankColor} style={{ background: `rgb(${tempRank.r}, ${tempRank.g}, ${tempRank.b})` }}></div>
            </div>
            {nameError && <p className='basic-form__hint u-m-top-1'>{t('please_enter_a_name')}</p>}
            <div className={styles.colorSet}>
                <label className={styles.colorLabel}>{t('red')}</label>
                <input type="range" min="0" max="255" value={tempRank.r} onChange={(e) => setTempRank({ ...tempRank, r: e.currentTarget.value })} />
            </div>
            <div className={styles.colorSet}>
                <label className={styles.colorLabel}>{t('green')}</label>
                <input type="range" min="0" max="255" value={tempRank.g} onChange={(e) => setTempRank({ ...tempRank, g: e.currentTarget.value })} />
            </div>
            <div className={styles.colorSet}>
                <label className={styles.colorLabel}>{t('blue')}</label>
                <input type="range" min="0" max="255" value={tempRank.b} onChange={(e) => setTempRank({ ...tempRank, b: e.currentTarget.value })} />
            </div>
            { formErrors && <FormErrors errors={formErrors} />}
            <div className='u-display-flex u-justify-center u-m-top-3'>
                <Button className='u-m-right-2' onClick={() => {
                    if (tempRank.name.length === 0) {
                        setNameError(true);
                    } else {
                        saveBandRank();
                    }
                }}>{rank.id ? t('button:update') : t('button:add')}</Button>
                <Button outline onClick={() => cancel()}>{t('button:cancel')}</Button>
            </div>
        </div>

    )
}

export default EditBandRank;