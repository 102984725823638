import React, { useEffect, useState, useRef } from "react";
import { ChevronDown } from "react-feather";
import { Dropdown } from "@axeedge/go-teacher-components";

import Tab from '../../../../../components/Tab';
import Tabs from '../../../../../components/Tabs';
import { monthNames, radiusOpts, lineColors } from "../../../services/utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from '../SchoolData.module.scss';
import cx from 'classnames';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



const ReadingLogsLineChart = ({ sourceData }) => {

    //console.log('sourceData', sourceData);

    const chartRef = useRef(null);


    const [viewType, setViewType] = useState('monthly');
    const [currentTab, setCurrentTab] = useState('total');
    const [selectedData, setSelectedData] = useState(sourceData.monthlyReportData);

    useEffect(() => {
        if (viewType === 'monthly') {
            setSelectedData(sourceData.monthlyReportData);
        } else if (viewType === 'yearly') {
            setSelectedData(sourceData.academicYearReports.sort((a, b) => a.academicYear - b.academicYear));
        }
        chartRef.current.update();

    }, [viewType, setSelectedData]);

    const displayOptions = [
        { text: 'Monthly', type: 'monthly', action: () => setViewType('monthly'), styleName: cx({ 'u-text-primary': viewType === 'monthly' }) },
        { text: 'Yearly', type: 'yearly', action: () => setViewType('yearly'), styleName: cx({ 'u-text-primary': viewType === 'yearly' }) },

    ];
    const viewTitle = displayOptions.find(o => o.type === viewType)?.text || '';

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                offset: selectedData.length === 0 ? true : false
            }
        }
    };


    const monthNumbers = sourceData.monthlyReportData.map(mr => mr.month);
    const labels = viewType === 'yearly' ? selectedData.map(item => item.academicYear) : monthNumbers.map(month => monthNames[month - 1]);

    const dataLogs = {
        labels,
        datasets: [
            {
                label: 'Reading Logs',
                data: selectedData.map(item => item.totalLogs),
                ...lineColors[0],
                ...radiusOpts
            },
        ],
    };

    const dataBooks = {
        labels,
        datasets: [
            {
                label: 'Books Completed',
                data: selectedData.map(item => item.totalBooksCompleted),
                ...lineColors[0],
                ...radiusOpts
            },
        ],
    }

    const breakdownLogsData = {
        labels,
        datasets: [
            {
                label: 'Parent Logs',
                data: selectedData.map(item => item.parentLogs),
                ...lineColors[0],
                ...radiusOpts
            },

            {
                label: 'Student Logs',
                data: selectedData.map(item => item.studentLogs),
                ...lineColors[1],
                ...radiusOpts
            },
            {
                label: 'Teacher Logs',
                data: selectedData.map(item => item.teacherLogs),
                ...lineColors[2],
                ...radiusOpts
            },
        ],
    }

    const breakDownBooksData = {
        labels,
        datasets: [
            {
                label: 'Group Books',
                data: selectedData.map(item => item.groupBooksCompleted),
                ...lineColors[0],
                ...radiusOpts
            },

            {
                label: 'Home Books',
                data: selectedData.map(item => item.homeBooksCompleted),
                ...lineColors[1],
                ...radiusOpts
            },
            {
                label: 'Library Books',
                data: selectedData.map(item => item.libraryBooksCompleted),
                ...lineColors[2],
                ...radiusOpts
            },
            {
                label: 'School Books',
                data: selectedData.map(item => item.schoolBooksCompleted),
                ...lineColors[3],
                ...radiusOpts
            },
        ],
    };

    const getChartData = () => {
        switch (currentTab) {
            case 'total':
                return dataLogs;
            case 'books':
                return dataBooks;
            case 'logsBreakdown':
                return breakdownLogsData;
            case 'booksBreakdown':
                return breakDownBooksData
            default:
                return dataLogs;
        }
    }

    return (

        <>
            <Tabs className='u-m-base-3'>
                <Tab selected={currentTab === 'total'} onClick={() => setCurrentTab('total')}>Total Reading logs</Tab>
                <Tab selected={currentTab === 'books'} onClick={() => setCurrentTab('books')}>Books completed</Tab>
                <Tab selected={currentTab === 'logsBreakdown'} onClick={() => setCurrentTab('logsBreakdown')}>Reading logs breakdown</Tab>
                <Tab selected={currentTab === 'booksBreakdown'} onClick={() => setCurrentTab('booksBreakdown')}>Books completed breakdown</Tab>
            </Tabs>

            <div className="u-flex-center u-m-base-2 u-justify-between">
                {currentTab === 'total' || currentTab === 'logsBreakdown' ?
                    <h4 className="u-m-base-0 u-bold">Number of reading logs</h4> :
                    <h4 className="u-m-base-0 u-bold">Books completed</h4>
                }
                <span className="small u-text-muted">Data is calculated at the end of each day</span>
            </div>

            <div className="u-flex-center u-m-base-3">
                Display:
                <Dropdown icon={ChevronDown} dropdownOpts={displayOptions} classNames={styles.viewOptionBtn} leftAligned titleLeft={viewTitle} />
            </div>

            <Line ref={chartRef} options={options} data={getChartData()} />

        </>
    )
}

export default ReadingLogsLineChart;