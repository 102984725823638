import React from 'react';
import styles from '../../../DataDashboard.module.scss';
import cx from 'classnames';
import { ArrowDown, ArrowUp, Minus } from 'react-feather';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';

const YearGroupItem = ({ yearData, threshold }) => {

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }
    return (
        <div className={styles.clsPanel}>
            <div className="card">
                <div className={cx("card-body", styles.clsStats)}>
                    <div className={styles.clsStatsPerc}>
                        <div className={styles.clsStatsPercData}>
                            <div className={styles.clsStatsPercNow}>
                                {yearData.percentage}<span>%</span>
                            </div>
                            <div className={styles.clsStatsPercPrev}>
                                {yearData.previousWeekPercentage < yearData.percentage ? <ArrowUp /> : yearData.previousWeekPercentage > yearData.percentage ? <ArrowDown /> : <Minus size="18" />}
                                {yearData.previousWeekPercentage}%
                            </div>
                        </div>
                        <p className='small u-text-center'>met target reads</p>
                    </div>
                    <div className={styles.clsStatsMain}>
                        <h3>{getYearGroupName(yearData.year)}</h3>
                        <ul className={styles.clsStatsList}>
                            <li>
                                <div className={styles.clsStatsListNo}>
                                    {yearData.studentsAboveThresholdCounter} / {yearData.studentsTotal}
                                </div>
                                <p>{threshold}+ log</p>
                            </li>
                            <li>
                                <div className={styles.clsStatsListNo}>
                                    {yearData.averageCounterPerStudent}
                                </div>
                                <p>avg/pupil</p>
                            </li>
                            <li>
                                <div className={styles.clsStatsListNo}>
                                    {yearData.totalLogs}
                                </div>
                                <p>total logs</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default YearGroupItem;