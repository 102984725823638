import React, { useState } from "react";
import { Loader } from "@axeedge/go-teacher-components";
import { ArrowLeft } from "react-feather";
import { COMPARE_CLASSES_BY_ID, COMPARE_YEAR_GROUPS_BY_ID } from "../../../services/graphql";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import CompareDataContent from "./CompareDataContent";
import moment from "moment";

import styles from '../SchoolData.module.scss';

const CompareData = ({ compareItem, isFromClass, currentUser, close, selectedItems }) => {

    const currentAcadYear = moment().toDate().getMonth() >= 8 ? moment().year() + 1 : moment().year();
    const [showFilters, setShowFilters] = useState(false);

    const [stateData, setStateData] = useState(null);

    const { data, loading, error } = useQuery(COMPARE_CLASSES_BY_ID, {
        variables: {
            schoolCode: currentUser?.school?.schoolCode,
            academicYear: currentAcadYear,
            ids: [compareItem.id, ...selectedItems]
        },
        skip: !isFromClass,
        onCompleted: (data) => {
            if (data?.schoolByCode?.classesByIds) {
                setStateData(data.schoolByCode.classesByIds)
            }
        }
    });

    const { data: dataYg, loading: loadingYg, error: errorYg } = useQuery(COMPARE_YEAR_GROUPS_BY_ID, {
        variables: {
            schoolCode: currentUser?.school?.schoolCode,
            academicYear: currentAcadYear,
            ids: [compareItem.id, ...selectedItems]
        },
        skip: isFromClass,
        onCompleted: (data) => {
            if (data?.schoolByCode?.yearGroupsByIds) {
                setStateData(data.schoolByCode.yearGroupsByIds)
            }
        }
    });

    const [filterClassesData, { loading: filteringClassesData }] = useLazyQuery(COMPARE_CLASSES_BY_ID, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data?.schoolByCode?.classesByIds) {
                setShowFilters(false);
                setStateData(data.schoolByCode.classesByIds);
            }
        }
    });

    const [filterYgData, { loading: filteringYgData }] = useLazyQuery(COMPARE_YEAR_GROUPS_BY_ID, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data?.schoolByCode?.yearGroupsByIds) {
                setShowFilters(false);
                setStateData(data.schoolByCode.yearGroupsByIds);
            }
        },
    });

    const applyFilters = (data) => {

        const filtersArray = Object.entries(data).map(([category, { values }]) => ({
            demographicsCategory: category,
            values
        })).filter(el => el.values.length !== 0);

        isFromClass ?
            filterClassesData({
                variables: {
                    schoolCode: currentUser?.school?.schoolCode,
                    academicYear: currentAcadYear,
                    ids: [compareItem.id, ...selectedItems],
                    filters: filtersArray
                }
            })
            :
            filterYgData({
                variables: {
                    schoolCode: currentUser?.school?.schoolCode,
                    academicYear: currentAcadYear,
                    ids: [compareItem.id, ...selectedItems],
                    filters: filtersArray
                }
            })
    }


    if (loading || loadingYg) {
        return <Loader />
    }

    if (error || errorYg) {
        return <p>{error.message}</p>
    }

    if (data && data.schoolByCode && stateData) {
        return (
            <>
                <div className={styles.dashWrapper}>
                    <div className="u-flex-center u-m-base-3">
                        <button onClick={close} className="btn-reset u-m-right-2 u-flex-center"><ArrowLeft /></button>
                        <h3 className="">{compareItem.name}</h3>
                    </div>
                    <CompareDataContent
                        loading={filteringClassesData || filteringYgData}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters}
                        onApplyFilters={applyFilters}
                        compareItem={compareItem}
                        reportDataItems={stateData}
                        currentUser={currentUser}
                    />
                </div>
            </>
        )
    }

    if (dataYg && dataYg.schoolByCode && stateData) {
        return (
            <>
                <div className={styles.dashWrapper}>
                    <div className="u-flex-center u-m-base-3">
                        <button onClick={close} className="btn-reset u-m-right-2 u-flex-center"><ArrowLeft /></button>
                        <h3 className="">{compareItem.name}</h3>
                    </div>
                    <CompareDataContent
                        loading={filteringClassesData || filteringYgData}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters}
                        onApplyFilters={applyFilters}
                        compareItem={compareItem}
                        reportDataItems={stateData}
                        currentUser={currentUser}
                    />
                </div>
            </>
        )
    }

    return null;
}

export default CompareData;