import React, { useContext, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import ls from 'local-storage';

import { Loader } from '@axeedge/go-teacher-components';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

import { AuthContext } from '../../../services/auth/AuthProvider';
import { GET_USER, CHECK_DOWNLOAD_PERMISSIONS } from '../services/graphql';

const UserQueryHolder = () => {
    const { data, error, loading } = useQuery(GET_USER);
    const { currentUser, setCurrentUser, userPermissions, setUserPermissions} = useContext(AuthContext);


    const [checkDataPermissions] = useLazyQuery(CHECK_DOWNLOAD_PERMISSIONS, {
        onCompleted: (data) => {
            if (data.currentTeacher && data.currentTeacher.id ) {
                setUserPermissions({ ...userPermissions, canManageStudentNotes: data.currentTeacher.canManageStudentNotes, canDownloadStudentData: data.currentTeacher.canDownloadStudentData })
            }
        }
    })

    useEffect(() => {
        if (currentUser && currentUser.school && userPermissions === null) {
            checkDataPermissions({
                variables: {
                    schoolId: currentUser.school.id
                }
            })
        }
    }, [currentUser])

    useEffect(() => {
        if (data && data.currentTeacher && data.currentTeacher.id && !currentUser && ls('go_teacher_user') !== 'undefined') {
            setCurrentUser(data.currentTeacher);
        }
    }, [currentUser, data, setCurrentUser])

    if (data && data.currentTeacher && data.currentTeacher.id && !data.currentTeacher.school.canAccessGoReadApp) {
        window.location = `${getUrl(APPS.go_portal_teacher)}/goread`;
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    return null;
}

export default UserQueryHolder;

