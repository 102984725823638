import React from "react";

const SingleRow = ({ stData, originY, maxNumber, roundedMax, chartWidth }) => {

    const bar1Width = maxNumber > 0 ? (stData.counter / roundedMax * chartWidth) : 1;
    const bar2Width = maxNumber > 0 ? (stData.previousYearCounter / roundedMax * chartWidth) : 1;

    return (
        <g className="bar">
            <g className='bars'>
                <rect fill="#C33764" x={0} y={originY + 5} width={bar1Width} height="5" rx='3'></rect>
                <rect fill="#C6CBB5" x={0} y={originY + 13} width={bar2Width} height="5" rx='3'></rect>
            </g>
        </g>
    )
}

export default SingleRow;