import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import { GET_STUDENT_QUERY } from './services/graphql';
import StudentContent from './Components/studentContent';

const Student = (props) => {

    const { data, error, loading } = useQuery(GET_STUDENT_QUERY, {
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data) {
        return (
            <>
                <StudentContent
                    student={data.student}
                    classInfo={props.location && props.location.state && props.location.state.classInfo}
                    classId={props.location && props.location.state && props.location.state.classId}
                    showNotes={props.location && props.location.state && props.location.state.showNotes }
                />
            </>
        );
    }

}

export default Student;