import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { REGENERATE_LOGIN_CODE } from '../services/graphql';
import { useTranslation } from 'react-i18next';


const LoginCode = ({ student, closeModal, setStudentState }) => {

    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);

    const [codeRefreshed, setCodeRefreshed] = useState(null);

    const [regenerateStudentLoginCode, { loading }] = useMutation(REGENERATE_LOGIN_CODE, {
        update: (_, { data }) => {
            if (data.regenerateStudentLoginCode.errors && data.regenerateStudentLoginCode.errors.length !== 0) {
                setFormErrors(data.regenerateStudentLoginCode.errors);
                return;
            }
            if (data.regenerateStudentLoginCode.student.id) {
                setCodeRefreshed(data.regenerateStudentLoginCode.student)
            }  
        }      
    });

    if (codeRefreshed) {
        return(
            <Fragment>
                <h1 className='u-m-base-10 title'>{t('login_code_refreshed')}</h1>
                <p className='u-m-base-20'>{t('pass_on_code_for', {studentName: codeRefreshed.name})}:</p>
                <h2 className='u-m-base-20 title'>{t('new_login_code')}: <b className='heavy'>{codeRefreshed.loginCode}</b></h2>
                <Button
                    outline
                    onClick={() => {
                        closeModal();
                        setCodeRefreshed(null);
                    }}
                >{t('button.done')}</Button>
            </Fragment>
        )
    }

    return(
        <Fragment>
            <h1 className='u-m-base-10 title'>Refresh Login Code</h1>
            <p className='u-m-base-20'>We'll generate a new login code for {student.name}. Once refreshed, {student.name} will need to use the new code to be able to login.</p>
            <Button
                onClick={() => regenerateStudentLoginCode({
                    variables: {
                        studentId: student.id
                    }
                })}
            >{loading ? t('please_wait') : t('refresh_login_code')}</Button>
            <Button
                outline
                className='u-m-left-2'
                onClick={() => closeModal()}
            >{t('button.cancel')}</Button>
            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
        </Fragment>
    )
}

export default LoginCode;