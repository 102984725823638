import React from 'react';
import * as Icon from 'react-feather';
import _ from 'lodash';
import styles from '../../scenes/Student/Student.module.scss'
const ViewLog = ({ location }) => {
    const {log} = location.state;
    return (
        <div className="u-padding-20">
            <p>Logged by: {log.author.name}</p>
            <p className="u-m-top-20">Added: {log.readingDate}</p>
            <p className="u-m-top-20"><Icon.FileText size="16"/> {log.pageStart} - {log.pageEnd}</p>
            {log.entry &&
                <>
                    <p className="u-m-top-20">Comment:</p>
                    <p className="u-m-top-10">{log.entry}</p>
                </>
            }
            {log.problemWords.length > 0 &&
                <>
                    <p className="u-m-top-20">Problem Words:</p>
                    <ul className={styles.probWordList}>
                        {_.map(log.problemWords, word => {
                            return <li key={`problemWord${word.id}`} className={styles.probWord}>{word.word}</li>
                        })}
                    </ul>
                </>
            }
        </div>
    )
}

export default ViewLog;