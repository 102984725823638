import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { RB_COMPLETE } from '../../utils/constants';
import { GET_GROUP_BOOK } from '../../scenes/Student/services/graphql';
import { EDIT_GROUP_LOG, REMOVE_FROM_GROUP, EDIT_GROUP_BOOK, DELETE_GROUP_BOOK, DELETE_GROUP_LOG, GET_CLASS_QUERY } from './services/graphql'
import { GET_GROUPS } from '../../scenes/ClassNew/components/Groups/services/graphql'
import { ASSIGN_STUDENTS_TO_GROUP } from '../BookSearch/services/graphql'
import styles from '../../scenes/Student/Student.module.scss';
import logStyles from '../LogReading/LogReading.module.scss'
import cx from 'classnames'
import * as Icon from 'react-feather'
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import LogNew from '../LogReading/LogNew'
import loader from '../../images/loader.svg';
import TypeLabel from '../BookTypeLabel';
import { AuthContext } from '../../services/auth/AuthProvider';
import LogAcknowledgement from '../../scenes/Student/Components/LogAcknowledgement';

const AddLog = ({ selectedBook, type, setSelectedBook, toggleComplete, initiateCompleteGroup, classId, filter, classData, setAddNewBookToGroup }) => {
    const book = selectedBook.book;
    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;
    const [showEditSuccess, setShowEditSuccess] = useState(false);
    const [showLogSuccess, setShowLogSuccess] = useState(false);
    const [showLogDeleteSuccess, setShowLogDeleteSuccess] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)

    const [actionFilter, setActionFilter] = useState(showEditSuccess ? 'BOOKINFO' : (book.status === RB_COMPLETE || showLogSuccess || showLogDeleteSuccess || selectedBook.history) ? "HISTORY" : "LOG");
    const [recentLogs, setRecentLogs] = useState([])
    const [selectedLog, setLog] = useState(null);

    const [rsError, setRsError] = useState(false)
    const [rsSuccess, setRsSuccess] = useState(false)
    const [assignError, setAssignError] = useState(false)

    const [initiateComplete, setInitiateComplete] = useState(initiateCompleteGroup);
    const [showDeleteGroupBook, setShowDeleteGroupBook] = useState(false)
    const [deleteErrors, setDeleteErrors] = useState([]);
    const [showAddPupils, setShowAddPupils] = useState(false);

    const [selectedStudents, setSelectedStudents] = useState([])

    const klsList = teacher.isSchoolAdmin ? teacher.school?.classes : [...teacher.studentsClasses, ...teacher.visibleStudentsClasses];
    const [selectedClass, setSelectedClass] = useState(klsList.sort((a, b) => a.name.localeCompare(b.name))[0]?.id)
    const [classStudents, setClassStudents] = useState([]);

    useEffect(() => {
        setInitiateComplete(initiateCompleteGroup)
    }, [initiateCompleteGroup])

    const [showCompleteLogSuccess, setShowCompleteLogSuccess] = useState(false)


    const [editErrors, setEditErrors] = useState(false);
    const { t } = useTranslation();
    const [editBook, setEditBook] = useState({
        //if group book set type as group by default
        bookType: type === 4 ? 4 : book.bookType,
        commentsEnabled: !book.hideComments
    });

    let endDate = moment(book.completedAt);

    const [logFilters, setLogFilters] = useState(['Teacher', 'Parent', 'Student']);

    const onClickEdit = (log) => {
        setActionFilter("EDIT");
        setLog(log);
    }

    const [selectedStudent, setSelectedStudent] = useState(null);


    const [getStudents, { loading: loadingStudents }] = useLazyQuery(GET_CLASS_QUERY, {
        onCompleted: data => {
            setClassStudents(data.studentsClass.students);
        },
        fetchPolicy: 'network-only'
    })

    const [fetchGroupLogs, { data: logData, loading: groupLoading }] = useLazyQuery(GET_GROUP_BOOK, {
        onCompleted: data => {
            if (data && data.groupBook && data.groupBook.logs) {
                setRecentLogs(_.reverse(_.sortBy(data.groupBook.logs, ['date'])))
            }
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (actionFilter === "HISTORY") {
            fetchGroupLogs({ variables: { id: book.id } })
        }
        setRsSuccess(false)
        setSelectedStudent(null)
    }, [actionFilter])

    useEffect(() => {
        if (showAddPupils) {
            getStudents({ variables: { id: selectedClass } })
        }
    }, [showAddPupils, selectedClass])

    const handleFilter = filter => {
        if (logFilters.indexOf(filter) !== -1) {
            const newLogs = logFilters.filter(f => f !== filter);
            setLogFilters(newLogs);
        } else {
            const newL = [...logFilters, filter];
            setLogFilters(newL);
        }
    }

    const onSelectStudent = e => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(s => s !== e.target.value));
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
        }
    }

    const onSelectAllStudents = e => {
        const stIds = sortExistingStudents().map(student => student.id);
        e.target.checked ? setSelectedStudents(stIds) : setSelectedStudents([])
    }



    const [editStudentReadingBook, { loading: editing }] = useMutation(EDIT_GROUP_BOOK, {
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_GROUPS,
                variables: {
                    id: classId,
                    status: filter,
                }
            }
        ],
        onCompleted: (data) => {
            if (data.editGroupReadingBook.errors && data.editGroupReadingBook.errors.length > 0) {
                setEditErrors(true);
                return
            }
            if (data.editGroupReadingBook && data.editGroupReadingBook.groupReadingBook && data.editGroupReadingBook.groupReadingBook.id) {
                setEditErrors(false);
                setShowEditSuccess(true);
            }
        }
    });

    const [deleteGroupBook, { loading: deleteBookLoading }] = useMutation(DELETE_GROUP_BOOK, {
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_GROUPS,
                variables: {
                    id: classId,
                    status: filter,
                }
            }
        ],
        variables: {
            groupReadingBookId: book.id,
        },
        onCompleted: (data) => {
            if (data.deleteGroupReadingBook.errors && data.deleteGroupReadingBook.errors.length > 0) {
                setDeleteErrors(data.deleteGroupReadingBook.errors)
                return null
            }
            if (data.deleteGroupReadingBook.deleted) {
                setSelectedBook({ open: false, book: null })
            }
        }
    })

    const [assignStudentsToGroup, { loading: assignLoading }] = useMutation(ASSIGN_STUDENTS_TO_GROUP, {
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_GROUPS,
                variables: {
                    id: classId,
                    status: filter,
                }
            }
        ],
        variables: {
            groupId: book.group.id,
            studentIds: selectedStudents
        },
        onCompleted: (data) => {
            if (data.assignStudentsToGroup.errors && data.assignStudentsToGroup.errors.length > 0) {
                setAssignError(true)
                return null
            }
            if (data.assignStudentsToGroup.group) {
                setAssignError(false)
                setSelectedStudents([])
                setShowAddPupils(false)
                return null;
            }
            return null;
        }
    })



    const MAX_DAYS_AGO = 7;

    const displayLogAuthor = author => {
        switch (author.role) {
            case 'Parent':
                return author.displayName
            case 'Teacher':
                return teacher && teacher.id === author.id ? t('you') : author.displayName
            case 'Student':
                return author.displayName //TODO: show avatar
            default:
                return '-'
        }
    }
    const sortExistingStudents = () => {
        const addableStudents = []
        const currentStudents = []
        book.students.forEach(s => {
            currentStudents.push(s.id)
        })
        classStudents.forEach(s => {
            if (currentStudents.includes(s.id)) {
                return null;
            } else {
                addableStudents.push(s)
            }
        })
        return addableStudents;
    }

    useEffect(() => {
        if (logData && logData.groupBook)
            setRecentLogs(_.reverse(_.sortBy(logData.groupBook.logs, ['date'])))
    }, [logData]
    )


    const Log = ({ log, action, teacher, book }) => {
        const intersectionRef = useRef(null);

        var date = moment(log.readingDate);
        return (
            <li ref={intersectionRef} className={styles.logListItem} >
                <div className={styles.logListItemHeader}>
                    <div className={styles.logListItemHeaderMain}>
                        <span className={styles.logListItemHeaderDate}>{date.format("DD MMM YY")}</span>
                        <span className={styles.logListItemHeaderPages}><Icon.FileText size="20" className='u-text-muted u-mr-2' /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}</span>
                        {log.studentIds && log.studentIds.length > 1 && <span className={styles.logListItemHeaderUsers}><Icon.Users className='u-text-muted u-m-l-2' /></span>}
                        <LogAcknowledgement
                            intersectionRef={intersectionRef}
                            classId={classId}
                            log={log}
                            teacher={teacher}
                            book={book}
                            fromGroup={true}
                        />
                    </div>
                    <div>{displayLogAuthor(log.author)}</div>
                </div>

                {log.entry !== "" &&
                    <div className={styles.logListSection}>
                        <p>{log.entry}</p>
                    </div>
                }
                {log.problemWords.length > 0 && (
                    <div className={styles.logListSection}>
                        {log.problemWords.map((w, i) => <span key={`word-${i}`} className='label label-dark u-m-right-5'>{w.word}</span>)}
                    </div>
                )}
                <Icon.Settings onClick={() => { action(log) }} className={styles.logListItemCog} />
            </li>
        )
    }

    const words = (problemWords) => {
        let words = [];
        _.map(problemWords, word => {
            return words.push(word.word);
        })
        return words;
    }

    const [removeFromGroup, { loading: removeLoading }] = useMutation(REMOVE_FROM_GROUP, {
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_GROUPS,
                variables: {
                    id: classId,
                    status: filter,
                }
            }
        ],
        update: (_, { data }) => {
            if (data.removeStudentFromGroupReadingBook.errors && data.removeStudentFromGroupReadingBook.errors.length != 0) {
                setRsError(true)
                return;
            }
            if (data.removeStudentFromGroupReadingBook.deleted) {
                setSelectedStudent(null)
                return
            }
        }
    })

    const EditLog = ({ log, close, book }) => {
        const [formErrors, setFormErrors] = useState(false);
        const [confirmLogDelete, setConfirmLogDelete] = useState(null);

        const [form, setForm] = useState({
            comment: log.entry ? log.entry : '',
            problemWords: log.problemWords ? words(log.problemWords) : [],
        });

        const [editLog, { loading }] = useMutation(EDIT_GROUP_LOG, {
            awaitRefetchQueries: true,
            update: (_, { data }) => {
                if (data.editGroupReadingBookLog.errors && data.editGroupReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.editGroupReadingBookLog) {
                    setActionFilter("HISTORY")
                    setShowLogSuccess(true);
                }
            },
            variables: {
                groupReadingBookLogId: log.id,
                entry: form.comment,
                problemWords: form.problemWords
            },
            refetchQueries: [{ query: GET_GROUP_BOOK, variables: { id: book.id } }],
        });

        const [deleteLog, { loading: deletingLog }] = useMutation(DELETE_GROUP_LOG, {
            awaitRefetchQueries: true,
            update: (_, { data }) => {
                if (data.deleteGroupReadingBookLog.errors && data.deleteGroupReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.deleteGroupReadingBookLog && data.deleteGroupReadingBookLog.deleted) {
                    setActionFilter("HISTORY")
                    setShowLogDeleteSuccess(true);
                    setConfirmLogDelete(false);
                }
            },
            variables: {
                groupReadingBookLogId: log.id,
            }
        });

        const handleEdit = (e) => {
            e.preventDefault();
            editLog();
        }

        let date = moment(log.readingDate);

        const tagInput = useRef(null);

        const addWordTags = e => {
            const val = e.target.value;
            if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
                e.preventDefault();
                if (form.problemWords.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                    return;
                }
                setForm({
                    ...form,
                    problemWords: [...form.problemWords, val]
                });
                tagInput.current.value = null;
            }
        };

        const removeWordTag = i => {
            const newWords = [...form.problemWords];
            newWords.splice(i, 1);
            setForm({
                ...form,
                problemWords: newWords
            });
        };

        return (
            <>
                <h1 className={cx(styles.bookSubHeading, "u-m-top-20")} onClick={close}><Icon.ArrowLeft className={styles.bookSubHeadingIcon} /> {t('history')}</h1>
                <div className={cx("u-m-top-20 u-m-base-20", styles.editHeader)}>
                    <div className={cx(styles.editMeta, "u-m-right-20")}>
                        <Icon.User className="u-m-right-5" /> {displayLogAuthor(log.author)}
                    </div>
                    <div className={styles.editMeta}>
                        <Icon.FileText className="u-m-right-5" /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}
                    </div>
                    <div className={cx(styles.editMeta, styles.editDate)}>
                        {date.format("DD MMM YY")}
                    </div>
                </div>
                <div className={styles.editPanel}>
                    <form onSubmit={(e) => handleEdit(e)}>
                        <label>{t('comment')}:</label>
                        <textarea className={cx(styles.logReadingComment, 'u-m-top-10 basic-form__text-box')} rows="3" placeholder={t('write_your_comment_here')} value={form.comment} onChange={(e) => setForm({ ...form, comment: e.currentTarget.value })}></textarea>
                        <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                            <input
                                type="text"
                                placeholder={t('separate_words_comma_enter')}
                                className='basic-form__text-box'
                                onKeyPress={(e) => addWordTags(e)}
                                ref={tagInput}
                            />
                        </div>
                        <div className={cx(logStyles.logReadingField, 'u-m-top-10 u-m-base-20')}>
                            {_.map(form.problemWords, (word, i) => {
                                return (
                                    <span className={logStyles.logReadingProbWord} key={`probWord${i}`}>
                                        {word}
                                        <button className={logStyles.logReadingProbWordDelete} type="button" onClick={() => removeWordTag(i)}><Icon.X size="12" /></button>
                                    </span>
                                )
                            })}
                        </div>
                        {formErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong_try_again')}</p>}
                        <Button className="u-m-right-10" disabled={loading}>{loading ? t('updating') : t('button.update')}</Button>
                        <Button outline onClick={close}>{t('button.cancel')}</Button>
                    </form>
                </div>
                <Button className='btn-danger u-m-top-3' onClick={() => setConfirmLogDelete(true)}>{deletingLog ? t('saving') : t('button.delete')}</Button>

                {confirmLogDelete &&
                    <Modal closeModal={() => setConfirmLogDelete(false)}>
                        <div className='u-text-center'>
                            <h3>{t('are_you_sure_you_want_delete_log')}</h3>
                            <Button
                                className='u-m-right-1 u-m-top-3'
                                onClick={() => deleteLog()}>{deletingLog ? `${t('saving')}...` : t('button.yes')}</Button>
                            <Button className='btn-danger' onClick={() => setConfirmLogDelete(false)}>{t('button.no')}</Button>
                        </div>
                    </Modal>
                }
            </>
        )
    }

    return (
        <>
            {!showAddPupils &&
                <>
                    <h1 className={cx(styles.bookHeading, "u-m-base-5")}>{book.book.title}</h1>
                    <TypeLabel type={book.bookType ? book.bookType : 'NewGroup'} />
                    {book.status === RB_COMPLETE && (
                        <Button className='u-m-left-2' outline
                            onClick={() => {
                                setAddNewBookToGroup(book.group);
                                setSelectedBook({ book: null, open: false });
                            }}>Add new book</Button>
                    )}
                </>
            }
            {(actionFilter !== 'EDIT' && actionFilter !== 'LOGAFTER' && !showAddPupils) &&
                <div className={cx(styles.bookFilters, styles.bookFiltersLog, "u-m-top-20 u-m-base-20")}>
                    {book.status !== RB_COMPLETE && <button className={styles.filter} onClick={() => { setActionFilter('LOG'); setShowEditSuccess(false) }}><span className={cx(styles.filterInner, actionFilter === 'LOG' ? styles.filterInnerActive : null)}>{t('log_reading')}</span></button>}
                    <button className={styles.filter} onClick={() => setActionFilter('HISTORY')}><span className={cx(styles.filterInner, actionFilter === 'HISTORY' ? styles.filterInnerActive : null)}>{t('history')}</span></button>
                    <button className={styles.filter} onClick={() => setActionFilter('PUPILS')}><span className={cx(styles.filterInner, actionFilter === 'PUPILS' ? styles.filterInnerActive : null)}>{t('pupils')}</span></button>
                    <button className={styles.filter} onClick={() => setActionFilter('BOOKINFO')}><span className={cx(styles.filterInner, actionFilter === 'BOOKINFO' ? styles.filterInnerActive : null)}>{t('settings')}</span></button>

                    {/*(book.status === RB_COMPLETE) &&
                        <Dropdown classNames={styles.bookFiltersLogAction}>
                            {book.status === RB_COMPLETE && 
                                <li><button onClick={() => setActionFilter("LOGAFTER")} className={styles.bookActionLog}><Icon.PlusCircle className={styles.bookActionLogIcon} />{t('add_log')}</button></li>
                            }
                        </Dropdown>
                        */}
                </div>
            }
            {actionFilter === 'LOG' &&
                <>
                    {(classId && book.students.length === 0) ?
                        <>
                            <p className="u-m-base-20">You need to re-add students to this Group Book before you can add a reading log.</p>
                            <Button onClick={() => { setActionFilter('PUPILS'); setShowAddPupils(true) }} outline>Add Students</Button>
                        </>
                        :
                        <>
                            {showSuccess ?
                                <>
                                    <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                                    <p className="u-m-base-20">Log Added</p>
                                    <Button onClick={() => setShowSuccess(false)}>{t('log_more')}</Button>
                                </> :
                                <>
                                    <LogNew initiateComplete={initiateComplete} initiateMethod={setInitiateComplete} toggleComplete={toggleComplete} book={book} recentLog={book.mostRecentLog && book.mostRecentLog.pageEnd} close={setSelectedBook} success={setShowSuccess} type={4} classId={classId} filter={filter} />
                                </>
                            }
                        </>
                    }
                </>
            }
            {actionFilter === 'PUPILS' &&
                <>
                    {!selectedStudent ?
                        <>
                            {showAddPupils ?
                                <>
                                    <h3 onClick={() => setShowAddPupils(false)} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', cursor: 'pointer' }}><Icon.ArrowLeft /> Add Pupils To Book</h3>
                                    <div style={{ display: 'flex' }} className="u-m-base-2">
                                        <select className='basic-form__text-select u-m-right-2' value={selectedClass} onChange={(e) => setSelectedClass(e.currentTarget.value)} >
                                        {klsList.sort((a, b) => a.name.localeCompare(b.name)).map(c => {
                                                return (
                                                    <option key={`class${c.id}`} value={c.id}>{c.name}</option>
                                                )
                                            })}
                                        </select>
                                        <Button onClick={() => assignStudentsToGroup()} disabled={assignLoading}>{assignLoading ? `${t('saving')}...` : 'Add To Book'}</Button>
                                    </div>
                                    {loadingStudents ? <img src={loader} alt="Loading" /> :
                                        <>
                                            {sortExistingStudents().length > 0 ?
                                                <>
                                                    <div className={logStyles.studentCountItem} style={{ marginBottom: '20px' }}>
                                                        <div className={logStyles.studentCounterSelect}>
                                                            <input
                                                                name="all"
                                                                className="basic-form__check-box"
                                                                type="checkbox"
                                                                checked={selectedStudents.length === sortExistingStudents().length}
                                                                onChange={(e) => onSelectAllStudents(e)}
                                                                id="selectAll"
                                                            />
                                                            <label className="basic-form__check-label" htmlFor='selectAll'>&nbsp;</label>
                                                        </div>
                                                        Select all
                                                    </div>
                                                    <ul className={cx("u-m-base-2", styles.addPupilList)}>
                                                        {sortExistingStudents().map(student => {
                                                            return (
                                                                <li className={logStyles.studentCountItem} key={`addStudent${student.id}`}>
                                                                    <div className={logStyles.studentCounterSelect}>
                                                                        <input
                                                                            name="student"
                                                                            className="basic-form__check-box"
                                                                            type="checkbox"
                                                                            value={student.id}
                                                                            onChange={(e) => onSelectStudent(e)}
                                                                            checked={selectedStudents.indexOf(student.id) > -1}
                                                                            id={`st_${student.id}`}
                                                                        />
                                                                        <label className="basic-form__check-label" htmlFor={`st_${student.id}`}>&nbsp;</label>
                                                                    </div>
                                                                    {student.firstName} {student.lastName}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </> : <p>There are no elegible pupils in this group.</p>

                                            }
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <Button onClick={() => setShowAddPupils(true)} outline>Add Pupils</Button>
                                    <ul>
                                        {book.students.map(student => {
                                            return (
                                                <li className={styles.studentLi} key={`removeStudent${student.id}`}>{student.firstName} {student.lastName}<Icon.XCircle style={{ marginLeft: 'auto' }} onClick={() => setSelectedStudent(student)} /></li>
                                            )
                                        })}
                                    </ul>
                                </>
                            }
                        </>
                        :
                        <>  {rsSuccess ?
                            <>
                                <p>You have succesfully removed {selectedStudent.firstName} {selectedStudent.lastName} from this group book.</p>
                            </>
                            :
                            <>
                                <p className="u-m-base-2">Are you sure you want to remove {selectedStudent.firstName} {selectedStudent.lastName} from this group book?</p>
                                <Button onClick={() => removeFromGroup({ variables: { groupReadingBookId: book.id, studentId: selectedStudent.id } })}>Yes</Button>
                                <Button className='btn-danger u-m-left-1' onClick={() => setSelectedStudent(null)}>No</Button>
                            </>
                        }
                        </>
                    }
                </>
            }
            {actionFilter === 'HISTORY' &&
                <>
                    {showCompleteLogSuccess ?
                        <>
                            <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                            <p className="u-m-base-20">Log added for this group</p>
                            <Button onClick={() => { setShowCompleteLogSuccess(false); setActionFilter("HISTORY") }}>{t('thanks')}</Button>
                        </> : <>
                            {book.createdAt && <p className="u-m-base-10">{t('book_assigned')}: {moment(book.createdAt).format("DD MMM YY")}</p>}
                            {book.status === RB_COMPLETE && <>
                                <p className="u-m-base-20">{t('completed')}: {endDate.format("DD MMM YY")}</p>
                                {(moment(new Date()).diff(moment(endDate), 'days') < MAX_DAYS_AGO) && (
                                    <p className='u-m-base-20'>{t('book_stays_in_current_listing_7_days')}</p>
                                )}

                            </>
                            }
                            {showLogDeleteSuccess &&
                                <p className={cx(styles.editMeta, "u-bold u-m-base-20")}><Icon.CheckCircle className="u-m-right-10" color="#6B9A11" />{t('log_delete_success')}</p>
                            }
                            {groupLoading ? <img src={loader} alt="Loading" /> :
                                <>
                                    {
                                        recentLogs.length !== 0 ? (
                                            <>
                                                <ul className={styles.logListFilters}>
                                                    <li>
                                                        <p onClick={() => handleFilter('Teacher')}>{logFilters.indexOf('Teacher') !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('teachers')}</p>
                                                    </li>
                                                    <li>
                                                        <p onClick={() => handleFilter('Parent')}>{logFilters.indexOf('Parent') !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('parents')}</p>
                                                    </li>
                                                    <li>
                                                        <p onClick={() => handleFilter('Student')}>{logFilters.indexOf('Student') !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('pupil')}</p>
                                                    </li>
                                                </ul>
                                                {showLogSuccess &&
                                                    <p className={cx(styles.editMeta, "u-bold u-m-base-10 u-m-top-10")}><Icon.CheckCircle className="u-m-right-10" color="#6B9A11" />{t('log_successfully_updated')}</p>
                                                }
                                                <ul className={styles.logList}>
                                                    {recentLogs.filter(l => logFilters.includes(l.author.role)).map(log => {
                                                        return <Log key={`activeBook${log.id}`} teacher={teacher} book={book} log={log} action={onClickEdit} />
                                                    })}
                                                </ul>
                                            </>
                                        ) : <h4>{t('no_logs_added_to_book')}</h4>
                                    }
                                </>}
                        </>}
                </>

            }
            {actionFilter === 'BOOKINFO' &&
                <>
                    <h2 className={cx(styles.bookHeading, "u-m-base-5")}>{book.book.title}</h2>
                    {book.book.author && <p>{book.book.author}</p>}
                    {book.book.isbn && <p className="u-m-top-20">ISBN: {book.book.isbn}</p>}
                    {book.book.isbn13 && <p>ISBN13: {book.book.isbn13}</p>}
                    {book.book.publisher && <p className="u-m-top-20">{book.book.publisher.name}</p>}
                    <div>
                        <div className='u-m-top-20 u-m-base-20 u-display-flex'>
                            <p className='u-m-right-10'>{t('pupil_comments_enabled')}</p>
                            <input type="checkbox" className={styles.switchToggle} id="switchc" checked={editBook.commentsEnabled} onChange={(e) => setEditBook({ ...editBook, commentsEnabled: !editBook.commentsEnabled })} value={editBook.commentsEnabled} />
                            <label className={styles.switchLabel} htmlFor="switchc">{t('toggle')}</label>
                        </div>
                    </div>
                    {editErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong_try_again')}</p>}

                    {showEditSuccess && <p className='u-bold u-m-base-2'>Changes saved!</p>}

                    <Button disabled={editing} onClick={() => editStudentReadingBook({
                        variables: {
                            groupReadingBookId: book.id,
                            hideComments: editBook.commentsEnabled ? false : true,
                        }
                    })}>{editing ? t('saving') : t('button.save_changes')}</Button>
                    <div className={styles.deleteBookPanel}>
                        <h2 className={cx(styles.deleteHeader, 'u-m-base-10')}>Delete Book?</h2>
                        <p className="u-m-top-10 u-m-base-10">You can delete this book if you are not planning on using it.</p>
                        <Button className='btn-danger' onClick={() => setShowDeleteGroupBook(true)}>{t('button.delete')}</Button>
                    </div>
                    {showDeleteGroupBook &&
                        <Modal closeModal={() => setShowDeleteGroupBook(false)}>
                            <div className='u-text-center'>
                                <p>Are you sure? Deleting this book will permanently remove it from the log</p>
                                {deleteErrors && deleteErrors.length > 0 && <FormErrors errors={deleteErrors} />}

                                <Button
                                    className='u-m-right-1 u-m-top-3'
                                    onClick={() => deleteGroupBook()}>{deleteBookLoading ? `${t('saving')}...` : t('button.yes')}</Button>
                                <Button className='btn-danger' onClick={() => setShowDeleteGroupBook(false)}>{t('button.no')}</Button>
                            </div>
                        </Modal>
                    }
                </>
            }
            {actionFilter === 'EDIT' &&
                <EditLog book={book} log={selectedLog} close={() => { setActionFilter("HISTORY"); setLog(null); }} />
            }
        </>
    )
}

export default AddLog