import React, { useState, Fragment, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_STUDENT_PROBLEM_WORDS, MARK_WORDS_AS_COMPLETED } from '../services/graphql';
import { Button, FormErrors, Loader } from '@axeedge/go-teacher-components';
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import styles from '../Student.module.scss';
import CompletedProblemWords from './completedProblemWords';
import _ from 'lodash';
import moment from 'moment';

// const WORD_STATUS = {
//     active: 1,
//     archived: 9,
//     completed: 10
// }

const DAYS_AGO = 14;

const AllProblemWords = ({ studentState }) => {

    const [showCurrent, setShowCurrent] = useState(true);
    const [baseWords, setBaseWords] = useState([]);
    const [completedWords, setCompletedWords] = useState([]);

    const [formErrors, setFormErrors] = useState([]);

    const [selectedWords, setSelectedWords] = useState([]);

    const groupWordsByDate = words => {
        const group = _.groupBy(words, ({ timestamp }) => moment(timestamp, 'DD-MM-YYYY').format('MMMM'));
        return Object.keys(group).map(month => ({ month, items: group[month] }));
    }

    const olderWords = words => {
        return words.filter(w => moment(w.timestamp, 'DD-MM-YYYY') < moment(new Date).subtract(DAYS_AGO, 'days'));
    }

    const onSelectWord = e => {
        if (selectedWords.indexOf(e.target.value) !== -1) {
            setSelectedWords(selectedWords.filter(s => s !== e.target.value));
        } else {
            setSelectedWords([...selectedWords, e.target.value]);
        }
    }

    const { data, error, loading } = useQuery(GET_STUDENT_PROBLEM_WORDS, {
        variables: {
            id: studentState.id,
            showAll: true
        },
    });

    useEffect(() => {
        if (data?.student?.mostRecentProblemWords) {
            setBaseWords(data.student.mostRecentProblemWords)
        }
        if (data?.student?.completedProblemWords) {
            setCompletedWords(data.student.completedProblemWords)
        }
    }, [data]);

    const [markProblemWordsAsCompleted, { loading: marking }] = useMutation(MARK_WORDS_AS_COMPLETED, {
        update: (_, { data }) => {
            if (data.markProblemWordsAsCompleted.errors && data.markProblemWordsAsCompleted.errors.length !== 0) {
                setFormErrors(data.markProblemWordsAsCompleted.errors);
                return;
            }
            if (data.markProblemWordsAsCompleted && data.markProblemWordsAsCompleted.completedWords) {
                setSelectedWords([]);
            }
        },
        variables: { words: selectedWords },
        refetchQueries: [{ query: GET_STUDENT_PROBLEM_WORDS, variables: { id: studentState.id, showAll: true } }],
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.student && baseWords) {
        return (
            <Fragment>
                <Tabs centered className='u-m-base-3'>
                    <Tab selected={showCurrent} onClick={() => setShowCurrent(true)}>Current problem words</Tab>
                    <Tab selected={!showCurrent} onClick={() => setShowCurrent(false)}>Completed problem words</Tab>
                </Tabs>
                <div>
                    {showCurrent ? (
                        <>
                            {baseWords.length > 0 ? (
                                <>
                                    <p className='lead heavy u-m-base-2'>Recent words</p>
                                    <ul className={styles.recentWordList}>
                                        {baseWords.filter(w => moment(w.timestamp, 'DD-MM-YYYY') >= moment(new Date).subtract(DAYS_AGO, 'days')).map(pword =>
                                            <li key={`pword${pword.id}`} className={styles.recentWord}>
                                                <input
                                                    onChange={(e) => onSelectWord(e)}
                                                    type='checkbox'
                                                    value={pword.id}
                                                /> {pword.word} <span className={styles.recentWordTime}>{pword.timestamp}</span>
                                            </li>
                                        )}
                                    </ul>
                                    {groupWordsByDate(olderWords(baseWords)).map((el, i) => (
                                        <div key={`group-${i}`}>
                                            <p className='lead heavy u-m-base-1'>{el.month}</p>
                                            <ul className={styles.recentWordList}>
                                                {el.items.map(pword =>
                                                    <li key={`pword${pword.id}`} className={styles.recentWord}>
                                                        <input
                                                            onChange={(e) => onSelectWord(e)}
                                                            type='checkbox'
                                                            value={pword.id}
                                                        /> {pword.word} <span className={styles.recentWordTime}>{pword.timestamp}</span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    ))}
                                    {formErrors && <FormErrors errors={formErrors} />}
                                    <Button disabled={selectedWords.length === 0 || marking} onClick={() => markProblemWordsAsCompleted()}>Mark as complete</Button>
                                </>
                            ) : <p>No current problem words</p>
                            }
                        </>
                    ) : <CompletedProblemWords words={completedWords} />
                    }
                </div>
            </Fragment >
        );
    }
    return null
}

export default AllProblemWords;