import React, { Fragment } from 'react';
import { Router, Location } from '@reach/router';
import '@reach/dialog/styles.css';
import { Dialog } from '@reach/dialog';

import TokenCheck from './TokenCheck';
import Dashboard from '../../Dashboard';
import Student from '../../Student';
import LogReading from '../../../components/LogReading';
import ViewLog from '../../../components/ViewLog';
import Banding from '../../Banding';
import BandingView from '../../Banding/View';
import ClassNew from '../../ClassNew'
import ParentData from '../../DataDashboard/scenes/ParentData'
import ReadingTargets from '../../DataDashboard/scenes/ReadingTargets';
import DataDashboardSchool from '../../DataDashboard/scenes/DataDashboardSchool';
import DataDashboardClass from '../../DataDashboard/scenes/DataDashboardSchool/DataDashboardClass'
import DataDashboardYearGroup from '../../DataDashboard/scenes/DataDashboardSchool/DataDashYearGroup';

const AppRouter = () => {

    const Routes = props => {
        return (
            <Router {...props}>
                <TokenCheck dialog={props.dialog} path='/'>
                    <LogReading path="/logReading/:id" />
                    <Banding path="/banding" />
                    <BandingView path="/banding/all" />
                    <ViewLog path="/viewlog" />
                    <Dashboard path="/" />
                    <ClassNew path="/class/:id" />
                    <Student path="/student/:id" />

                    <ReadingTargets path='/dataReadingTargets' />
                    <ParentData path="/dataParent" />
                    <DataDashboardSchool path="/dataDashboard" />
                    <DataDashboardClass path="/dataDashboard/class/:id" />
                    <DataDashboardYearGroup path="/dataDashboard/year/:code/:schoolId" />
                </TokenCheck>
            </Router>
        )
    }

    return (
        <Location>
            {({ location, navigate }) => {
                const { oldLocation } = location.state || {};
                return (
                    <Fragment>
                        <Routes location={oldLocation != null ? oldLocation : location} />
                        <Dialog
                            aria-label="Modal"
                            isOpen={oldLocation != null}
                            onDismiss={() => {
                                navigate(oldLocation.pathname);
                            }}
                        >
                            <Routes dialog location={location} />
                        </Dialog>
                    </Fragment>
                )
            }}
        </Location>
    )
}

export default AppRouter;